import React, { useEffect, useState, useCallback } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
// import axiosDownload from "../../axiosDownload";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Measurement from "./Measurement";
import Comparison from "./Comparison";
// import get_grain from '../../json/get_grain.json'

import TrendAnalysis from "./TrendAnalysis";

const Main = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [ActiveChart, setActiveChart] = useState("Measurement");

  const [NotAllow, setNotAllow] = useState([]);

  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [FromDatesAll, setFromDatesAll] = useState([]);
  const [ToDatesAll, setToDatesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");

  const [TimeFramesAllComp1, setTimeFramesAllComp1] = useState([]);
  const [FromDatesAllComp1, setFromDatesAllComp1] = useState([]);
  const [ToDatesAllComp1, setToDatesAllComp1] = useState([]);
  const [timeframeComp1, setTimeframeComp1] = useState("");
  const [DefaultStartDateComp1, setDefaultStartDateComp1] = useState("");
  const [DefaultEndDateComp1, setDefaultEndDateComp1] = useState("");

  const [TimeFramesAllComp2, setTimeFramesAllComp2] = useState([]);
  const [FromDatesAllComp2, setFromDatesAllComp2] = useState([]);
  const [ToDatesAllComp2, setToDatesAllComp2] = useState([]);
  const [timeframeComp2, setTimeframeComp2] = useState("");
  const [DefaultStartDateComp2, setDefaultStartDateComp2] = useState("");
  const [DefaultEndDateComp2, setDefaultEndDateComp2] = useState("");

  const [Notes, setNotes] = useState("");

  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [FetchCall, setFetchCall] = useState(true);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);
  const [Reset, setReset] = useState(0);
  const [charttitle, setcharttitle] = useState("");
  const [xtitle, setxtitle] = useState("");
  const [ytitle, setytitle] = useState("");

  const [BreadCrumbsComp1, setBreadCrumbsComp1] = useState([]);
  const [categoriesComp1, setCategoriesComp1] = useState([]);
  const [seriesComp1, setSeriesComp1] = useState([]);
  // const [SummaryComp1, setSummaryComp1] = useState([])
  const [FetchCallComp1, setFetchCallComp1] = useState(true);
  const [charttitleComp1, setcharttitleComp1] = useState("");
  const [xtitleComp1, setxtitleComp1] = useState("");
  const [ytitleComp1, setytitleComp1] = useState("");
  const [ChartdataComp1, setChartdataComp1] = useState({});
  const [ClickedCategoryComp1, setClickedCategoryComp1] = useState(null);

  const [BreadCrumbsComp2, setBreadCrumbsComp2] = useState([]);
  const [categoriesComp2, setCategoriesComp2] = useState([]);
  const [seriesComp2, setSeriesComp2] = useState([]);
  // const [SummaryComp2, setSummaryComp2] = useState([])
  const [FetchCallComp2, setFetchCallComp2] = useState(true);
  const [charttitleComp2, setcharttitleComp2] = useState("");
  const [xtitleComp2, setxtitleComp2] = useState("");
  const [ytitleComp2, setytitleComp2] = useState("");
  const [ChartdataComp2, setChartdataComp2] = useState({});
  const [ClickedCategoryComp2, setClickedCategoryComp2] = useState(null);

  // const [FetchFilters, setFetchFilters] = useState(true)
  // const [NewFilters, setNewFilters] = useState([])
  // const [MeasurementFilters, setMeasurementFilters] = useState([])

  const [AllFiltersComp, setAllFiltersComp] = useState([]);
  const [ActiveFiltersComp, setActiveFiltersComp] = useState([]);
  const [AllFilters, setAllFilters] = useState([]);
  const [ActiveFilters, setActiveFilters] = useState([]);
  const [ClickedCategory, setClickedCategory] = useState(null);
  const [Chartdata, setChartdata] = useState({});

  const [CompFilterTabShow, setCompFilterTabShow] = useState(false);

  const [ZoomIn, setZoomIn] = useState(false);

  const onChangeHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAll.length; i++) {
      if (TimeFramesAll[i].key === value) {
        setFromDatesAll(TimeFramesAll[i].fromdates);
        setToDatesAll(TimeFramesAll[i].todates);
      }
    }
    setTimeframe(value);
  };

  const onChangeHandlerComp1 = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllComp1.length; i++) {
      if (TimeFramesAllComp1[i].key === value) {
        setFromDatesAllComp1(TimeFramesAllComp1[i].fromdates);
        setToDatesAllComp1(TimeFramesAllComp1[i].todates);
      }
    }
    setTimeframeComp1(value);
  };

  const onChangeHandlerComp2 = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllComp2.length; i++) {
      if (TimeFramesAllComp2[i].key === value) {
        setFromDatesAllComp2(TimeFramesAllComp2[i].fromdates);
        setToDatesAllComp2(TimeFramesAllComp2[i].todates);
      }
    }
    setTimeframeComp2(value);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_timeframes/", { page: "grain_contri", brand: Analyse.Brand, kpi: Analyse.KPI })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          setFromDatesAll(response.data.data[0].fromdates);
          setToDatesAll(response.data.data[0].todates);
          setDefaultStartDate(response.data.data[0].default_startdate);
          setDefaultEndDate(response.data.data[0].default_enddate);

          setTimeFramesAllComp1(response.data.data);
          setTimeframeComp1(response.data.data[0].key);
          setFromDatesAllComp1(response.data.data[0].fromdates);
          setToDatesAllComp1(response.data.data[0].todates);
          setDefaultStartDateComp1(response.data.data[0].default_startdate);
          setDefaultEndDateComp1(response.data.data[0].default_enddate);

          setTimeFramesAllComp2(response.data.data);
          setTimeframeComp2(response.data.data[0].key);
          setFromDatesAllComp2(response.data.data[0].fromdates);
          setToDatesAllComp2(response.data.data[0].todates);
          setDefaultStartDateComp2(response.data.data[0].default_startdate);
          setDefaultEndDateComp2(response.data.data[0].default_enddate);

          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (FetchCall && ActiveChart === "Measurement") {
      if (
        timeframe !== "" &&
        DefaultStartDate !== "" &&
        DefaultEndDate !== "" &&
        JSON.stringify(Chartdata) !== "{}"
      ) {
        if (
          FromDatesAll.indexOf(DefaultStartDate) >
          ToDatesAll.indexOf(DefaultEndDate)
        ) {
          toast.error("Please enter valid time period");
          setFetchCall(false);
        } else {
          setLoader(true);
          setCategories([]);
          setSeries([]);
          axios
            .post("get_grain_contrib_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              startdate: DefaultStartDate,
              enddate: DefaultEndDate,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              chartdata: Chartdata,
            })
            .then((response) => {
              if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                setNotAllow(response.data.data.nonclicklist);
                setCategories(response.data.data.categories);
                setSeries(response.data.data.series);
                setSummary(response.data.data.summary || []);
                setNotes(response.data.notes);
                setcharttitle(response.data.data.charttitle);
                setxtitle(response.data.data.xtitle);
                setytitle(response.data.data.ytitle);
                setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCall(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframe,
    DefaultStartDate,
    DefaultEndDate,
    FetchCall,
    FromDatesAll,
    ToDatesAll,
    Chartdata,
    ActiveFilters,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  useEffect(() => {
    const chartdata = {};
    for (var i = 0; i < ActiveFilters.length; i++) {
      const selection = [];
      if ("Selected" in ActiveFilters[i]) {
        for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
          selection.push(ActiveFilters[i].Selected[j].value);
        }
      }
      chartdata[ActiveFilters[i].name] = {
        selection: [],
        Filter: selection,
      };
    }
    setChartdata(chartdata);
    setFetchCall(true);
  }, [ActiveFilters]);

  useEffect(() => {
    const chartdata1 = {};
    const chartdata2 = {};

    for (var i = 0; i < ActiveFiltersComp.length; i++) {
      const selection1 = [];
      if ("Selected1" in ActiveFiltersComp[i]) {
        for (var j = 0; j < ActiveFiltersComp[i].Selected1.length; j++) {
          selection1.push(ActiveFiltersComp[i].Selected1[j].value);
        }
      }
      chartdata1[ActiveFiltersComp[i].name] = {
        selection: [],
        Filter: selection1,
      };

      const selection2 = [];
      if ("Selected2" in ActiveFiltersComp[i]) {
        for (j = 0; j < ActiveFiltersComp[i].Selected2.length; j++) {
          selection2.push(ActiveFiltersComp[i].Selected2[j].value);
        }
      }
      chartdata2[ActiveFiltersComp[i].name] = {
        selection: [],
        Filter: selection2,
      };
    }
    setChartdataComp1(chartdata1);
    setFetchCallComp1(true);
    setChartdataComp2(chartdata2);
    setFetchCallComp2(true);
  }, [ActiveFiltersComp]);

  useEffect(() => {
    // if (FetchFilters) {
    setLoader(true);
    axios
      .post("get_grain_contrib_new_filters/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          var temp = [];
          temp.push(response.data.data[0]);
          if (temp[0].filters[0].value === "All" && false) {
            var FilterAll = [];
            for (var i = 0; i < temp[0].filters.length; i++) {
              FilterAll.push(temp[0].filters[i]);
            }
            temp[0].Selected = FilterAll;
            temp[0].Selected1 = FilterAll;
            temp[0].Selected2 = FilterAll;
          } else {
            temp[0].Selected = [temp[0].filters[0]];
            temp[0].Selected1 = [temp[0].filters[0]];
            temp[0].Selected2 = [temp[0].filters[0]];
          }
          setActiveFilters(temp);
          setActiveFiltersComp(temp);

          const newd = JSON.parse(JSON.stringify(response.data.data));
          newd.shift();
          setAllFilters(newd);
          setAllFiltersComp(response.data.data);
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
    // setFetchFilters(false)
    // }
  }, [Analyse.Brand, Analyse.KPI]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "inactive"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      let filter = AllFilters[result.source.index];

      if (filter.filters[0].value === "All" && false) {
        var temp = [];
        for (var i = 0; i < filter.filters.length; i++) {
          temp.push(filter.filters[i]);
        }
        filter.Selected = temp;
      } else {
        filter.Selected = [filter.filters[0]];
      }
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setActiveFilters(ActiveFiltersTemp);

      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      setAllFilters(AllFiltersTemp);
    }

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setActiveFilters(ActiveFiltersTemp);
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "active"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);

      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      setActiveFilters(ActiveFiltersTemp);
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "inactive"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);
    }
  };

  const DrillAll = () => {
    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }

    setIsAllEnabled(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
  };

  const ResetAll = () => {
    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
  };

  const DrillDownAll = useCallback(
    (IsReturn = 0) => {
      let ChartdataTemp = {};
      function addToChartdata(category, index, ParentLabel) {
        var FilterTemp = [];
        var selectionTemp = [];

        if (ActiveFilters[index] !== undefined) {
          if (ActiveFilters[index].name in ChartdataTemp) {
            FilterTemp = JSON.parse(
              JSON.stringify(ChartdataTemp[ActiveFilters[index].name].Filter)
            );
            selectionTemp = JSON.parse(
              JSON.stringify(ChartdataTemp[ActiveFilters[index].name].selection)
            );
          } else {
            ChartdataTemp[ActiveFilters[index].name] = {};
          }

          // FilterTemp = Chartdata[ActiveFilters[index].name].Filter;

          if (typeof category === "object") {
            if (category.name !== "") {
              ParentLabel = ParentLabel !== '' ? ParentLabel + '-' + category.name.split(",")[0] : category.name.split(",")[0]
              selectionTemp.push(ParentLabel);
            }
          } else {
            if (category !== "") {
              ParentLabel = ParentLabel !== '' ? ParentLabel + '-' + category.split(",")[0] : category.split(",")[0]
              selectionTemp.push(ParentLabel);
            }
          }

          ChartdataTemp[ActiveFilters[index].name].Filter = FilterTemp;
          ChartdataTemp[ActiveFilters[index].name].selection = selectionTemp;

          if (typeof category === "object" && "categories" in category) {
            for (var k = 0; k < category.categories.length; k++) {
              addToChartdata(category.categories[k], index + 1, ParentLabel);
            }
          }
        }
      }

      for (var i = 0; i < categories.length; i++) {
        addToChartdata(categories[i], 0, '');
      }

      if (IsReturn === 1) {
        return ChartdataTemp;
      }

      for (const filter in Chartdata) {
        if (!(filter in ChartdataTemp)) {
          ChartdataTemp[filter] = Chartdata[filter];
        }
      }

      setChartdata(ChartdataTemp);
      setFetchCall(true);
    },
    [ActiveFilters, Chartdata, categories]
  );

  useEffect(() => {
    const ChartdataTemp = DrillDownAll(1);
    const BreadCrumbsTemp = [];

    for (const filter in ChartdataTemp) {
      if (ChartdataTemp[filter].selection.length !== 0) {
        BreadCrumbsTemp.push(filter);
      }
    }
    setBreadCrumbs(BreadCrumbsTemp);
  }, [categories, DrillDownAll]);

  const DrillUpAll = () => {
    let ChartdataTemp = {};
    var count = 0;

    function addToChartdata(category, index) {
      if (index < ActiveFilters.length) {
        var FilterTemp = [];
        var selectionTemp = [];

        if (ActiveFilters[index].name in ChartdataTemp) {
          FilterTemp = JSON.parse(
            JSON.stringify(ChartdataTemp[ActiveFilters[index].name].Filter)
          );
          selectionTemp = JSON.parse(
            JSON.stringify(ChartdataTemp[ActiveFilters[index].name].selection)
          );
        } else {
          ChartdataTemp[ActiveFilters[index].name] = {};
        }

        FilterTemp = Chartdata[ActiveFilters[index].name].Filter;

        if (typeof category === "object") {
          if (category.name !== "") {
            selectionTemp.push(category.name.split(",")[0]);
          }
        } else {
          if (category !== "") {
            selectionTemp.push(category.split(",")[0]);
          }
        }

        ChartdataTemp[ActiveFilters[index].name].Filter = FilterTemp;
        ChartdataTemp[ActiveFilters[index].name].selection = selectionTemp;

        if (typeof category === "object" && "categories" in category) {
          count = index + 1;
          for (var k = 0; k < category.categories.length; k++) {
            addToChartdata(category.categories[k], index + 1);
          }
        }
      }
    }

    for (var i = 0; i < categories.length; i++) {
      addToChartdata(categories[i], 0);
    }

    var CountTemp = 0;
    for (const filter in Chartdata) {
      if (!(filter in ChartdataTemp)) {
        ChartdataTemp[filter] = Chartdata[filter];
      }

      if (CountTemp >= count - 1) {
        ChartdataTemp[filter].selection = [];
      }
      CountTemp++;
    }

    setChartdata(ChartdataTemp);
    setFetchCall(true);
  };

  const DrillDown = (Category) => {
    setClickedCategory(Category);
  };

  const DrillUp = (Category) => {
    if (Category.pos !== undefined && Category.axis !== undefined) {
      function chnnellist(list) {
        if ("parent" in list && list.parent !== undefined) {
          return chnnellist(list.parent);
        } else {
          return list.categories;
        }
      }
      const SeriesList = chnnellist(Category.axis.categories[Category.pos]);

      let ChartdataTemp = JSON.parse(JSON.stringify(Chartdata));
      if (ChartdataTemp[ActiveFilters[0].name].selection.length === 0) {
        ChartdataTemp = DrillDownAll(1);
      }

      let LabelFound = false;

      for (const property in ChartdataTemp) {
        if (LabelFound) {
          for (var i = 0; i < SeriesList.length; i++) {
            let name = Object.prototype.toString.call(SeriesList[i]) === "[object String]" ? SeriesList[i] : SeriesList[i].name;
            let index = ChartdataTemp[property].selection.indexOf(name.split(",")[0]);
            if (index > -1) {
              ChartdataTemp[property].selection.splice(index, 1);
            }
          }
        }

        var span = document.createElement("span");
        span.innerHTML = Category.value;
        const value = span.firstChild.getAttribute("title");
        if (ChartdataTemp[property].selection.includes(value)) {
          let index = ChartdataTemp[property].selection.indexOf(value);
          if (index > -1) {
            ChartdataTemp[property].selection.splice(index, 1);
          }
          LabelFound = true;
        }
      }

      setChartdata(ChartdataTemp);
      setFetchCall(true);
    }
  };

  useEffect(() => {
    if (ClickedCategory) {
      var GraphSeries = [];
      function chnnellist(list) {
        if (list.name !== "") {
          GraphSeries.push(list.name);
        }

        if ("parent" in list && list.parent !== undefined) {
          chnnellist(list.parent);
        }
      }
      chnnellist(ClickedCategory.category);
      setClickedCategory(null);
      GraphSeries = GraphSeries.reverse();

      const is2dArray = categories.every((item) => typeof item === "object");
      let ChartdataTemp = JSON.parse(JSON.stringify(Chartdata));
      if (ChartdataTemp[ActiveFilters[0].name].selection.length === 0 && is2dArray) {
        ChartdataTemp = DrillDownAll(1);
      }

      let i = 0;
      let SeriesLabel = ''
      for (const property in ChartdataTemp) {
        if (GraphSeries[i] !== undefined && GraphSeries[i].split(",")[0] !== '') {
          SeriesLabel += (SeriesLabel !== '') ? "-" + GraphSeries[i].split(",")[0] : GraphSeries[i].split(",")[0]
          if (!ChartdataTemp[property].selection.includes(SeriesLabel)) {
            ChartdataTemp[property].selection.push(SeriesLabel);
          }
        }
        i++;
      }

      setChartdata(ChartdataTemp);
      setFetchCall(true);
    }
  }, [ActiveFilters, ClickedCategory, Chartdata, categories, DrillDownAll]);

  const onChangeFilter = (selected, index) => {
    const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
    if (selected.length === 0) {
      return;
    }
    ActiveFiltersTemp[index].Selected = selected;
    setActiveFilters(ActiveFiltersTemp);
  };

  const AddCompFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFiltersComp));

    let filter = AllFiltersComp[index];
    filter.Selected1 = [filter.filters[0]];
    filter.Selected2 = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setActiveFiltersComp(ActiveFiltersCompTemp);
    setCompFilterTabShow(true);
  };

  const RemoveCompFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFiltersComp));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFiltersComp(ActiveFiltersCompTemp);
      }
    }
    setCompFilterTabShow(true);
  };

  const onDragEndcomp = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersCompTemp = JSON.parse(
        JSON.stringify(ActiveFiltersComp)
      );
      ActiveFiltersCompTemp.splice(result.source.index, 1);
      ActiveFiltersCompTemp.splice(
        result.destination.index,
        0,
        ActiveFiltersComp[result.source.index]
      );
      setActiveFiltersComp(ActiveFiltersCompTemp);
    }
  };

  const onChangeFilterComp = (selected, index, SelectType) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFiltersComp));
    if (selected.length === 0) {
      return;
    }
    if (SelectType === "Selected1") {
      ActiveFiltersCompTemp[index].Selected1 = selected;
    }
    if (SelectType === "Selected2") {
      ActiveFiltersCompTemp[index].Selected2 = selected;
    }
    setActiveFiltersComp(ActiveFiltersCompTemp);
  };

  useEffect(() => {
    if (FetchCallComp1 && ActiveChart === "Comparison") {
      if (
        timeframeComp1 !== "" &&
        DefaultStartDateComp1 !== "" &&
        DefaultEndDateComp1 !== "" &&
        JSON.stringify(ChartdataComp1) !== "{}"
      ) {
        if (
          FromDatesAllComp1.indexOf(DefaultStartDateComp1) >
          ToDatesAllComp1.indexOf(DefaultEndDateComp1)
        ) {
          toast.error("Please enter valid time period");
          setFetchCall(false);
        } else {
          setLoader(true);
          setCategoriesComp1([]);
          setSeriesComp1([]);
          axios
            .post("get_grain_contrib_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframeComp1,
              startdate: DefaultStartDateComp1,
              enddate: DefaultEndDateComp1,
              chartdata: ChartdataComp1,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
            })
            .then((response) => {
              if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                // setCategories(get_grain.data.categories);
                // setSeries(get_grain.data.series);
                setNotAllow(response.data.data.nonclicklist);
                setCategoriesComp1(response.data.data.categories);
                setSeriesComp1(response.data.data.series);
                // setSummary(response.data.data.summary || []);
                // setNotes(response.data.notes);
                setcharttitleComp1(response.data.data.charttitle);
                setxtitleComp1(response.data.data.xtitle);
                setytitleComp1(response.data.data.ytitle);
                setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCallComp1(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframeComp1,
    DefaultStartDateComp1,
    DefaultEndDateComp1,
    FetchCallComp1,
    FromDatesAllComp1,
    ToDatesAllComp1,
    ChartdataComp1,
    ActiveFiltersComp,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  const DrillDownComp1 = (Category) => {
    setClickedCategoryComp1(Category);
  };

  const DrillUpComp1 = (Category) => {
    if (Category.pos !== undefined && Category.axis !== undefined) {
      function chnnellist(list) {
        if ("parent" in list && list.parent !== undefined) {
          return chnnellist(list.parent);
        } else {
          return list.categories;
        }
      }
      const SeriesList = chnnellist(Category.axis.categories[Category.pos]);

      let ChartdataTemp = JSON.parse(JSON.stringify(ChartdataComp1));
      if (ChartdataTemp[ActiveFiltersComp[0].name].selection.length === 0) {
        ChartdataTemp = DrillDownAll1(1);
      }

      let LabelFound = false;
      for (const property in ChartdataTemp) {
        if (LabelFound) {
          for (var i = 0; i < SeriesList.length; i++) {
            let name =
              Object.prototype.toString.call(SeriesList[i]) ===
                "[object String]"
                ? SeriesList[i]
                : SeriesList[i].name;
            let index = ChartdataTemp[property].selection.indexOf(
              name.split(",")[0]
            );
            if (index > -1) {
              ChartdataTemp[property].selection.splice(index, 1);
            }
          }
        }

        var span = document.createElement("span");
        span.innerHTML = Category.value;
        const value = span.firstChild.getAttribute("title");

        if (ChartdataTemp[property].selection.includes(value)) {
          let index = ChartdataTemp[property].selection.indexOf(value);
          if (index > -1) {
            ChartdataTemp[property].selection.splice(index, 1);
          }
          LabelFound = true;
        }
      }

      setChartdataComp1(ChartdataTemp);
      setFetchCallComp1(true);
    }
    // setClickedCategory(Category)
  };

  const DrillDownAll1 = useCallback(
    (IsReturn = 0) => {
      let ChartdataTemp = {};
      function addToChartdata(category, index, ParentLabel) {
        var FilterTemp = [];
        var selectionTemp = [];

        if (ActiveFiltersComp[index] !== undefined) {
          if (ActiveFiltersComp[index].name in ChartdataTemp) {
            FilterTemp = JSON.parse(
              JSON.stringify(ChartdataTemp[ActiveFiltersComp[index].name].Filter)
            );
            selectionTemp = JSON.parse(
              JSON.stringify(ChartdataTemp[ActiveFiltersComp[index].name].selection)
            );
          } else {
            ChartdataTemp[ActiveFiltersComp[index].name] = {};
          }

          // FilterTemp = ChartdataComp1[ActiveFiltersComp[index].name].Filter;

          if (typeof category === "object") {
            if (category.name !== "") {
              ParentLabel = ParentLabel !== '' ? ParentLabel + '-' + category.name.split(",")[0] : category.name.split(",")[0]
              selectionTemp.push(ParentLabel);
            }
          } else {
            if (category !== "") {
              ParentLabel = ParentLabel !== '' ? ParentLabel + '-' + category.split(",")[0] : category.split(",")[0]
              selectionTemp.push(ParentLabel);
            }
          }

          ChartdataTemp[ActiveFiltersComp[index].name].Filter = FilterTemp;
          ChartdataTemp[ActiveFiltersComp[index].name].selection = selectionTemp;

          if (typeof category === "object" && "categories" in category) {
            for (var k = 0; k < category.categories.length; k++) {
              addToChartdata(category.categories[k], index + 1, ParentLabel);
            }
          }
        }
      }

      for (var i = 0; i < categoriesComp1.length; i++) {
        addToChartdata(categoriesComp1[i], 0, '');
      }

      if (IsReturn === 1) {
        return ChartdataTemp;
      }

      for (const filter in ChartdataComp1) {
        if (!(filter in ChartdataTemp)) {
          ChartdataTemp[filter] = ChartdataComp1[filter];
        }
      }

      setChartdataComp1(ChartdataTemp);
      setFetchCallComp1(true);
    },
    [ActiveFiltersComp, ChartdataComp1, categoriesComp1]
  );

  useEffect(() => {
    const ChartdataTemp = DrillDownAll1(1);
    const BreadCrumbsComp1Temp = [];
    for (const filter in ChartdataTemp) {
      if (ChartdataTemp[filter].selection.length !== 0) {
        BreadCrumbsComp1Temp.push(filter);
      }
    }
    setBreadCrumbsComp1(BreadCrumbsComp1Temp);
  }, [categoriesComp1, DrillDownAll1]);

  useEffect(() => {
    if (ClickedCategoryComp1) {
      var GraphSeries = [];
      function chnnellist(list) {
        if (list.name !== "") {
          GraphSeries.push(list.name);
        }

        if ("parent" in list && list.parent !== undefined) {
          chnnellist(list.parent);
        }
      }
      chnnellist(ClickedCategoryComp1.category);
      setClickedCategoryComp1(null);
      GraphSeries = GraphSeries.reverse();

      const is2dArray = categoriesComp1.every((item) => typeof item === "object");
      let ChartdataTemp = JSON.parse(JSON.stringify(ChartdataComp1));
      if (ChartdataTemp[ActiveFiltersComp[0].name].selection.length === 0 && is2dArray) {
        ChartdataTemp = DrillDownAll1(1);
      }

      let i = 0;
      let SeriesLabel = ''
      for (const property in ChartdataTemp) {
        if (GraphSeries[i] !== undefined && GraphSeries[i].split(",")[0] !== '') {
          SeriesLabel += (SeriesLabel !== '') ? "-" + GraphSeries[i].split(",")[0] : GraphSeries[i].split(",")[0]
          if (!ChartdataTemp[property].selection.includes(SeriesLabel)) {
            ChartdataTemp[property].selection.push(SeriesLabel);
          }
        }
        i++;
      }

      setChartdataComp1(ChartdataTemp);
      setFetchCallComp1(true);
    }
  }, [ActiveFiltersComp, ClickedCategoryComp1, ChartdataComp1, categoriesComp1, DrillDownAll1,]);

  const DrillAll1 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp1) {
      ChartdataTemp[filter] = ChartdataComp1[filter];
      ChartdataTemp[filter].selection = [];
    }

    setIsAllEnabled(1);
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
  };

  const ResetAll1 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp1) {
      ChartdataTemp[filter] = ChartdataComp1[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
  };

  const DrillUpAll1 = () => {
    let ChartdataTemp = {};
    var count = 0;

    function addToChartdata(category, index) {
      if (index < ActiveFiltersComp.length) {
        var FilterTemp = [];
        var selectionTemp = [];

        if (ActiveFiltersComp[index].name in ChartdataTemp) {
          FilterTemp = JSON.parse(
            JSON.stringify(ChartdataTemp[ActiveFiltersComp[index].name].Filter)
          );
          selectionTemp = JSON.parse(
            JSON.stringify(
              ChartdataTemp[ActiveFiltersComp[index].name].selection
            )
          );
        } else {
          ChartdataTemp[ActiveFiltersComp[index].name] = {};
        }

        FilterTemp = ChartdataComp1[ActiveFiltersComp[index].name].Filter;

        if (typeof category === "object") {
          if (category.name !== "") {
            selectionTemp.push(category.name.split(",")[0]);
          }
        } else {
          if (category !== "") {
            selectionTemp.push(category.split(",")[0]);
          }
        }

        ChartdataTemp[ActiveFiltersComp[index].name].Filter = FilterTemp;
        ChartdataTemp[ActiveFiltersComp[index].name].selection = selectionTemp;

        if (typeof category === "object" && "categories" in category) {
          count = index + 1;
          for (var k = 0; k < category.categories.length; k++) {
            addToChartdata(category.categories[k], index + 1);
          }
        }
      }
    }

    for (var i = 0; i < categoriesComp1.length; i++) {
      addToChartdata(categoriesComp1[i], 0);
    }

    var CountTemp = 0;
    for (const filter in ChartdataComp1) {
      if (!(filter in ChartdataTemp)) {
        ChartdataTemp[filter] = ChartdataComp1[filter];
      }

      if (CountTemp >= count - 1) {
        ChartdataTemp[filter].selection = [];
      }
      CountTemp++;
    }

    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
  };

  const DrillAll2 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp2) {
      ChartdataTemp[filter] = ChartdataComp2[filter];
      ChartdataTemp[filter].selection = [];
    }

    setIsAllEnabled(1);
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
  };

  const ResetAll2 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp2) {
      ChartdataTemp[filter] = ChartdataComp2[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
  };

  const DrillDownAll2 = useCallback(
    (IsReturn = 0) => {
      let ChartdataTemp = {};
      function addToChartdata(category, index, ParentLabel) {
        var FilterTemp = [];
        var selectionTemp = [];

        if (ActiveFiltersComp[index] !== undefined) {
          if (ActiveFiltersComp[index].name in ChartdataTemp) {
            FilterTemp = JSON.parse(
              JSON.stringify(ChartdataTemp[ActiveFiltersComp[index].name].Filter)
            );
            selectionTemp = JSON.parse(
              JSON.stringify(ChartdataTemp[ActiveFiltersComp[index].name].selection)
            );
          } else {
            ChartdataTemp[ActiveFiltersComp[index].name] = {};
          }

          // FilterTemp = ChartdataComp2[ActiveFiltersComp[index].name].Filter;

          if (typeof category === "object") {
            if (category.name !== "") {
              ParentLabel = ParentLabel !== '' ? ParentLabel + '-' + category.name.split(",")[0] : category.name.split(",")[0]
              selectionTemp.push(ParentLabel);
            }
          } else {
            if (category !== "") {
              ParentLabel = ParentLabel !== '' ? ParentLabel + '-' + category.split(",")[0] : category.split(",")[0]
              selectionTemp.push(ParentLabel);
            }
          }

          ChartdataTemp[ActiveFiltersComp[index].name].Filter = FilterTemp;
          ChartdataTemp[ActiveFiltersComp[index].name].selection = selectionTemp;

          if (typeof category === "object" && "categories" in category) {
            for (var k = 0; k < category.categories.length; k++) {
              addToChartdata(category.categories[k], index + 1, ParentLabel);
            }
          }
        }
      }

      for (var i = 0; i < categoriesComp2.length; i++) {
        addToChartdata(categoriesComp2[i], 0, '');
      }

      if (IsReturn === 1) {
        return ChartdataTemp;
      }

      for (const filter in ChartdataComp2) {
        if (!(filter in ChartdataTemp)) {
          ChartdataTemp[filter] = ChartdataComp2[filter];
        }
      }

      setChartdataComp2(ChartdataTemp);
      setFetchCallComp2(true);
    },
    [ActiveFiltersComp, ChartdataComp2, categoriesComp2]
  );

  useEffect(() => {
    const ChartdataTemp = DrillDownAll2(1);
    const BreadCrumbsComp2Temp = [];
    for (const filter in ChartdataTemp) {
      if (ChartdataTemp[filter].selection.length !== 0) {
        BreadCrumbsComp2Temp.push(filter);
      }
    }
    setBreadCrumbsComp2(BreadCrumbsComp2Temp);
  }, [categoriesComp2, DrillDownAll2]);

  const DrillUpAll2 = () => {
    let ChartdataTemp = {};
    var count = 0;

    function addToChartdata(category, index) {
      if (index < ActiveFiltersComp.length) {
        var FilterTemp = [];
        var selectionTemp = [];

        if (ActiveFiltersComp[index].name in ChartdataTemp) {
          FilterTemp = JSON.parse(
            JSON.stringify(ChartdataTemp[ActiveFiltersComp[index].name].Filter)
          );
          selectionTemp = JSON.parse(
            JSON.stringify(
              ChartdataTemp[ActiveFiltersComp[index].name].selection
            )
          );
        } else {
          ChartdataTemp[ActiveFiltersComp[index].name] = {};
        }

        FilterTemp = ChartdataComp2[ActiveFiltersComp[index].name].Filter;

        if (typeof category === "object") {
          if (category.name !== "") {
            selectionTemp.push(category.name.split(",")[0]);
          }
        } else {
          if (category !== "") {
            selectionTemp.push(category.split(",")[0]);
          }
        }

        ChartdataTemp[ActiveFiltersComp[index].name].Filter = FilterTemp;
        ChartdataTemp[ActiveFiltersComp[index].name].selection = selectionTemp;

        if (typeof category === "object" && "categories" in category) {
          count = index + 1;
          for (var k = 0; k < category.categories.length; k++) {
            addToChartdata(category.categories[k], index + 1);
          }
        }
      }
    }

    for (var i = 0; i < categoriesComp2.length; i++) {
      addToChartdata(categoriesComp2[i], 0);
    }

    var CountTemp = 0;
    for (const filter in ChartdataComp2) {
      if (!(filter in ChartdataTemp)) {
        ChartdataTemp[filter] = ChartdataComp2[filter];
      }

      if (CountTemp >= count - 1) {
        ChartdataTemp[filter].selection = [];
      }
      CountTemp++;
    }

    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
  };

  useEffect(() => {
    if (FetchCallComp2 && ActiveChart === "Comparison") {
      if (
        timeframeComp2 !== "" &&
        DefaultStartDateComp2 !== "" &&
        DefaultEndDateComp2 !== "" &&
        JSON.stringify(ChartdataComp2) !== "{}"
      ) {
        if (
          FromDatesAllComp2.indexOf(DefaultStartDateComp2) >
          ToDatesAllComp2.indexOf(DefaultEndDateComp2)
        ) {
          toast.error("Please enter valid time period");
          setFetchCall(false);
        } else {
          setLoader(true);
          setCategoriesComp2([]);
          setSeriesComp2([]);
          axios
            .post("get_grain_contrib_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframeComp2,
              startdate: DefaultStartDateComp2,
              enddate: DefaultEndDateComp2,
              chartdata: ChartdataComp2,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
            })
            .then((response) => {
              if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                setNotAllow(response.data.data.nonclicklist);
                setCategoriesComp2(response.data.data.categories);
                setSeriesComp2(response.data.data.series);
                // setSummary(response.data.data.summary || []);
                // setNotes(response.data.notes);
                setcharttitleComp2(response.data.data.charttitle);
                setxtitleComp2(response.data.data.xtitle);
                setytitleComp2(response.data.data.ytitle);
                setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCallComp2(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframeComp2,
    DefaultStartDateComp2,
    DefaultEndDateComp2,
    FetchCallComp2,
    FromDatesAllComp2,
    ToDatesAllComp2,
    ChartdataComp2,
    ActiveFiltersComp,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  const DrillDownComp2 = (Category) => {
    setClickedCategoryComp2(Category);
  };

  const DrillUpComp2 = (Category) => {
    if (Category.pos !== undefined && Category.axis !== undefined) {
      function chnnellist(list) {
        if ("parent" in list && list.parent !== undefined) {
          return chnnellist(list.parent);
        } else {
          return list.categories;
        }
      }
      const SeriesList = chnnellist(Category.axis.categories[Category.pos]);

      let ChartdataTemp = JSON.parse(JSON.stringify(ChartdataComp2));
      if (ChartdataTemp[ActiveFiltersComp[0].name].selection.length === 0) {
        ChartdataTemp = DrillDownAll2(1);
      }

      let LabelFound = false;
      for (const property in ChartdataTemp) {
        if (LabelFound) {
          for (var i = 0; i < SeriesList.length; i++) {
            let name =
              Object.prototype.toString.call(SeriesList[i]) ===
                "[object String]"
                ? SeriesList[i]
                : SeriesList[i].name;
            let index = ChartdataTemp[property].selection.indexOf(
              name.split(",")[0]
            );
            if (index > -1) {
              ChartdataTemp[property].selection.splice(index, 1);
            }
          }
        }

        var span = document.createElement("span");
        span.innerHTML = Category.value;
        const value = span.firstChild.getAttribute("title");

        if (ChartdataTemp[property].selection.includes(value)) {
          let index = ChartdataTemp[property].selection.indexOf(value);
          if (index > -1) {
            ChartdataTemp[property].selection.splice(index, 1);
          }
          LabelFound = true;
        }
      }

      setChartdataComp2(ChartdataTemp);
      setFetchCallComp2(true);
    }
    // setClickedCategory(Category)
  };

  useEffect(() => {
    if (ClickedCategoryComp2) {
      var GraphSeries = [];
      function chnnellist(list) {
        if (list.name !== "") {
          GraphSeries.push(list.name);
        }

        if ("parent" in list && list.parent !== undefined) {
          chnnellist(list.parent);
        }
      }
      chnnellist(ClickedCategoryComp2.category);
      setClickedCategoryComp2(null);
      GraphSeries = GraphSeries.reverse();

      const is2dArray = categoriesComp2.every((item) => typeof item === "object");
      let ChartdataTemp = JSON.parse(JSON.stringify(ChartdataComp2));
      if (ChartdataTemp[ActiveFiltersComp[0].name].selection.length === 0 && is2dArray) {
        ChartdataTemp = DrillDownAll2(1);
      }

      let i = 0;
      let SeriesLabel = ''
      for (const property in ChartdataTemp) {
        if (GraphSeries[i] !== undefined && GraphSeries[i].split(",")[0] !== '') {
          SeriesLabel += (SeriesLabel !== '') ? "-" + GraphSeries[i].split(",")[0] : GraphSeries[i].split(",")[0]
          if (!ChartdataTemp[property].selection.includes(SeriesLabel)) {
            ChartdataTemp[property].selection.push(SeriesLabel);
          }
        }
        i++;
      }

      setChartdataComp2(ChartdataTemp);
      setFetchCallComp2(true);
    }
  }, [ActiveFiltersComp, ClickedCategoryComp2, ChartdataComp2, categoriesComp2, DrillDownAll2,]);

  const HandleZoom = () => {
    setLoader(true)
    const categoriesTemp = JSON.parse(JSON.stringify(categories))
    const seriesTemp = JSON.parse(JSON.stringify(series))

    setCategories([])
    setSeries([])

    setZoomIn(!ZoomIn);
    setTimeout(function () {
      setCategories(categoriesTemp)
      setSeries(seriesTemp)
      setLoader(false)
    }, 1000);
  }

  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                        return "/" + item.key === location.pathname
                          ? item.value
                          : null;
                      })
                      : null}
                  </h1>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 d-flex ce-filter justify-content-end">
                <button
                  className={
                    ActiveChart === "Measurement" ? "btn active" : "btn"
                  }
                  type="button"
                  onClick={() => setActiveChart("Measurement")}
                >
                  Measurement Chart
                </button>
                <button
                  className={
                    ActiveChart === "Comparison" ? "btn active" : "btn"
                  }
                  type="button"
                  onClick={() => setActiveChart("Comparison")}
                >
                  Comparison Charts
                </button>
                <button
                  className={
                    ActiveChart === "TrendAnalysis" ? "btn active" : "btn"
                  }
                  type="button"
                  onClick={() => setActiveChart("TrendAnalysis")}
                >
                  Trend Analysis
                </button>
              </div>
            </div>
          </div>

          {ActiveChart === "Measurement" ? (
            <Measurement
              timeframe={timeframe}
              onChangeHandler={onChangeHandler}
              TimeFramesAll={TimeFramesAll}
              DefaultStartDate={DefaultStartDate}
              DefaultEndDate={DefaultEndDate}
              setDefaultStartDate={setDefaultStartDate}
              setDefaultEndDate={setDefaultEndDate}
              FromDatesAll={FromDatesAll}
              ToDatesAll={ToDatesAll}
              setFetchCall={setFetchCall}
              onDragEnd={onDragEnd}
              Summary={Summary}
              AllFilters={AllFilters}
              ActiveFilters={ActiveFilters}
              Notes={Notes}
              categories={categories}
              series={series}
              xtitle={xtitle}
              ytitle={ytitle}
              charttitle={charttitle}
              DrillUp={DrillUp}
              DrillDown={DrillDown}
              onChangeFilter={onChangeFilter}
              DrillUpAll={DrillUpAll}
              DrillDownAll={DrillDownAll}
              DrillAll={DrillAll}
              ResetAll={ResetAll}
              BreadCrumbs={BreadCrumbs}
              // setZoomIn={setZoomIn}
              HandleZoom={HandleZoom}
              ZoomIn={ZoomIn}
              NotAllow={NotAllow}
            />
          ) : null}

          {ActiveChart === "Comparison" ? (
            <Comparison
              AllFiltersComp={AllFiltersComp}
              ActiveFiltersComp={ActiveFiltersComp}
              AddCompFilter={AddCompFilter}
              RemoveCompFilter={RemoveCompFilter}
              CompFilterTabShow={CompFilterTabShow}
              setCompFilterTabShow={setCompFilterTabShow}
              onDragEndcomp={onDragEndcomp}
              onChangeFilterComp={onChangeFilterComp}
              timeframeComp1={timeframeComp1}
              onChangeHandlerComp1={onChangeHandlerComp1}
              TimeFramesAllComp1={TimeFramesAllComp1}
              DefaultStartDateComp1={DefaultStartDateComp1}
              DefaultEndDateComp1={DefaultEndDateComp1}
              setDefaultStartDateComp1={setDefaultStartDateComp1}
              setDefaultEndDateComp1={setDefaultEndDateComp1}
              FromDatesAllComp1={FromDatesAllComp1}
              ToDatesAllComp1={ToDatesAllComp1}
              setFetchCallComp1={setFetchCallComp1}
              categoriesComp1={categoriesComp1}
              seriesComp1={seriesComp1}
              xtitleComp1={xtitleComp1}
              ytitleComp1={ytitleComp1}
              charttitleComp1={charttitleComp1}
              DrillDownComp1={DrillDownComp1}
              DrillUpComp1={DrillUpComp1}
              timeframeComp2={timeframeComp2}
              onChangeHandlerComp2={onChangeHandlerComp2}
              TimeFramesAllComp2={TimeFramesAllComp2}
              DefaultStartDateComp2={DefaultStartDateComp2}
              DefaultEndDateComp2={DefaultEndDateComp2}
              setDefaultStartDateComp2={setDefaultStartDateComp2}
              setDefaultEndDateComp2={setDefaultEndDateComp2}
              FromDatesAllComp2={FromDatesAllComp2}
              ToDatesAllComp2={ToDatesAllComp2}
              setFetchCallComp2={setFetchCallComp2}
              categoriesComp2={categoriesComp2}
              seriesComp2={seriesComp2}
              xtitleComp2={xtitleComp2}
              ytitleComp2={ytitleComp2}
              charttitleComp2={charttitleComp2}
              DrillDownComp2={DrillDownComp2}
              DrillUpComp2={DrillUpComp2}
              DrillUpAll1={DrillUpAll1}
              DrillDownAll1={DrillDownAll1}
              DrillAll1={DrillAll1}
              ResetAll1={ResetAll1}
              BreadCrumbsComp1={BreadCrumbsComp1}
              DrillUpAll2={DrillUpAll2}
              DrillDownAll2={DrillDownAll2}
              DrillAll2={DrillAll2}
              ResetAll2={ResetAll2}
              BreadCrumbsComp2={BreadCrumbsComp2}
              Notes={Notes}
              NotAllow={NotAllow}
            />
          ) : null}

          {ActiveChart === "TrendAnalysis" ? (
            <TrendAnalysis
              timeframe={timeframe}
              onChangeHandler={onChangeHandler}
              TimeFramesAll={TimeFramesAll}
              DefaultStartDate={DefaultStartDate}
              DefaultEndDate={DefaultEndDate}
              setDefaultStartDate={setDefaultStartDate}
              setDefaultEndDate={setDefaultEndDate}
              FromDatesAll={FromDatesAll}
              ToDatesAll={ToDatesAll}
              setFetchCall={setFetchCall}
              onDragEnd={onDragEnd}
              Summary={Summary}
              AllFilters={AllFilters}
              ActiveFilters={ActiveFilters}
              Notes={Notes}
              categories={categories}
              series={series}
              xtitle={xtitle}
              ytitle={ytitle}
              charttitle={charttitle}
              DrillUp={DrillUp}
              DrillDown={DrillDown}
              onChangeFilter={onChangeFilter}
              DrillUpAll={DrillUpAll}
              DrillDownAll={DrillDownAll}
              DrillAll={DrillAll}
              ResetAll={ResetAll}
              BreadCrumbs={BreadCrumbs}
              // setZoomIn={setZoomIn}
              HandleZoom={HandleZoom}
              ZoomIn={ZoomIn}
              NotAllow={NotAllow}
            />
          ) : null}

        </div>
      </div>
    </>
  );
};

export default Main;
