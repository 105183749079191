import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import OptimiseForm from "./OptimiseForm";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import Loader from "../../Components/Loader";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import SkewbSimulator from "./SkewbSimulator";
import SaveResultAlert from "./SaveResultAlert";
import RangeConfirmAlert from "./RangeConfirmAlert";
import AlertBox from './AlertBox'

import ColumnChart from '../../Charts/ColumnChart'
import LineChart from '../../Charts/LineChart'
import StackChart from '../../Charts/StackChart'
import WaterfallChart from '../../Charts/WaterfallChart'

// import get_opt_prev_json from '../../json/get_opt_prev_json.json'
// import get_opt_prev_json from '../../json/sb21-on_sales-Sep-PrevSep.json'
// import optimise from '../../json/optimise.json'
// import optimise from '../../json/new.json'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopUp from "../../Components/PopUp";

const Main = () => {
  const { ResultId } = useParams()

  const [loader, setLoader] = useState(false);

  const [PreviousResponse, setPreviousResponse] = useState(null);
  const [CurrentResponse, setCurrentResponse] = useState(null);

  const [AllowCall, setAllowCall] = useState(false);
  const [ChartActiveTab, setChartActiveTab] = useState("Distribution");
  const [AllowEdit, setAllowEdit] = useState(false);
  const [PreviousData, setPreviousData] = useState([]);
  const [SelectedYear, setSelectedYear] = useState('');
  const [Summary, setSummary] = useState({});
  const [result_name, setResultName] = useState('');
  const [Notes, setNotes] = useState('');
  const [SaveResponse, setSaveResponse] = useState('');
  const [ConfirmRange, setConfirmRange] = useState({});
  const [AlertMessage, setAlertMessage] = useState('');
  const [ChannelExpand, setChannelExpand] = useState({});
  const [ChannelExpand1, setChannelExpand1] = useState({});
  const [Levels, setLevels] = useState([]);

  const [CaraouselChartdict, setCaraouselChartdict] = useState([]);
  const [CaraouselSummarydict, setCaraouselSummarydict] = useState([]);
  const [OptChartdict, setOptChartdict] = useState([]);
  const [OptSummarydict, setOptSummarydict] = useState([]);

  const [DeepDive, setDeepDive] = useState(false);

  const YearHandler = (e) => {
    setAllowCall(false)
    for (var i = 0; i < PreviousData.length; i++) {
      if (e.target.value === PreviousData[i].year) {
        setSummary(PreviousData[i].data);
        break;
      }
    }
    setSelectedYear(e.target.value);
  };

  const [NewData, setNewData] = useState({});
  const [NewFixedData, setNewFixedData] = useState({});
  const [NewSaveData, setNewSaveData] = useState({});
  const [Parameter, setParameter] = useState({
    brand: "",
    kpi: "",
    timeframe: "",
    timeperiod: "",
    optimization_type: "",
    budget: "",
  });
  const [SuggestedAllocation, setSuggestedAllocation] = useState([]);
  const [EditedAllocation, setEditedAllocation] = useState([]);

  const [GrowthDistribution, setGrowthDistribution] = useState({
    GrowthData: [],
    GrowthLabel: [],
    GrowthColor: []
  });

  const [Contribution, setContribution] = useState([]);

  const [SaveResult, setSaveResult] = useState(false);

  const SaveOptimisation = () => {
    if (result_name !== '') {
      const ParamData = {
        "result_name": result_name,
        "optimization_type": Parameter.optimization_type,
        "budget": Parameter.budget,
        "brand": Parameter.brand,
        "kpi": Parameter.kpi,
        "timeframe": Parameter.timeframe,
        "timeperiod": Parameter.timeperiod,
        "result_data": NewSaveData,
        "ddtag": SelectedYear
      }
      setLoader(true);
      axios
        .post("save_optimisation/", ParamData)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            // setSaveResult(false)
            setSaveResponse(response.data.data)
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }

  const OptimizedData = (parameter) => {
    setResultName(parameter.result_name)
    setParameter(parameter);
  };

  useEffect(() => {
    if (Parameter.brand !== '' && AllowCall) {
      setLoader(true);
      axios
        .post("get_opt_prev_json/", Parameter)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setPreviousResponse(response.data);
            // console.log(get_opt_prev_json)
            // console.log(response.data.data)
            const temp = { data: response.data.data }
            // setPreviousData(response.data.data.data);
            // setSummary(response.data.data.data[0].data)
            // setSelectedYear(response.data.data.data[0].year);

            setPreviousData(temp.data);
            setSummary(temp.data[0].data)
            setSelectedYear(temp.data[0].year);
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [Parameter, AllowCall]);

  useEffect(() => {
    if (PreviousData.length && AllowCall) {
      setLoader(true);

      Parameter.ddtag = SelectedYear
      axios
        .post("optimise/", Parameter)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setCurrentResponse(response.data);
            setNewSaveData(response.data.data);
            setNewData(response.data.data.data);
            setNewFixedData(response.data.data.data);
            setNotes(response.data.notes)
            setCaraouselChartdict(response.data.data.caraouselchartdict)
            setCaraouselSummarydict(response.data.data.caraouselsummarydict)
            setOptChartdict(response.data.data.optchartdict)
            setOptSummarydict(response.data.data.optsummarydict)

            // setNewData(optimise.data);
            // setNewFixedData(optimise.data);
            // setNotes(optimise.notes)
            // setCaraouselChartdict(optimise.data.caraouselchartdict)
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [PreviousData, Parameter, AllowCall, SelectedYear]);

  useEffect(() => {
    if (ResultId) {
      setLoader(true);
      axios
        .post("optimise/", { result_id: ResultId })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            // console.log(response.data)
            // setNewData(response.data.data);
            // setNewFixedData(response.data.data);
            setCurrentResponse(response.data);
            setNewSaveData(response.data.data);
            setNewData(response.data.data.data);
            setNewFixedData(response.data.data.data);
            setNotes(response.data.notes)
            setCaraouselChartdict(response.data.data.caraouselchartdict)
            setCaraouselSummarydict(response.data.data.caraouselsummarydict)
            setOptChartdict(response.data.data.optchartdict)
            setOptSummarydict(response.data.data.optsummarydict)
            setParameter({
              brand: response.data.data.brand_short,
              kpi: response.data.data.kpi_short,
              timeframe: response.data.data.timeframe,
              timeperiod: response.data.data.timeperiod,
              optimization_type: response.data.data.optimization_type,
              budget: response.data.data.spend,
            })

            const ddtag = response.data.data.ddtag
            axios
              .post("get_opt_prev_json/", {
                "brand": response.data.data.brand_short,
                "kpi": response.data.data.kpi_short,
                "timeperiod": response.data.data.timeperiod
              })
              .then((response) => {
                if (response.data.error === 1) {
                  toast.error(response.data.erroMsg);
                  setLoader(false);
                } else {
                  setPreviousResponse(response.data)
                  setPreviousData(response.data.data);
                  if (ddtag) {
                    for (var i = 0; i < response.data.data.length; i++) {
                      if (ddtag === response.data.data[i].year) {
                        setSelectedYear(response.data.data[i].year);
                        setSummary(response.data.data[i].data)
                        break
                      }
                    }
                  } else {
                    setSelectedYear(response.data.data[0].year);
                    setSummary(response.data.data[0].data)
                  }
                  setDeepDive(true)
                  setLoader(false);
                }
              })
              .catch(() => {
                setLoader(false);
              });
          }
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setAllowCall(true)
    }
  }, [ResultId]);

  // const EditNewSpends = (ChannelName, Index, Spends, Index1 = '') => {
  //   if (isNaN(Spends)) {
  //     return false
  //   }
  //   var TempNewData = JSON.parse(JSON.stringify(NewData));

  //   if (Index1 === '') {
  //     TempNewData.channels[ChannelName][Index].spends = Spends;
  //   } else {
  //     TempNewData.channels[ChannelName][Index].subchannel[Index1].spends = Spends;
  //   }

  //   setNewData(TempNewData);
  // };

  const EditNewSpends = (MyLevel, Spends) => {
    if (isNaN(Spends)) {
      return false
    }
    var TempNewData = JSON.parse(JSON.stringify(NewData));

    MyLevel = MyLevel.split("|");
    var i = 0
    var j = 0
    var k = 0
    var l = 0

    if (MyLevel.length === 2) {
      for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
        if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
          TempNewData.channels[MyLevel[0]][i].spends = Spends;
          break;
        }
      }
    }

    if (MyLevel.length === 3) {
      for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
        if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
          for (j = 0; j < TempNewData.channels[MyLevel[0]][i].subchannel.length; j++) {
            if (TempNewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
              TempNewData.channels[MyLevel[0]][i].subchannel[j].spends = Spends;
              break
            }
          }
        }
      }
    }

    if (MyLevel.length === 4) {
      for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
        if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
          for (j = 0; j < TempNewData.channels[MyLevel[0]][i].subchannel.length; j++) {
            if (TempNewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
              for (k = 0; k < TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length; k++) {
                if (TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].mykey === MyLevel[3]) {
                  TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].spends = Spends;
                  break
                }
              }
            }
          }
        }
      }
    }

    if (MyLevel.length === 5) {
      for (i = 0; i < TempNewData.channels[MyLevel[0]].length; i++) {
        if (TempNewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
          for (j = 0; j < TempNewData.channels[MyLevel[0]][i].subchannel.length; j++) {
            if (TempNewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
              for (k = 0; k < TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length; k++) {
                if (TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].mykey === MyLevel[3]) {
                  for (l = 0; l < TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].subchannel.length; l++) {
                    if (TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].subchannel[l].mykey === MyLevel[4]) {
                      TempNewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].subchannel[l].spends = Spends;
                      break
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // console.log(TempNewData)
    setNewData(TempNewData);
  };

  const EditNewSpendsOnBlur = (MyLevel, Spends) => {
    Spends = (Spends === '') ? 0 : parseFloat(Spends)
    EditNewSpends(MyLevel, Spends)

    MyLevel = MyLevel.split("|");

    if (MyLevel.length === 2) {
      ValidateLevel1(MyLevel)
    }

    if (MyLevel.length === 3) {
      ValidateLevel2(MyLevel)
    }

    if (MyLevel.length === 4) {
      ValidateLevel3(MyLevel)
    }
    // console.log(MyLevel)
    // console.log(NewData)
    // console.log(NewFixedData)
  };

  const ValidateLevel1 = (MyLevel) => {
    let Index = ''
    let Index1 = ''
    let Index2 = ''
    let RangeError = false
    let ChildTotalError = false

    for (var i = 0; i < NewData.channels[MyLevel[0]].length; i++) {
      if (NewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
        if (NewData.channels[MyLevel[0]][i].spends !== NewFixedData.channels[MyLevel[0]][i].spends) {
          Index = i
          const Spends = NewData.channels[MyLevel[0]][i].spends

          if ('subchannel' in NewFixedData.channels[MyLevel[0]][i]) {
            var ChildTotal = 0
            for (var s = 0; s < NewFixedData.channels[MyLevel[0]][i].subchannel.length; s++) {
              if (NewData.channels[MyLevel[0]][i].subchannel[s].spends !== NewFixedData.channels[MyLevel[0]][i].subchannel[s].spends) {
                ChildTotal += parseFloat(NewData.channels[MyLevel[0]][i].subchannel[s].spends)
              }
            }

            if (ChildTotal > parseFloat(Spends)) {
              ChildTotalError = true
            }
          }

          if (Spends > NewFixedData.channels[MyLevel[0]][i].max_val || Spends < NewFixedData.channels[MyLevel[0]][i].min_val) {
            RangeError = true
          }
        }
        break;
      }
    }

    if (validate() > NewFixedData.spend) {
      setConfirmRange({
        ChannelName: MyLevel[0],
        Index: Index,
        Index1: Index1,
        Index2: Index2,
        message: 'Total spend amount should not be more than ' + parseFloat(NewFixedData.spend).toFixed(1) + NewFixedData.units,
        FalseText: 'Okay'
      })
    } else {
      if (ChildTotalError) {
        setConfirmRange({
          ChannelName: MyLevel[0],
          Index: Index,
          Index1: Index1,
          Index2: Index2,
          message: 'Total of ' + MyLevel[1] + ' sub channel spends should not be greater than ' + MyLevel[1] + ' spends',
          FalseText: 'Okay'
        })
      } else {
        if (RangeError) {
          setConfirmRange({
            ChannelName: MyLevel[0],
            Index: Index,
            Index1: Index1,
            Index2: Index2,
            message: 'Entered value falls outside range, Are you sure you want to do this change?',
            FalseText: 'No'
          })
        }
      }
    }
  }

  const ValidateLevel2 = (MyLevel) => {
    let Index = ''
    let Index1 = ''
    let Index2 = ''
    let RangeError = false
    let SpendNotMatchError = false
    let SpendExceedLimitError = false
    let ChildTotalError = false
    let ChildChanged = 0
    let ChildSpends = 0

    for (var i = 0; i < NewData.channels[MyLevel[0]].length; i++) {
      if (NewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
        for (var j = 0; j < NewData.channels[MyLevel[0]][i].subchannel.length; j++) {
          if (NewData.channels[MyLevel[0]][i].subchannel[j].spends !== NewFixedData.channels[MyLevel[0]][i].subchannel[j].spends) {
            ChildChanged += 1
            ChildSpends += parseFloat(NewData.channels[MyLevel[0]][i].subchannel[j].spends)
          }

          if (NewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
            Index = i
            Index1 = j

            const Spends = NewData.channels[MyLevel[0]][i].subchannel[j].spends

            if ('subchannel' in NewData.channels[MyLevel[0]][i].subchannel[j]) {
              var ChildTotal = 0
              for (var s = 0; s < NewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length; s++) {
                ChildTotal += parseFloat(NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[s].spends)
              }

              if (ChildTotal > parseFloat(Spends)) {
                ChildTotalError = true
              }
            }

            if (Spends > NewFixedData.channels[MyLevel[0]][i].subchannel[j].max_val || Spends < NewFixedData.channels[MyLevel[0]][i].subchannel[j].min_val) {
              RangeError = true
            }
            // break
          }
        }

        if (ChildChanged === NewData.channels[MyLevel[0]][i].subchannel.length && ChildSpends !== NewData.channels[MyLevel[0]][i].spends) {
          SpendNotMatchError = true
        } else {
          if (NewData.channels[MyLevel[0]][i].spends !== NewFixedData.channels[MyLevel[0]][i].spends) {
            if (ChildSpends > NewData.channels[MyLevel[0]][i].spends) {
              SpendExceedLimitError = true
            }
          }
        }
        break
      }
    }

    if (validate() > NewFixedData.spend) {
      setConfirmRange({
        ChannelName: MyLevel[0],
        Index: Index,
        Index1: Index1,
        Index2: Index2,
        message: 'Total spend amount should not be more than ' + parseFloat(NewFixedData.spend).toFixed(1) + NewFixedData.units,
        FalseText: 'Okay'
      })
    } else {
      if (ChildTotalError) {
        setConfirmRange({
          ChannelName: MyLevel[0],
          Index: Index,
          Index1: Index1,
          Index2: Index2,
          message: 'Total of ' + MyLevel[2] + ' sub channel spends should not be greater than ' + MyLevel[2] + ' spends',
          FalseText: 'Okay'
        })
      } else {
        if (SpendExceedLimitError) {
          setConfirmRange({
            ChannelName: MyLevel[0],
            Index: Index,
            Index1: Index1,
            Index2: Index2,
            message: 'Total of ' + MyLevel[1] + ' sub channel should not exceed ' + NewData.channels[MyLevel[0]][Index].spends + ' spends',
            FalseText: 'Okay'
          })
        } else {
          if (SpendNotMatchError) {
            setConfirmRange({
              ChannelName: MyLevel[0],
              Index: Index,
              Index1: Index1,
              Index2: Index2,
              message: 'Total of ' + MyLevel[1] + ' sub channel should be equal to ' + MyLevel[1] + ' spends',
              FalseText: 'Okay'
            })
          } else {
            if (RangeError) {
              setConfirmRange({
                ChannelName: MyLevel[0],
                Index: Index,
                Index1: Index1,
                Index2: Index2,
                message: 'Entered value falls outside range, Are you sure you want to do this change?',
                FalseText: 'No'
              })
            }
          }
        }
      }
    }
  }

  const ValidateLevel3 = (MyLevel) => {
    let Index = ''
    let Index1 = ''
    let Index2 = ''
    let RangeError = false
    let SpendNotMatchError = false
    let SpendExceedLimitError = false
    let ChildChanged = 0
    let ChildSpends = 0

    for (var i = 0; i < NewData.channels[MyLevel[0]].length; i++) {
      if (NewData.channels[MyLevel[0]][i].mykey === MyLevel[1]) {
        for (var j = 0; j < NewData.channels[MyLevel[0]][i].subchannel.length; j++) {
          if (NewData.channels[MyLevel[0]][i].subchannel[j].mykey === MyLevel[2]) {
            for (var k = 0; k < NewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length; k++) {

              if (NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].spends !== NewFixedData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].spends) {
                ChildChanged += 1
                ChildSpends += parseFloat(NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].spends)
              }

              if (NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].mykey === MyLevel[3]) {
                Index = i
                Index1 = j
                Index2 = k

                const Spends = NewData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].spends;
                if (Spends > NewFixedData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].max_val || Spends < NewFixedData.channels[MyLevel[0]][i].subchannel[j].subchannel[k].min_val) {
                  RangeError = true
                }
                // break
              }

            }

            if (ChildChanged === NewData.channels[MyLevel[0]][i].subchannel[j].subchannel.length && ChildSpends !== NewData.channels[MyLevel[0]][i].subchannel[j].spends) {
              SpendNotMatchError = true
            } else {
              if (NewData.channels[MyLevel[0]][i].subchannel[j].spends !== NewFixedData.channels[MyLevel[0]][i].subchannel[j].spends) {
                if (ChildSpends > NewData.channels[MyLevel[0]][i].subchannel[j].spends) {
                  SpendExceedLimitError = true
                }
              }
            }
            break
          }
        }
        break
      }
    }

    if (validate() > NewFixedData.spend) {
      setConfirmRange({
        ChannelName: MyLevel[0],
        Index: Index,
        Index1: Index1,
        Index2: Index2,
        message: 'Total spend amount should not be more than ' + parseFloat(NewFixedData.spend).toFixed(1) + NewFixedData.units,
        FalseText: 'Okay'
      })
    } else {
      if (SpendExceedLimitError) {
        setConfirmRange({
          ChannelName: MyLevel[0],
          Index: Index,
          Index1: Index1,
          Index2: Index2,
          message: 'Total of ' + MyLevel[2] + ' sub channel should not exceed ' + NewData.channels[MyLevel[0]][Index].subchannel[Index1].spends + ' spends',
          FalseText: 'Okay'
        })
      } else {
        if (SpendNotMatchError) {
          setConfirmRange({
            ChannelName: MyLevel[0],
            Index: Index,
            Index1: Index1,
            Index2: Index2,
            message: 'Total of ' + MyLevel[2] + ' sub channel should be equal to ' + MyLevel[2] + ' spends',
            FalseText: 'Okay'
          })
        } else {
          if (RangeError) {
            setConfirmRange({
              ChannelName: MyLevel[0],
              Index: Index,
              Index1: Index1,
              Index2: Index2,
              message: 'Entered value falls outside range, Are you sure you want to do this change?',
              FalseText: 'No'
            })
          }
        }
      }
    }
  }

  const validate = () => {
    var AllTotal = 0
    function countTotal(NewList, FixedList) {
      var ListTotal = 0
      for (var n = 0; n < NewList.length; n++) {
        if (NewList[n].spends !== FixedList[n].spends) {
          ListTotal += parseFloat(NewList[n].spends)
        } else {
          if ('subchannel' in NewList[n]) {
            ListTotal += countTotal(NewList[n].subchannel, FixedList[n].subchannel)
          }
        }
      }
      return ListTotal
    }

    for (const [key] of Object.entries(NewData.channels)) {
      const Total = countTotal(NewData.channels[key], NewFixedData.channels[key])
      AllTotal += Total
    }
    return AllTotal
  }

  // const validate = () => {
  //   var changed = [];
  //   var obj = {};
  //   var i = 0;
  //   var j = 0;
  //   var total = 0

  //   for (const [key] of Object.entries(NewData.channels)) {
  //     for (i = 0; i < NewData.channels[key].length; i++) {
  //       if (NewData.channels[key][i].spends !== NewFixedData.channels[key][i].spends) {
  //         obj = {};
  //         obj[key + "-" + NewData.channels[key][i].mykey] = parseFloat(NewData.channels[key][i].spends);
  //         changed.push(obj);
  //         total += parseFloat(NewData.channels[key][i].spends);
  //       }

  //       if ('subchannel' in NewData.channels[key][i]) {
  //         for (j = 0; j < NewData.channels[key][i].subchannel.length; j++) {
  //           if (NewData.channels[key][i].subchannel[j].spends !== NewFixedData.channels[key][i].subchannel[j].spends) {
  //             obj = {};
  //             obj[key + "-" + NewData.channels[key][i].mykey + "-" + NewData.channels[key][i].subchannel[j].mykey] = parseFloat(NewData.channels[key][i].subchannel[j].spends);
  //             changed.push(obj);
  //             total += parseFloat(NewData.channels[key][i].subchannel[j].spends);
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return total
  // }

  // const EditNewSpendsOnBlur = (ChannelName, Index, Spends, Index1 = '') => {
  //   Spends = (Spends === '') ? 0 : parseFloat(Spends)
  //   var TempNewData = JSON.parse(JSON.stringify(NewData));
  //   var ChannelData = null
  //   if (Index1 === '') {
  //     TempNewData.channels[ChannelName][Index].spends = Spends;
  //     ChannelData = NewData.channels[ChannelName][Index]
  //   } else {
  //     TempNewData.channels[ChannelName][Index].subchannel[Index1].spends = Spends;
  //     ChannelData = NewData.channels[ChannelName][Index].subchannel[Index1]
  //   }

  //   setNewData(TempNewData);

  //   var Flag = true
  //   if (Index1 !== '' || true) {
  //     if (NewData.channels[ChannelName][Index].spends !== NewFixedData.channels[ChannelName][Index].spends) {
  //       var TempTotal = 0
  //       var SubCount = 0
  //       for (var i = 0; i < NewData.channels[ChannelName][Index].subchannel.length; i++) {
  //         if (NewData.channels[ChannelName][Index].subchannel[i].spends !== NewFixedData.channels[ChannelName][Index].subchannel[i].spends) {
  //           TempTotal += parseFloat(NewData.channels[ChannelName][Index].subchannel[i].spends)
  //           SubCount += 1
  //         }
  //       }

  //       if (SubCount === NewData.channels[ChannelName][Index].subchannel.length && parseFloat(NewData.channels[ChannelName][Index].spends) !== TempTotal) {
  //         Flag = false
  //         setConfirmRange({
  //           ChannelName: ChannelName,
  //           Index: Index,
  //           Index1: Index1,
  //           message: 'Total of ' + ChannelName + ' sub channel should be equal to ' + ChannelName + ' spends',
  //           FalseText: 'Okay'
  //         })
  //       } else {
  //         if (TempTotal > NewData.channels[ChannelName][Index].spends) {
  //           Flag = false
  //           setConfirmRange({
  //             ChannelName: ChannelName,
  //             Index: Index,
  //             Index1: Index1,
  //             message: 'Total of ' + ChannelName + ' sub channel should not exceed ' + NewData.channels[ChannelName][Index].spends,
  //             FalseText: 'Okay'
  //           })
  //         }
  //       }
  //     }
  //   }

  //   if (validate() > NewFixedData.spend) {
  //     setConfirmRange({
  //       ChannelName: ChannelName,
  //       Index: Index,
  //       Index1: Index1,
  //       message: 'Total spend amount should not be more than ' + NewFixedData.spend + NewFixedData.units,
  //       FalseText: 'Okay'
  //     })
  //   } else {
  //     if ((Spends > ChannelData.max_val || Spends < ChannelData.min_val) && Flag) {
  //       setConfirmRange({
  //         ChannelName: ChannelName,
  //         Index: Index,
  //         Index1: Index1,
  //         message: 'Entered value falls outside range, Are you sure you want to do this change?',
  //         FalseText: 'No'
  //       })
  //     }
  //   }
  // };

  const ResetSpends = (ChannelName, Index, Index1 = '') => {
    var TempNewData = JSON.parse(JSON.stringify(NewData));
    if (Index1 === '') {
      TempNewData.channels[ChannelName][Index].spends = NewFixedData.channels[ChannelName][Index].spends;
    } else {
      TempNewData.channels[ChannelName][Index].subchannel[Index1].spends = NewFixedData.channels[ChannelName][Index].subchannel[Index1].spends;
    }
    setNewData(TempNewData);
  }

  const AletrResponse = (response) => {
    if (!response) {
      var TempNewData = JSON.parse(JSON.stringify(NewData));
      if ('Index2' in ConfirmRange && ConfirmRange.Index2 !== '') {
        TempNewData.channels[ConfirmRange.ChannelName][ConfirmRange.Index].subchannel[ConfirmRange.Index1].subchannel[ConfirmRange.Index2].spends = NewFixedData.channels[ConfirmRange.ChannelName][ConfirmRange.Index].subchannel[ConfirmRange.Index1].subchannel[ConfirmRange.Index2].spends;
      } else {
        if (ConfirmRange.Index1 === '') {
          TempNewData.channels[ConfirmRange.ChannelName][ConfirmRange.Index].spends = NewFixedData.channels[ConfirmRange.ChannelName][ConfirmRange.Index].spends;
        } else {
          TempNewData.channels[ConfirmRange.ChannelName][ConfirmRange.Index].subchannel[ConfirmRange.Index1].spends = NewFixedData.channels[ConfirmRange.ChannelName][ConfirmRange.Index].subchannel[ConfirmRange.Index1].spends;
        }
      }
      setNewData(TempNewData);
    }
    setConfirmRange({})
  }

  useEffect(() => {
    if (JSON.stringify(NewFixedData) !== "{}") {
      var i = 0;
      var Allocation = [];
      var GrowthData = [];
      var GrowthLabel = [];
      var GrowthColor = [];
      var ContributionTemp = []

      for (const [key] of Object.entries(NewFixedData.channels)) {
        var Total = 0;
        var Contri = 0;
        for (i = 0; i < NewFixedData.channels[key].length; i++) {
          Total += parseFloat(NewFixedData.channels[key][i].spends);
          Contri += parseFloat(NewFixedData.channels[key][i].contri);
        }
        Allocation.push({
          name:
            key + " " +
            parseInt(Total) +
            " " +
            NewFixedData.units,
          y: Total,
          color: NewFixedData.channels[key][0].color,
          dataLabels: {
            enabled: true,
          },
        });

        GrowthData.push((Contri * NewFixedData.growth_per / NewFixedData.growth_value).toFixed(2))
        GrowthLabel.push(key)
        GrowthColor.push(NewFixedData.channels[key][0].color)

        ContributionTemp.push({
          label: key,
          barThickness: 12,
          backgroundColor: NewFixedData.channels[key][0].color,
          data: [
            ((Total / NewFixedData.spend) * 100).toFixed(2),
            ((Contri / NewFixedData.growth_value) * 100).toFixed(2)
          ]
        })
      }

      setGrowthDistribution({
        GrowthData: GrowthData,
        GrowthLabel: GrowthLabel,
        GrowthColor: GrowthColor
      })

      setContribution(ContributionTemp)

      setSuggestedAllocation(Allocation);
    }
  }, [NewFixedData]);

  useEffect(() => {
    if (JSON.stringify(NewData) !== "{}") {
      // var i = 0;
      // var j = 0;
      var Allocation = [];
      var AllTotal = 0

      function countTotal(NewList, FixedList) {
        var ListTotal = 0
        for (var n = 0; n < NewList.length; n++) {
          if (NewList[n].spends !== FixedList[n].spends) {
            ListTotal += parseFloat(NewList[n].spends)
          } else {
            if ('subchannel' in NewList[n]) {
              ListTotal += countTotal(NewList[n].subchannel, FixedList[n].subchannel)
            }
          }
        }
        return ListTotal
      }

      for (const [key] of Object.entries(NewData.channels)) {
        const Total = countTotal(NewData.channels[key], NewFixedData.channels[key])
        if (Total > 0) {
          AllTotal += Total
          Allocation.push({
            name:
              key + " " +
              parseInt(Total) +
              " " +
              NewData.units,
            y: Total,
            color: NewData.channels[key][0].color,
            dataLabels: {
              enabled: true,
            },
          });
        }
      }

      // console.log(AllTotal)

      // for (const [key] of Object.entries(NewData.channels)) {
      //   var Total = 0;
      //   for (i = 0; i < NewData.channels[key].length; i++) {
      //     if (NewData.channels[key][i].spends !== NewFixedData.channels[key][i].spends && NewFixedData.channels[key][i].fillflag === 1) {
      //       Total += parseFloat(NewData.channels[key][i].spends);
      //     } else {
      //       if ('subchannel' in NewData.channels[key][i]) {
      //         for (j = 0; j < NewData.channels[key][i].subchannel.length; j++) {
      //           if (NewData.channels[key][i].subchannel[j].spends !== NewFixedData.channels[key][i].subchannel[j].spends && NewFixedData.channels[key][i].fillflag === 1) {
      //             Total += parseFloat(NewData.channels[key][i].subchannel[j].spends);
      //           }
      //         }
      //       }
      //     }
      //   }

      //   if (Total > 0) {
      //     AllTotal += Total
      //     Allocation.push({
      //       name:
      //         key + " " +
      //         parseInt(Total) +
      //         " " +
      //         NewData.units,
      //       y: Total,
      //       color: NewData.channels[key][0].color,
      //       dataLabels: {
      //         enabled: true,
      //       },
      //     });
      //   }
      // }

      Allocation.push({
        name:
          " " +
          parseInt(NewData.spend - AllTotal) +
          " " +
          NewData.units,
        y: (NewData.spend - AllTotal) > 0 ? (NewData.spend - AllTotal) : 0,
        color: "#1C2427",
        dataLabels: {
          enabled: true,
        },
      });

      setEditedAllocation(Allocation);
    }
  }, [NewData, NewFixedData]);

  const Optimise = () => {
    var changed = [];
    var obj = {};
    // var i = 0;
    // var j = 0;
    var total = 0

    function countChanged(MyLevel, NewList, FixedList) {
      var ListTotal = 0
      for (var n = 0; n < NewList.length; n++) {
        const NewLevel = MyLevel + "-" + NewList[n].mykey

        let add = true
        if (NewList[n].spends !== FixedList[n].spends) {
          ListTotal += parseFloat(NewList[n].spends)
          obj = {};
          obj[NewLevel] = parseFloat(NewList[n].spends);
          changed.push(obj);
          add = false
        }

        if ('subchannel' in NewList[n]) {
          const SubTotal = countChanged(NewLevel, NewList[n].subchannel, FixedList[n].subchannel)
          if (add === true) {
            ListTotal += SubTotal
          }
        }
      }
      return ListTotal
    }

    for (const [key] of Object.entries(NewData.channels)) {
      total += countChanged(key, NewData.channels[key], NewFixedData.channels[key])
    }

    // for (const [key] of Object.entries(NewData.channels)) {
    //   for (i = 0; i < NewData.channels[key].length; i++) {
    //     if (NewData.channels[key][i].spends !== NewFixedData.channels[key][i].spends) {
    //       obj = {};
    //       obj[key + "-" + NewData.channels[key][i].mykey] = parseFloat(NewData.channels[key][i].spends);
    //       changed.push(obj);
    //       total += parseFloat(NewData.channels[key][i].spends);
    //     }

    //     if ('subchannel' in NewData.channels[key][i]) {
    //       for (j = 0; j < NewData.channels[key][i].subchannel.length; j++) {
    //         if (NewData.channels[key][i].subchannel[j].spends !== NewFixedData.channels[key][i].subchannel[j].spends) {
    //           obj = {};
    //           obj[key + "-" + NewData.channels[key][i].mykey + "-" + NewData.channels[key][i].subchannel[j].mykey] = parseFloat(NewData.channels[key][i].subchannel[j].spends);
    //           changed.push(obj);
    //           total += parseFloat(NewData.channels[key][i].subchannel[j].spends);
    //         }
    //       }
    //     }
    //   }
    // }

    if (total > NewFixedData.spend) {
      setAlertMessage('Total spend amount should not be more than ' + NewFixedData.spend + NewFixedData.units)
    } else {
      const parameter = Parameter;
      parameter.optimization_type = 'budget';
      parameter.budget = NewFixedData.spend;
      parameter.changed = changed;
      Parameter.ddtag = SelectedYear
      // console.log(parameter);
      setLoader(true);
      axios
        .post("optimise/", parameter)
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            // setNewData(response.data.data);
            // setNewFixedData(response.data.data);
            setCurrentResponse(response.data)
            setNewSaveData(response.data.data);
            setNewData(response.data.data.data);
            setNewFixedData(response.data.data.data);
            setNotes(response.data.notes)
            setCaraouselChartdict(response.data.data.caraouselchartdict)
            setCaraouselSummarydict(response.data.data.caraouselsummarydict)
            setOptChartdict(response.data.data.optchartdict)
            setOptSummarydict(response.data.data.optsummarydict)
            setAllowEdit(false)
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("optimiser/", {
        currentdict: {
          data: NewFixedData
        },
        prevdict: {
          data: PreviousData
        }
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const handleExpandChannel = (channel, index) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand))
    if (channel in Expand) {
      Expand[channel].push(index)
    } else {
      const temp = []
      temp.push(index)
      Expand[channel] = temp
    }
    setChannelExpand(Expand)
  }

  const handleShrinkChannel = (channel, index) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand))
    if (channel in Expand) {
      const i = Expand[channel].indexOf(index);
      if (i > -1) {
        Expand[channel].splice(i, 1)
      }
    }
    setChannelExpand(Expand)
  }

  const handleExpandChannel1 = (channel, mykey, index1) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand1))
    if (channel in Expand) {
      // Expand[channel].push(index)
      Expand[channel][mykey].push(index1)
    } else {
      const temp = []
      temp.push(index1)

      const channelTemp = {}
      channelTemp[mykey] = temp
      Expand[channel] = channelTemp
    }
    setChannelExpand1(Expand)
  }

  const handleShrinkChannel1 = (channel, mykey, index1) => {
    const Expand = JSON.parse(JSON.stringify(ChannelExpand1))
    if (channel in Expand) {
      const i = Expand[channel][mykey].indexOf(index1);
      if (i > -1) {
        Expand[channel][mykey].splice(i, 1)
      }
    }
    setChannelExpand1(Expand)
  }

  const AddLevel = (MyLevel) => {
    const LevelsTemp = JSON.parse(JSON.stringify(Levels))
    LevelsTemp.push(MyLevel)
    setLevels(LevelsTemp)
  }

  const RemoveLevel = (MyLevel) => {
    const LevelsTemp = JSON.parse(JSON.stringify(Levels))
    LevelsTemp.splice(LevelsTemp.indexOf(MyLevel), 1)
    setLevels(LevelsTemp)
  }

  // useEffect(() => {
  //   if (SelectedYear !== '' && CurrentResponse !== null && PreviousResponse !== null) {
  //     const ParamData = {
  //       "ddtag": SelectedYear,
  //       "curjson": CurrentResponse,
  //       "prevjson": PreviousResponse
  //     }

  //     setLoader(true);
  //     axios
  //       .post("get_opt_prev_json_summary/", ParamData)
  //       .then((response) => {
  //         if (response.data.error === 1) {
  //           toast.error(response.data.erroMsg);
  //           setLoader(false);
  //         } else {
  //           // setOptSummarydict(response.data.data)
  //           setLoader(false);
  //         }
  //       })
  //       .catch(() => {
  //         setLoader(false);
  //       });
  //   }
  // }, [SelectedYear, CurrentResponse, PreviousResponse])

  const [ShowPopup, setShowPopup] = useState(false);
  const [ChartData, setChartData] = useState({})
  const [LineFilter, setLineFilter] = useState('')
  const setChartDataMain = (data, Filter) => {
    setChartData(data)
    setLineFilter(Filter)
    setShowPopup(true)
  }

  return (
    <>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      {
        ShowPopup ? <PopUp setShowPopup={setShowPopup}>
          {
            (JSON.stringify(ChartData) !== '{}') ?
              <>
                {ChartData.type === 'column' && JSON.stringify(ChartData.data) !== '{}' ? <ColumnChart data={ChartData.data} ChartHeight={'100%'} ChartWidth={'100%'} /> : null}
                {ChartData.type === 'line' && JSON.stringify(ChartData.data) !== '{}' ? <LineChart data={ChartData.data[LineFilter]} ChartHeight={'50%'} ChartWidth={'100%'} /> : null}
                {ChartData.type === 'stack' && JSON.stringify(ChartData.data) !== '{}' ? <StackChart data={ChartData.data} ChartHeight={'100%'} ChartWidth={'100%'} /> : null}
                {ChartData.type === 'waterfall' && JSON.stringify(ChartData.data) !== '{}' ? <WaterfallChart data={ChartData.data} ChartHeight={'90%'} ChartWidth={'100%'} /> : null}
              </>
              : null
          }
        </PopUp> : null
      }

      {JSON.stringify(ConfirmRange) !== '{}' ? <RangeConfirmAlert AletrResponse={AletrResponse} message={ConfirmRange.message} FalseText={ConfirmRange.FalseText} /> : null}
      {AlertMessage ? <AlertBox AlertMessage={AlertMessage} setAlertMessage={setAlertMessage} /> : null}
      {SaveResult ? <SaveResultAlert SaveResponse={SaveResponse} SaveOptimisation={SaveOptimisation} Parameter={Parameter} result_name={result_name} setResultName={setResultName} setSaveResult={setSaveResult} /> : null}
      <Topbar />
      <Menubar />

      {PreviousData.length > 0 && JSON.stringify(NewData) !== "{}" && JSON.stringify(NewFixedData) !== "{}" && DeepDive ? (
        <SkewbSimulator
          EditNewSpends={EditNewSpends}
          EditNewSpendsOnBlur={EditNewSpendsOnBlur}
          ResetSpends={ResetSpends}
          Optimise={Optimise}
          setAllowEdit={setAllowEdit}
          setChartActiveTab={setChartActiveTab}
          YearHandler={YearHandler}
          setSaveResult={setSaveResult}
          DownloadData={DownloadData}
          PreviousData={PreviousData}
          NewData={NewData}
          NewFixedData={NewFixedData}
          AllowEdit={AllowEdit}
          SuggestedAllocation={SuggestedAllocation}
          EditedAllocation={EditedAllocation}
          ChartActiveTab={ChartActiveTab}
          GrowthDistribution={GrowthDistribution}
          Contribution={Contribution}
          SelectedYear={SelectedYear}
          Summary={Summary}
          Notes={Notes}

          ChannelExpand={ChannelExpand}
          ChannelExpand1={ChannelExpand1}
          handleExpandChannel={handleExpandChannel}
          handleShrinkChannel={handleShrinkChannel}
          handleExpandChannel1={handleExpandChannel1}
          handleShrinkChannel1={handleShrinkChannel1}
          Levels={Levels}
          AddLevel={AddLevel}
          RemoveLevel={RemoveLevel}
          OptChartdict={OptChartdict}
          OptSummarydict={OptSummarydict}

          setChartDataMain={setChartDataMain}
        />
      ) : (
        <OptimiseForm
          OptimizedData={OptimizedData}
          PreviousData={PreviousData}
          NewData={NewData}
          NewFixedData={NewFixedData}
          setDeepDive={setDeepDive}

          CaraouselChartdict={CaraouselChartdict}
          CaraouselSummarydict={CaraouselSummarydict}
        />
      )}
    </>
  );
};

export default Main;
