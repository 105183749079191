import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./redux/actions/TokenAction";
import Login from "./Authorization/Login";
import DataLandscape from "./Container/DataLandscape/Main";
import Logout from "./Container/Logout";
import EffectivenessDrivers from "./Container/EffectivenessDriver/Main";
import GainsAndRisks from "./Container/GainsAndRisks/Main";
import CampaignEffectiveness from "./Container/CampaignEffectiveness/Main";
// import CampaignEffectivenessTest from "./Container/CampaignEffectivenessTest/Main";
import ChannnelEfficacy from "./Container/ChannnelEfficacy/Main";
import ChannnelEfficacyAdstock from "./Container/ChannnelEfficacyAdstock/Main";
import SynergyEffects from "./Container/SynergyEffects/Main";
import Prioritisation from "./Container/KPI-Prioritisation/Main";
import SkewbSimulator from "./Container/SkewbSimulator/Main";
import SimulatorRedirect from "./Container/SkewbSimulator/SimulatorRedirect";
import SavedResults from "./Container/SavedResults/Main";
import DeleteResult from "./Container/SavedResults/DeleteResultPopup";
import Dashboard from "./Container/Dashboard/Dashboard";
import Validation from "./Container/Validation/Main";
import Users from "./Container/UserManagement/Main";
import axios from "./axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-255858069-1"; // Google Analytics TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  useEffect(() => { 
    ReactGA.pageview(window.location.pathname + window.location.search)
  })

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (localStorage.getItem("TokenSkewb")) {
      dispatch(setToken(localStorage.getItem("TokenSkewb")));
    }
    return () => { };
  }, [dispatch]);

  const token = useSelector((state) => state.getToken.token);
  // console.log(token);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      setError(error.response.data.erroMsg);
      if (error.response.data.error) {
        navigate("/logout");
      }
      return Promise.reject(error);
    }
  );

  var paths = null;
  if (token) {
    paths = (
      <Routes>
        <Route exact path="/validations" element={<Validation />}></Route>
        <Route exact path="/eda" element={<DataLandscape />}></Route>
        <Route exact path="/contri" element={<EffectivenessDrivers />}></Route>
        <Route exact path="/roi" element={<GainsAndRisks />}></Route>
        <Route exact path="/grain_contri" element={<CampaignEffectiveness />}></Route>
        {/* <Route exact path="/grain_contri1" element={<CampaignEffectivenessTest />}></Route> */}
        <Route exact path="/roc" element={<ChannnelEfficacy />}></Route>
        <Route exact path="/adstock" element={<ChannnelEfficacyAdstock />}></Route>
        <Route exact path="/synergy" element={<SynergyEffects />}></Route>
        <Route exact path="/kpi_prioritization" element={<Prioritisation />}></Route>
        <Route exact path="/optimize" element={<SimulatorRedirect />}></Route>
        <Route exact path="/optimizer" element={<SkewbSimulator />}></Route>
        <Route exact path="/optimizer/:ResultId" element={<SkewbSimulator />}></Route>
        <Route exact path="/savedresults" element={<SavedResults />}></Route>
        <Route exact path="/deleteresult" element={<DeleteResult />}></Route>
        <Route exact path="/users" element={<Users />}></Route>
        <Route exact path="/home" element={<Dashboard />}></Route>
        <Route exact path="*" element={<Dashboard />}></Route>
        <Route exact path="/logout" element={<Logout />}></Route>
      </Routes>
    );
  } else {
    paths = (
      <Routes>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="*" element={<Login />}></Route>
      </Routes>
    );
  }

  return (
    <div>
      <ToastContainer theme="colored" />
      {paths}
    </div>
  );
}

export default App;
