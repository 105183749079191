import React from "react";
import Logo from "../images/skewb-logomark.png";

const Loader = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(0, 0, 0,0.5)",
        zIndex: "9999",
        display: "flex",
      }}
    >
      <div className="loader-skewb" style={{ margin: "auto" }}>
        <img
          src={Logo}
        //   style={{ height: "100px", width: "100px" }}
          alt="Loading..."
        />
      </div>
    </div>
  );
};

export default Loader;
