import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Piechart = (props) => {
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      plotBackgroundColor: "#1c2427",
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: "#1c2427",
      height: props.height,
    },
    title: {
      text: "INDIRECT CHANNEL DISTRIBUTION",
      align: "center",
      verticalAlign: "bottom",
      style: { "color": "#ffffff", "fontSize": "10px" },
      y: -10
    },
    tooltip: {
      headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;">',
      pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
      footerFormat: "</div>",
      shared: true,
      useHTML: true,
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      borderRadius: 8
    },
    // tooltip: {
    //   headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;">',
    //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    //   footerFormat: "</div>",
    //   shared: true,
    //   useHTML: true,
    //   borderColor: '#ffffff',
    //   backgroundColor: '#ffffff',
    //   borderRadius: 8
    // },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: 5,
          style: {
            fontWeight: "bold",
            color: "white",
          },
          formatter: function () {
            // console.log(this)
            return this.key
            // + ' ' + this.percentage.toFixed(2) + '%'
            // return ''
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "100%",
      },
    },
    series: [
      {
        type: "pie",
        name: "",
        innerSize: "50%",
        data: props.Channel,
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          enabled: true,
          symbol: "menu",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427"
          },
          titleKey: "",
          menuItems: ["viewFullscreen"]
        }
      }
    }
  };
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
      {/* <p style={{ color: '#7D9CA6', textAlign: 'center' }}>INDIRECT CHANNEL DISTRIBUTION</p> */}
    </>
  );
};

export default Piechart;
