import React, { useState, useEffect, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import KPISunburst from "./KPISunburst";
import LegendsTable from "./Legends";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from '../../images/download-icon.png'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [KpiSunburstData, setKpiSunburstData] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [Notes, setNotes] = useState('');
  const [Legends, setLegends] = useState([]);
  const [TableKPI, setTableKPI] = useState({});
  const [AllData, setAllData] = useState({});

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_kpi_prioritization/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setAllData(response.data.data);
          setKpiSunburstData(response.data.data.kpi);
          setSummary(response.data.data.summary);
          setLegends(response.data.data.Legends);
          setNotes(response.data.notes);
          setTableKPI(response.data.data.table);
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.Brand, Analyse.KPI]);

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("kpi_prioritization/", { data: AllData })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const [ChartHeight, setChartHeight] = useState('')
  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight)
    }
  }, [])

  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* KPI Prioritisation */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid main-content-w">
            <div className="row">
              <div className="col-xl-4">
                <div className="card card-min-height align-items-sm-center">
                  <div className="row w-100">
                    <div className="col-sm-12 brand-card ef-drivers">
                      <div className="row align-items-center justify-content-sm-between pb-24">
                        <div className="col-sm-10">
                          <div className="page-title-right"></div>
                        </div>

                        <div className="col-sm-2 d-flex align-items-center justify-content-sm-end">
                          <img src={Download} alt="" style={{ float: "right", cursor: 'pointer' }} title="Download" onClick={() => DownloadData()} />
                          {/* <i
                            className="fas fa-download px-2 mx-2"
                            style={{
                              float: "right",
                              fontSize: "25px",
                              color: "#fff ",
                            }}
                          ></i> */}
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-sm-between ">
                        <div className="col-sm-12">
                          <div ref={refGraphDiv}>
                            <KPISunburst KpiSunburstData={KpiSunburstData} height={ChartHeight} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="row">
                            {
                              Legends.map((item, index) => {
                                return <div key={index} className="col-md-3" style={{ color: '#ffffff', whiteSpace: 'nowrap' }}>
                                  <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: item.Color }}></span>
                                  {item.Name}
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-5">
                <div className="card card-min-height">
                  <div className="card-body brand-card">
                    {
                      JSON.stringify(TableKPI) !== '{}' ?
                        <>
                          <p className="card-title-lable">{TableKPI.charttitle}</p>
                          <div className="row my-1 flex-nowrap">
                            {/* <div className="custom-col-sm-1">
                              <p className="m-0 vertical-text"><i className="ri-arrow-up-line my-2"></i> {TableKPI.ytitle}</p>
                            </div> */}
                            <div className="col-sm-12 px-0">
                              <div className="gr-table-x-title-div ">
                                {/* <p className="m-0 gr-table-x-title">{TableKPI.xtitle} <i className="ri-arrow-right-line mx-2"></i></p> */}
                              </div>
                              <div className="table-responsive gains-risk-table" style={{ height: "470px", overflowY: "scroll" }}>
                                <table className="table table-bordered mb-0">
                                  <thead>
                                    <tr>
                                      <th className="caption"></th>
                                      {TableKPI.columns.map((item) => {
                                        return <th key={item}>{item}</th>;
                                      })}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {/* <tr>
                                      <td className="channel-name">channel 1</td>
                                      <td>val1</td>
                                      <td>val2</td>
                                    </tr> */}
                                    {TableKPI.rows.map((item) => {
                                      return (
                                        <tr key={item.ChannelName}>
                                          <td className="channel-name">{item.ChannelName}</td>
                                          {item.ChannelData.map((data, index) => {
                                            return (
                                              <td
                                                key={index}
                                                style={{ color: data.Color }}
                                              >
                                                {data.Value}
                                              </td>
                                            );
                                          })}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="row my-3 d-flex justify-content-sm-end">
                            <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
                              {
                                TableKPI.Legends.map((item, index) => {
                                  return <LegendsTable key={index} Name={item.Name} Color={item.Color} />
                                })
                              }
                            </div>
                          </div>
                        </>
                        : null
                    }
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card card-min-height">
                  <div className="card-body brand-card">
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">Summary</h3>
                      <div className="col-sm-12">
                        <div className="spends-list my-2">
                          <ol>
                            {Summary.map((item, index) => {
                              return (
                                <li key={index} className="spends-list-li">
                                  <p>{item}</p>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 data-l-note" dangerouslySetInnerHTML={{ __html: Notes }}>
                {/* {Notes} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
