import React, { useEffect, useState, useRef, useCallback } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import Waterfall from "./Waterfall";
import Piechart from "./Piechart";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from "../../images/download-icon.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopUp from "../../Components/PopUp";
// import WaterFallHigh from "./WaterFallHigh";

const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");

  const [WaterfallData, setWaterfallData] = useState([]);
  const [Summary, setSummary] = useState({ self: {} });
  const [SummaryFetched, setSummaryFetched] = useState([]);
  const [Stack, setStack] = useState({});
  const [SelectedLabel, setSelectedLabel] = useState("");
  const [Channel, setChannel] = useState([]);
  const [Title, setTitle] = useState("");
  const [xtitle, setxtitle] = useState("");
  const [ytitle, setytitle] = useState("");
  const [Notes, setNotes] = useState("");
  const [AlternateNotes, setAlternateNotes] = useState("");

  const [Categories, setCategories] = useState([]);
  const [ActiveFilters, setActiveFilters] = useState([]);
  const [ClickedCategory, setClickedCategory] = useState(null);
  const [Chartdata, setChartdata] = useState({});
  const [FetchCall, setFetchCall] = useState(false);
  const [NotAllow, setNotAllow] = useState([]);
  const [Reset, setReset] = useState(0);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);

  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [Decimal, setDecimal] = useState(0);

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_contribution_filter/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setActiveFilters(response.data.data);
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    const chartdata = {};
    for (var i = 0; i < ActiveFilters.length; i++) {
      chartdata[ActiveFilters[i].value] = [];
    }
    setChartdata(chartdata);
    // setFetchCall(true);
  }, [ActiveFilters]);

  const DrillDown = (Category) => {
    setClickedCategory(Category);
  };

  const DrillUp = (Category) => {
    if (Category.pos !== undefined && Category.axis !== undefined) {
      function chnnellist(list) {
        if ("parent" in list && list.parent !== undefined) {
          return chnnellist(list.parent);
        } else {
          return list.categories;
        }
      }
      const SeriesList = chnnellist(Category.axis.categories[Category.pos]);
      if (SeriesList !== undefined) {
        let ChartdataTemp = JSON.parse(JSON.stringify(Chartdata));
        if (ChartdataTemp[ActiveFilters[0].value].length === 0) {
          ChartdataTemp = DrillDownAll(1);
        }
        let LabelFound = false;

        for (const property in ChartdataTemp) {
          if (LabelFound) {
            for (var i = 0; i < SeriesList.length; i++) {
              let name =
                Object.prototype.toString.call(SeriesList[i]) ===
                "[object String]"
                  ? SeriesList[i]
                  : SeriesList[i].name;
              let index = ChartdataTemp[property].indexOf(name.split(",")[0]);
              if (index > -1) {
                ChartdataTemp[property].splice(index, 1);
              }
            }
          }

          var span = document.createElement("span");
          span.innerHTML = Category.value;
          const value = span.firstChild.getAttribute("title");

          if (ChartdataTemp[property].includes(value)) {
            let index = ChartdataTemp[property].indexOf(value);
            if (index > -1) {
              ChartdataTemp[property].splice(index, 1);
            }
            LabelFound = true;
          }
        }

        setChartdata(ChartdataTemp);
        setFetchCall(true);
      }
    }
  };

  const DrillDownAll = useCallback(
    (IsReturn = 0) => {
      let ChartdataTemp = {};
      let selectionTemp = [];

      function addToChartdata(category, index, ParentLabel) {
        if (ActiveFilters[index] !== undefined) {
          if (ActiveFilters[index].value in ChartdataTemp) {
            ChartdataTemp[ActiveFilters[index].value] = JSON.parse(
              JSON.stringify(ChartdataTemp[ActiveFilters[index].value])
            );
          } else {
            ChartdataTemp[ActiveFilters[index].value] = [];
          }

          if (typeof category === "object") {
            if (category.name !== "") {
              ParentLabel =
                ParentLabel !== ""
                  ? ParentLabel + "-" + category.name.split(",")[0]
                  : category.name.split(",")[0];
              selectionTemp.push(ParentLabel);
            }
          } else {
            if (category !== "") {
              ParentLabel =
                ParentLabel !== ""
                  ? ParentLabel + "-" + category.split(",")[0]
                  : category.split(",")[0];
              selectionTemp.push(ParentLabel);
            }
          }

          ChartdataTemp[ActiveFilters[index].value] = selectionTemp;
          if (typeof category === "object" && "categories" in category) {
            for (var k = 0; k < category.categories.length; k++) {
              addToChartdata(category.categories[k], index + 1, ParentLabel);
            }
          }
        }
      }

      for (var i = 0; i < Categories.length; i++) {
        addToChartdata(Categories[i], 0, "");
      }

      for (i = 0; i < ActiveFilters.length; i++) {
        if (ActiveFilters[i].value in ChartdataTemp) {
          ChartdataTemp[ActiveFilters[i].value] = JSON.parse(
            JSON.stringify(ChartdataTemp[ActiveFilters[i].value])
          );
        } else {
          ChartdataTemp[ActiveFilters[i].value] = [];
        }
      }

      if (IsReturn === 1) {
        return ChartdataTemp;
      }

      setChartdata(ChartdataTemp);
      setFetchCall(true);
    },
    [ActiveFilters, Categories]
  );

  useEffect(() => {
    if (ClickedCategory) {
      var GraphSeries = [];
      function chnnellist(list) {
        if (list.name !== "") {
          GraphSeries.push(list.name);
        }

        if ("parent" in list && list.parent !== undefined) {
          chnnellist(list.parent);
        }
      }
      chnnellist(ClickedCategory.category);
      setClickedCategory(null);
      GraphSeries = GraphSeries.reverse();

      let NotAllowLabel = "";
      GraphSeries.forEach((item, index) => {
        NotAllowLabel +=
          NotAllowLabel !== "" ? "-" + item.split(",")[0] : item.split(",")[0];
      });

      if (!(GraphSeries.length > 0 && NotAllow.includes(NotAllowLabel))) {
        const is2dArray = Categories.every((item) => typeof item === "object");
        let ChartdataTemp = JSON.parse(JSON.stringify(Chartdata));
        if (ChartdataTemp[ActiveFilters[0].value].length === 0 && is2dArray) {
          ChartdataTemp = DrillDownAll(1);
        }

        let i = 0;
        let SeriesLabel = "";
        for (const property in ChartdataTemp) {
          if (
            GraphSeries[i] !== undefined &&
            GraphSeries[i].split(",")[0] !== ""
          ) {
            SeriesLabel +=
              SeriesLabel !== ""
                ? "-" + GraphSeries[i].split(",")[0]
                : GraphSeries[i].split(",")[0];
            if (!ChartdataTemp[property].includes(SeriesLabel)) {
              ChartdataTemp[property].push(SeriesLabel);
            }
          }
          i++;
        }

        setChartdata(ChartdataTemp);
        setFetchCall(true);
      }
    }
  }, [
    ActiveFilters,
    ClickedCategory,
    Chartdata,
    Categories,
    NotAllow,
    DrillDownAll,
  ]);

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_timeframes/", { page: "contrubution",  brand: Analyse.Brand,
      kpi: Analyse.KPI  })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.Brand, Analyse.KPI ]);

  useEffect(() => {
    if (timeframe) {
      setLoader(true);
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          page: "contribution",
        })
        .then((response) => {
          // console.log("response: ", response.data.data[0].value);
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            }else{
              setChannelCategory('');
            }
            setFetchCall(true);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [timeframe, Analyse.Brand, Analyse.KPI]);

  // useEffect(() => {
  //   if (timeframe) {
  //     setLoader(true);
  //     axios
  //       .post("get_contributions_category/", {
  //         brand: Analyse.Brand,
  //         kpi: Analyse.KPI,
  //         timeframe: timeframe,
  //       })
  //       .then((response) => {
  //         if (response.data.error === 1) {
  //           toast.error(response.data.erroMsg);
  //           setLoader(false);
  //         } else {

  //           setLoader(false);
  //         }
  //       })
  //       .catch((data) => {
  //         setLoader(false);
  //       });
  //   }
  // }, [timeframe, TimeFramesAll, Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    // console.log(ChannelCategory);
    // console.log(FetchCall);
    // console.log(timeframe);
    // console.log(JSON.stringify(Chartdata));

    if (FetchCall && timeframe && JSON.stringify(Chartdata) !== "{}") {
      setLoader(true);
      setCategories([]);
      setWaterfallData([]);
      axios
        .post("get_contributions_new/", {
          timeframe: timeframe,
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          chartdata: Chartdata,
          category: ChannelCategory,
          is_all_enabled: IsAllEnabled,
          reset: Reset,
        })
        .then((response) => {
          if (response.data.error !== 1) {
            setTitle(response.data.chart.charttitle);
            setxtitle(response.data.chart.xtitle);
            setytitle(response.data.chart.ytitle);
            setCategories(response.data.chart.categories);
            setDecimal(response.data.chart.decimal);

            if (response.data.chart.summary) {
              setSummaryFetched(response.data.chart.summary);
            }
            if (response.data.chart.series[0].data) {
              setWaterfallData(response.data.chart.series[0].data);
            } else {
              setWaterfallData([]);
            }
            if (response.data.chart.stack) {
              setStack(response.data.chart.stack);
            }
            setNotAllow(response.data.chart.nonclicklist);
          } else {
            setWaterfallData([]);
            toast.error(response.data.erroMsg);
          }

          setNotes(response.data.notes);
          setSelectedLabel("");
          setSummary({ self: {} });
          setChannel([]);
          setLoader(false);
          setFetchCall(false);
          setReset(0);
          setIsAllEnabled(0);
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [
    FetchCall,
    timeframe,
    Analyse.Brand,
    Analyse.KPI,
    Chartdata,
    IsAllEnabled,
    Reset,
    ChannelCategory,
  ]);

  const removeDash = useCallback((label) => {
    label = label.slice(0, -1);
    return label[label.length - 1] === "-" ? removeDash(label) : label;
  }, []);

  const dataplotRollOverHandler = (data) => {
    let my = data.substring(0, data.length);
    setSelectedLabel(my);
    // if (data.id) {
    //   let label = data.id;
    //   if (label[label.length - 1] === "-") {
    //     label = removeDash(label);
    //   }
    //   setSelectedLabel(label);
    // }
  };

  useEffect(() => {
    var flag = true;
    for (const [key, value] of Object.entries(Stack)) {
      if (SelectedLabel === key) {
        var temp = [];
        var otherPercentage = 0;
        var SummeryData = {};

        for (var i = 0; i < value.length; i++) {
          temp.push({
            name: value[i].name,
            y: value[i].value,
            color: value[i].color,
            dataLabels: {
              enabled: true,
            },
          });

          for (const [key, value] of Object.entries(WaterfallData)) {
            let label = value.id;
            if (label[label.length - 1] === "-") {
              label = removeDash(label);
            }

            if (SelectedLabel === label) {
              SummeryData = WaterfallData[key];
              break;
            }
          }
          otherPercentage += value[i].percentage;
        }
        otherPercentage = otherPercentage * 100;
        SummeryData.otherPercentage = otherPercentage.toFixed(2);

        flag = false;
        setChannel(temp);
        setSummary(SummeryData);
        break;
      }
    }

    if (flag === true) {
      setChannel([]);
      setSummary({});
      for (const [key, value] of Object.entries(WaterfallData)) {
        if (SelectedLabel === value.id) {
          setAlternateNotes(WaterfallData[key].notes);
          break;
        }
      }
    }
  }, [SelectedLabel, Stack, WaterfallData, removeDash]);

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("contribution/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const [ChartHeight, setChartHeight] = useState("");
  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [WaterfallData]);
  const [ShowPopup, setShowPopup] = useState(false);

  const ResetAll = () => {
    const chartdata = {};
    for (var i = 0; i < ActiveFilters.length; i++) {
      chartdata[ActiveFilters[i].value] = [];
    }
    setChartdata(chartdata);
    setReset(1);
    setFetchCall(true);
  };

  const DrillAll = () => {
    const chartdata = {};
    for (var i = 0; i < ActiveFilters.length; i++) {
      chartdata[ActiveFilters[i].value] = [];
    }

    setChartdata(chartdata);
    setIsAllEnabled(1);
    setFetchCall(true);
  };

  const DrillUpAll = () => {
    let ChartdataTemp = {};
    var count = 0;

    function addToChartdata(category, index, ParentLabel) {
      if (index < ActiveFilters.length) {
        var selectionTemp = [];

        if (ActiveFilters[index].value in ChartdataTemp) {
          selectionTemp = JSON.parse(
            JSON.stringify(ChartdataTemp[ActiveFilters[index].value])
          );
        } else {
          ChartdataTemp[ActiveFilters[index].value] = [];
        }

        if (typeof category === "object") {
          if (category.name !== "") {
            ParentLabel =
              ParentLabel !== ""
                ? ParentLabel + "-" + category.name.split(",")[0]
                : category.name.split(",")[0];
            selectionTemp.push(ParentLabel);
          }
        } else {
          if (category !== "") {
            ParentLabel =
              ParentLabel !== ""
                ? ParentLabel + "-" + category.split(",")[0]
                : category.split(",")[0];
            selectionTemp.push(ParentLabel);
          }
        }

        ChartdataTemp[ActiveFilters[index].value] = selectionTemp;

        if (typeof category === "object" && "categories" in category) {
          count = index + 1;
          for (var k = 0; k < category.categories.length; k++) {
            addToChartdata(category.categories[k], index + 1, ParentLabel);
          }
        }
      }
    }

    for (var i = 0; i < Categories.length; i++) {
      addToChartdata(Categories[i], 0, "");
    }

    var CountTemp = 0;
    for (const filter in Chartdata) {
      if (!(filter in ChartdataTemp)) {
        ChartdataTemp[filter] = Chartdata[filter];
      }

      if (CountTemp >= count - 1) {
        ChartdataTemp[filter] = [];
      }
      CountTemp++;
    }

    setChartdata(ChartdataTemp);
    setFetchCall(true);
  };

  return (
    <>
      {loader ? <Loader /> : null}
      {ShowPopup ? (
        <PopUp setShowPopup={setShowPopup} SelectedLabel={SelectedLabel}>
          {Channel.length ? <Piechart Channel={Channel} height="50%" /> : null}
        </PopUp>
      ) : null}
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Effectiveness Drivers */}
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                          return "/" + item.key === location.pathname
                            ? item.value
                            : null;
                        })
                      : null}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid main-content-w"
            style={{ paddingTop: "10px" }}
          >
            <div className="row">
              <div className="col-xl-9">
                <div className="card card-min-height">
                  <div className="card-body brand-card ef-drivers">
                    <div className="row align-items-center justify-content-sm-between">
                      <div className="col-sm-2">
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setTimeframe(e.target.value);
                            setChannelCategory("");
                          }}
                          value={timeframe}
                        >
                          {TimeFramesAll.map((item) => {
                            return (
                              <option key={item.key} value={item.key}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {AllChannelCategory.length !== 0 ? (
                        <div className="col-sm-2">
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                            }}
                            value={ChannelCategory}
                          >
                            {AllChannelCategory.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}

                      <div className="col-sm-6">
                        <p className="card-title-lable" style={{  paddingRight: "137px" }}>{Title}</p>
                      </div>
                      <div className="col-sm-2">
                        <img
                          src={Download}
                          alt=""
                          style={{ float: "right", cursor: "pointer" }}
                          title="Download"
                          onClick={() => DownloadData()}
                        />
                        {/* <i
                          className="fas fa-download"
                          style={{
                            float: "right",
                            fontSize: "25px",
                            color: "#fff ",
                          }}
                        ></i> */}
                      </div>
                    </div>

                    <div className="row mt-5 mb-3">
                      <div className="col-sm-12">
                        <div className="row align-items-center sorting-btn-ui">
                          <div className="col-sm-3">
                            <ul>
                              <li onClick={() => DrillUpAll()} title="Drill Up">
                                <i
                                  className="fa fa-arrow-up"
                                  aria-hidden="true"
                                ></i>
                              </li>
                              <li
                                onClick={() => DrillDownAll()}
                                title="Drill Down"
                              >
                                <i
                                  className="fa fa-arrow-down"
                                  aria-hidden="true"
                                ></i>
                              </li>
                              <li onClick={() => DrillAll()} title="Expand All">
                                <i
                                  className="fa fa-sitemap"
                                  aria-hidden="true"
                                ></i>
                              </li>
                              <li onClick={() => ResetAll()} title="Reset">
                                <i className="fa" aria-hidden="true">
                                  &#8635;
                                </i>
                              </li>
                            </ul>
                          </div>
                          <div className="col-sm-9 custom-brdcrum">
                            {/* {props.BreadCrumbs.join(" / ")} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {WaterfallData.length > 0 && Categories.length > 0 ? (
                        <div className="h-450 mt-3" ref={refGraphDiv}>
                          <Waterfall
                            WaterfallData={WaterfallData}
                            xtitle={xtitle}
                            ytitle={ytitle}
                            dataplotRollOverHandler={dataplotRollOverHandler}
                            DrillDown={DrillDown}
                            DrillUp={DrillUp}
                            height={ChartHeight}
                            Categories={Categories}
                            NotAllow={NotAllow}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card">
                  <div
                    className="card-body brand-card"
                    style={{ height: "185px" }}
                  >
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">Summary</h3>
                      <div className="spends-list">
                        <ol style={{ color: "#CAD5DA" }}>
                          {SummaryFetched.map((item, index) => {
                            return <li key={index}>{item}</li>;
                          })}
                        </ol>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-body brand-card"
                    style={{ height: "445px" }}
                  >
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">{SelectedLabel}</h3>
                      <div
                        className="spends-list"
                        style={{ overflow: "auto", height: "120px" }}
                      >
                        {Summary.self &&
                        JSON.stringify(Summary.self) !== "{}" ? (
                          <ol style={{ color: "#CAD5DA", marginBottom: "0px" }}>
                            <li>
                              Total Contribution to {Analyse.BrandName}:
                              <span
                                style={{ fontWeight: "bold", color: "#D6FF41" }}
                              >
                                {Summary.y.toFixed(Decimal)}%
                              </span>
                              <br />
                            </li>
                            <li style={{ marginTop: "5px" }}>
                              Total Direct Contribution to {Analyse.BrandName}:
                              <span style={{ color: "#D6FF41" }}>
                                {(
                                  (Summary.self.value * 100) /
                                  Summary.y
                                ).toFixed(Decimal)}
                                % ({Summary.self.value.toFixed(Decimal)}% of{" "}
                                {Summary.y.toFixed(Decimal)}%)
                              </span>
                              <br />
                            </li>
                            <li style={{ marginTop: "5px" }}>
                              Total In-Direct Contribution to
                              {Analyse.BrandName}:
                              <span style={{ color: "#D6FF41" }}>
                                {(
                                  100 -
                                  (Summary.self.value * 100) / Summary.y
                                ).toFixed(Decimal)}
                                % (
                                {(Summary.y - Summary.self.value).toFixed(
                                  Decimal
                                )}
                                % of {Summary.y.toFixed(Decimal)}%)
                              </span>
                              <br />
                            </li>
                          </ol>
                        ) : (
                          <p
                            style={{
                              color: "#ffffff",
                              fontSize: "16px",
                              padding: "35px 22px",
                            }}
                          >
                            {AlternateNotes}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-sm-between">
                      {Channel.length ? (
                        <>
                          <div className="col-sm-12">
                            <span
                              className="cursor-pointer text-white chart-zoom-icon"
                              title="Full Screen"
                              onClick={() => setShowPopup(true)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi-fullscreen"
                                viewBox="0 0 16 16"
                              >
                                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                              </svg>
                            </span>
                            <Piechart
                              Channel={Channel}
                              SelectedLabel={SelectedLabel}
                              height="70%"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-sm-12 data-l-note"
                dangerouslySetInnerHTML={{ __html: Notes }}
              >
                {/* {Notes} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
