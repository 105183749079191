import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import accessibility from "highcharts/modules/accessibility";
import DATA from "./DATA";

// console.log(DATA.data.series);
accessibility(Highcharts);

const Chart = ({ height }) => {
  const chartOptions = {
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    chart: {
      type: "column",
      backgroundColor: "#1c2427",
      height: height + 50,
    },
    title: {
      text: DATA.data.charttitle,
      style: {
        color: "#D6FF41",
      },
    },
    xAxis: {
      categories: DATA.data.categories,
      title: DATA.data.xtitle,
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    yAxis: {
      min: 0,
      tickInterval: 5,
      gridLineColor: "#7d9ca6",
      gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: DATA.data.ytitle,
        style: {
          color: "#D6FF41",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          color:
            localStorage.getItem("CurrentTheme") === "Dark"
              ? "#FFFFFF"
              : "#1C2427",
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            click: function () {
              console.log("Clicked point:", this.options.y);
            },
          },
        },
      },
    },
    series: DATA.data.series,
    legend: {
      enabled: true,
      itemHoverStyle: {
        color: "#ffffff",
      },
      itemStyle: {
        color: "#ffffff",
      },
      symbolRadius: 0,
    },
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

const StackChart2 = ({ height }) => {
  return <Chart height={height} />;
};

export default StackChart2;
