import React from "react";
// import Legends from "./Legends";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ColumnChart = (props) => {

    const options = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                color: '#ffffff',
                labels: {
                    title: {
                        font: {
                            weight: 'bold'
                        }
                    }
                }
            },
            title: {
                display: true,
                text: props.data.charttitle,
                color: "#d6ff41"
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem, data) {
                        return tooltipItem.value + '%';
                    }
                }
            },
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                axis: {
                    display: true,
                    stacked: true
                },
                title: {
                    display: true,
                    text: props.data.ytitle,
                    color: "#d6ff41"
                },
                ticks: {
                    color: "#ffffff",
                    display: false,
                },
                grid: {
                    display: false
                }
            },
            x: {
                title: {
                    display: true,
                    text: props.data.xtitle,
                    color: "#d6ff41"
                },
                ticks: {
                    color: "#ffffff"
                },
                grid: {
                    color: '#ffffff',
                    display: true,
                    borderDash: [1],
                    tickLength: 0
                }
            }
        },
    };

    const data = {
        labels: props.data.chartdata.label,
        datasets: [
            {
                barThickness: 20,
                backgroundColor: props.data.chartdata.color,
                data: [props.data.chartdata.value[0], props.data.chartdata.value[1]]
                // data: [29.9,5]
            }
        ],
    };

    return (<>
        <Bar options={options} data={data} height={props.ChartHeight / 2} />
        {/* <div style={{
            marginTop: '15px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {
                props.data.chartdata.label.map((item, index) => {
                    return <Legends key={index} Name={item} Color={props.data.chartdata.color[index]} />
                })
            }
        </div> */}
    </>
    );
};

export default ColumnChart;