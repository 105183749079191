import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserVector from "../images/user-vector.png";
import axios from "../axios";
import { useNavigate } from "react-router";

const Rightbar = (props) => {
  const navigate = useNavigate();
  const [BrandsAll, setBrandsAll] = useState([]);
  const [Brand, setBrand] = useState("");
  const [KPIAll, setKPIAll] = useState([]);
  const [KPI, setKPI] = useState("");
  const [AnalyticsToolAll, setAnalyticsToolAll] = useState([]);
  const [AnalyticsTool, setAnalyticsTool] = useState("");

  useEffect(() => {
    axios.post("dashboard/").then((response) => {
      setBrandsAll(response.data.data);
      setBrand(response.data.data[0].brand_short_code);
      setKPIAll(response.data.data[0].kpi);
      setKPI(response.data.data[0].kpi[0].kpi_short_code);
      setAnalyticsToolAll(response.data.data[0].kpi[0].analysis_tools);
      setAnalyticsTool(response.data.data[0].kpi[0].analysis_tools[0].key);
    });

  }, []);

  const BrandHandler = (e) => {
    const value = e.target.value;

    for (var i = 0; i < BrandsAll.length; i++) {
      if (BrandsAll[i].brand_short_code === value) {
        setKPIAll(BrandsAll[i].kpi);
        setKPI(BrandsAll[i].kpi[0].kpi_short_code);
        setAnalyticsToolAll(BrandsAll[i].kpi[0].analysis_tools);
        setAnalyticsTool(BrandsAll[i].kpi[0].analysis_tools[0].key);
        break;
      }
    }
    setBrand(value);
  };

  const KPIHandler = (e) => {
    const value = e.target.value;

    for (var i = 0; i < KPIAll.length; i++) {
      if (KPIAll[i].kpi_short_code === value) {
        setAnalyticsToolAll(KPIAll[i].analysis_tools);
        setAnalyticsTool(KPIAll[i].analysis_tools[0].key);
        break;
      }
    }

    setKPI(value);
  };

  const SubmitAnalyse = () => {
    var BrandName = ''
    var i = 0

    for (i = 0; i < BrandsAll.length; i++) {
      if (BrandsAll[i].brand_short_code === Brand) {
        BrandName = BrandsAll[i].brand_display_name
        break;
      }
    }

    var KPIName = '';
    for (i = 0; i < KPIAll.length; i++) {
      if (KPI === KPIAll[i].kpi_short_code) {
        KPIName = KPIAll[i].kpi_display_name;
        break;
      }
    }

    const Analyse = {
      KPI: KPI,
      KPIName: KPIName,
      AnalyticsTool: AnalyticsTool,
      Brand: Brand,
      BrandName: BrandName,
      Menubar: AnalyticsToolAll,
    };
    localStorage.setItem("Analyse", JSON.stringify(Analyse));
    navigate("/" + AnalyticsTool);
  };

  return (
    <>
      <div className="right-bar">
        <div data-simplebar className="h-100">
          <div className="rightbar-title d-flex align-items-center px-3 py-4">
            <button
              className="right-bar-toggle ms-auto"
              onClick={() => props.setShow(!props.show)}
            >
              <i className="mdi mdi-close noti-icon"></i>
            </button>
          </div>

          <div className="px-6 py-4 ">
            <div className="row">
              <div className="col-sm-2">
                <img src={UserVector} alt="" style={{ marginTop: '7px' }} />
              </div>
              <div className="col-sm-10">
                <h3 className="m-0 me-2 h-menu-user-name">{localStorage.getItem("NameSkewb")}</h3>
                {/* <p className="mb-0 h-menu-user-profile">
                  CEO, COMPANY ABC LTD.
                </p> */}
              </div>
              <hr className="h-menu-user-hr" />
            </div>
          </div>
          <div className="px-6 pb-4">
            <div className="form-group mb-3 row">
              <div className="col-12">
                <h3>
                  <label
                    className="login-lable h-menu-label"
                  >
                    Select Brand
                  </label>
                </h3>
                <select
                  className="form-select"
                  value={Brand}
                  onChange={BrandHandler}
                >
                  {BrandsAll.map((item) => {
                    return (
                      <option
                        key={item.brand_short_code}
                        value={item.brand_short_code}
                      >
                        {item.brand_display_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group mb-3 row">
              <div className="col-12">
                <h3>
                  <label className="login-lable h-menu-label">Select KPI</label>
                </h3>
                <select
                  className="form-select"
                  value={KPI}
                  onChange={KPIHandler}
                >
                  {KPIAll.map((item) => {
                    return (
                      <option
                        key={item.kpi_short_code}
                        value={item.kpi_short_code}
                      >
                        {item.kpi_display_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group mb-3 row">
              <div className="col-12">
                <h3>
                  <label className="login-lable h-menu-label">
                    Select Analytics Tool
                  </label>
                </h3>
                <select
                  className="form-select"
                  value={AnalyticsTool}
                  onChange={(e) => setAnalyticsTool(e.target.value)}
                >
                  {AnalyticsToolAll.map((item) => {
                    return (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group mb-3 text-center row mt-3 pt-1">
              <div className="col-12 mt-3">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={SubmitAnalyse}
                >
                  Analyse
                </button>
              </div>
            </div>
          </div>

          <footer style={{ position: 'absolute', bottom: 0 }}>
            <div className="px-6 py-4">
              {
                localStorage.getItem("is_superuser") === "1" ? <div className="row py-2 ">
                  <div className="col-sm-12">
                    <Link to="/users" className="h-menu-link">
                      <p className="mb-0">
                        <u>USER MANAGEMENT</u>
                      </p>
                    </Link>
                  </div>
                </div> : null
              }
              <div className="row py-2 ">
                <div className="col-sm-12">
                  <Link to="" className="h-menu-link">
                    <p className="mb-0">
                      <u>CONTACT SUPPORT</u>
                    </p>
                  </Link>
                </div>
              </div>
              <div className="row py-2">
                <div className="col-sm-12">
                  <Link to="/logout" className="h-menu-link">
                    <p className="mb-0">
                      <u>LOGOUT</u>
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <div className="rightbar-overlay"></div>
    </>
  );
};

export default Rightbar;
