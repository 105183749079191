import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LTSTROI = (props) => {
  // console.log(props.height);
  const options = {
    chart: {
      type: "column",
      backgroundColor: "#1c2427",
      showAxes: true,
      height: props.height
      // height: ((9 / 16) * 100) - 5 + "%",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.Categories,
      // categories: ["Channel1", "Channel2", "Channel3"],
      title: {
        text: props.Xtitle,
        style: {
          color: "#FFFFFF",
        },
      },
      crosshair: true,
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
    },
    yAxis: {
      min: 0,
      gridLineColor: '#7d9ca6',
      gridLineDashStyle: 'ShortDash',
      visible: true,
      lineWidth: 1,
      title: {
        text: props.Ytitle,
        style: {
          color: "#FFFFFF",
        },
      },
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
    },
    tooltip: {
      headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><span style="font-size:10px;">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="padding:0">{series.name}: </td>' +
        '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table></div>",
      shared: true,
      useHTML: true,
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      borderRadius: 8
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
        }
        // events: {
        //   click: (e) => {
        //     console.log(e)
        //   }
        // }
      },
    },
    legend: {
      enabled: true,
      itemHoverStyle: {
        color: '#ffffff'
      },
      itemStyle: {
        color: '#ffffff'
      },
      symbolRadius: 0
    },
    series: props.Series,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          className: "highcharts-contextbutton",
          enabled: true,
          symbol: 'download',
          symbolStroke: "#ffffff",
          theme: {
            fill: '#1c2427',
          },
          titleKey: ""
        }
      }
    }
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={options} {...props} />
  );
};

export default LTSTROI;
