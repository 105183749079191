import React, { useState, useEffect, useRef } from "react";
// import Download from '../../images/download-icon.png'
import circle from '../../images/c.png'
import tringle from '../../images/t.png'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);


const LineChart = (props) => {
  const refGraphDiv = useRef(null);

  const [graphData, setgraphData] = useState([])

  useEffect(() => {
    let graphDataTemp = [];
    for (let index = 0; index < props.series.length; index++) {
      const element = props.series[index];
      // const data = element.data.slice(0, 199)
      // const data = element.data
      // if (element.name === "OOH") {
      //   data[5].marker.enabled = true
      //   data[5].marker.radius = 4
      // }

      const obj = {
        type: "line",
        name: element.name,
        marker: element.marker,
        data: element.data,
        showInLegend: props.SelectedChannel !== 'All Channels' ? false : true,
        color: element.color ? element.color : "#D6FF41",
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          formatter: function (e) {
            if ("marker" in this.point) {
              return (
                "<div style='color:#ffffff;font-family:Inter;'>" +
                // this.point.marker.name +
                // "<br>" +
                this.x +
                "</div>"
              );
            } else {
              return "";
            }
          },
          useHTML: true
        },
      };
      graphDataTemp.push(obj);
    }

    setgraphData(graphDataTemp)
  }, [props.series, props.SelectedChannel])

  const [ChartHeight, setChartHeight] = useState('')
  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight)
    }
  }, [graphData])

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     graphData: []
  //   };
  // }

  // componentDidMount() {
  //   let graphData = [];
  //   for (let index = 0; index < this.props.series.length; index++) {
  //     const element = this.props.series[index];
  //     const obj = {
  //       type: "line",
  //       name: element.name,
  //       marker: element.marker,
  //       data: element.data,
  //       showInLegend: this.props.SelectedChannel !== 'All Channels' ? false : true,
  //       color: element.color ? element.color : "#D6FF41",
  //       dataLabels: {
  //         enabled: true,
  //         allowOverlap: true,
  //         formatter: function (e) {
  //           if ("marker" in this.point) {
  //             return (
  //               "<div style='color:#ffffff;font-family:Inter;'>" +
  //               // this.point.marker.name +
  //               // "<br>" +
  //               this.x +
  //               "</div>"
  //             );
  //           } else {
  //             return "";
  //           }
  //         },
  //         useHTML: true
  //       },
  //     };
  //     graphData.push(obj);
  //   }

  //   this.setState({ graphData: graphData })
  // }

  // componentWillUnmount() {
  //   this.setState({ graphData: [] })
  // }

  // render() {
  const options = {
    title: {
      text: props.ChartTitle,
      style: {
        color: "#D6FF41",
        fontSize: "15px"
      },
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: "#1c2427",
      type: "line",
      height: ChartHeight,
      // height: "65%",
      showAxes: true,
    },
    xAxis: {
      visible: true,
      lineWidth: 1,
      title: {
        enabled: true,
        text: props.xunits,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
      plotBands: [
        {
          from: props.plotBands.from,
          to: props.plotBands.to,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, "rgb(214,255,65,0.2)"],
              [0.5, "rgba(214,255,65,0.2)"],
              [1, "rgba(214,255,65)"],
            ],
          },
        },
      ],
      categories: props.labels,
    },
    yAxis: {
      visible: true,
      lineWidth: 1,
      title: {
        enabled: true,
        text: props.yunits,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        formatter: function () {
          return this.value + " %";
        },
        style: {
          color: "#FFFFFF",
        },
      },
    },
    tooltip: {
      enabled: props.SelectedChannel !== 'All Channels' ? true : false,
      headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><span style="font-size:10px;">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="padding:0">{series.name}: </td>' +
        '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table></div>",
      shared: true,
      useHTML: true,
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      borderRadius: 8
    },
    // plotOptions: {
    //   series: {
    //     lineColor: "#D6FF41",
    //   },
    // },
    legend: {
      enabled: true,
      itemStyle: {
        color: '#fff'
      },
      itemHoverStyle: {
        color: '#fff'
      }
    },
    series: graphData,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          enabled: true,
          className: "highcharts-contextbutton",
          symbol: 'menu',
          symbolStroke: "#ffffff",
          theme: {
            fill: '#1c2427',
          },
          titleKey: "",
          menuItems: ["viewFullscreen"]
        }
      }
    }
  };

  return (
    <>
      <div className={props.height ? props.height + " row align-items-center justify-content-sm-between" : "h-500 row align-items-center justify-content-sm-between"}>

        {/* <div style={{ height: props.height ? props.height : '450px' }} ref={refGraphDiv}> */}
        <div className={props.height ? props.height : "h-500"} ref={refGraphDiv}>
          {
            graphData.length === props.series.length ? <HighchartsReact highcharts={Highcharts} options={options} /> : null
          }
        </div>
        {
          props.height ? null :
            <div className="row ch-movement-legend" >
              <div className="col-sm-12">
                <ul className="m-0 p-0 text-center">
                  <li className="px-2">
                    <div className="d-flex align-items: center">
                      <img src={tringle} alt="" style={{ width: "15px", height: "15px", marginRight: "2px" }} />
                      <p className="m-0 legend-title">Sustenance Point</p>
                    </div>
                  </li>
                  <li className="px-2">
                    <div className="d-flex">
                      <img src={circle} alt="" style={{ width: "15px", height: "15px", marginRight: "2px" }} />
                      <p className="m-0 legend-title">Operating Point</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
        }

      </div>
    </>
  );
  // }

};

export default LineChart;
