import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import LineChart from "./LineChart";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from '../../images/download-icon.png'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SummaryAdstock from "./SummaryAdstock";
import PopUp from "../../Components/PopUp";

const Main = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [ChannelAll, setChannelAll] = useState([]);
  const [SelectedChannel, setSelectedChannel] = useState("");
  const [ROCData, setROCData] = useState({});
  const [AdstockData, setAdstockData] = useState({});
  const [Summary, setSummary] = useState([]);

  const [ChartData, setChartData] = useState([]);
  const [SelectedChartData, setSelectedChartData] = useState({});

  const [Notes, setNotes] = useState('');

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_roc_channels/", { brand: Analyse.Brand, kpi: Analyse.KPI })
      .then((response) => {
        if (response.data.error !== 1) {
          setChannelAll(response.data.data);
          setSelectedChannel(response.data.data[0].key);
          setNotes(response.data.notes)
        } else {
          setChannelAll([]);
          setSelectedChannel('');
          setNotes('')
          toast.error(response.data.erroMsg);
        }
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.KPI, Analyse.Brand]);

  useEffect(() => {
    setLoader(true);
    if (SelectedChannel) {
      setROCData({});
      setAdstockData({})
      setSummary([]);
      axios
        .post("get_roc/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          channel: SelectedChannel,
        })
        .then((response) => {
          if (response.data.error === 1) {
            setROCData({});
            setSummary([]);
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            if (SelectedChannel === 'All Channels') {
              setROCData(response.data.data);
              setChartData([])
              setSelectedChartData({})
            } else {
              setAdstockData(response.data.data.adstock)
              setChartData(response.data.data.chart_data)
              setSelectedChartData(response.data.data.chart_data[0])
            }
            setSummary(response.data.data.summary);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [SelectedChannel, Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (JSON.stringify(SelectedChartData) !== '{}') {
      setROCData(SelectedChartData.data);
    }
  }, [SelectedChartData]);

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("roc/", {
        data: ROCData
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const [ShowPopup, setShowPopup] = useState(false)

  return (
    <>
      {loader ? <Loader /> : null}
      {
        ShowPopup ? <PopUp setShowPopup={setShowPopup}>
          {JSON.stringify(AdstockData) !== '{}' ? (
            <LineChart
              ChartTitle={AdstockData.data.title}
              series={AdstockData.data.series}
              xunits={AdstockData.data.xunits}
              plotBands={AdstockData.data.plotBands}
              labels={AdstockData.data.labels}
              yunits={AdstockData.data.yunits}
              SelectedChannel={''}
              height={'h-100-per'}
            />
          ) : null}
        </PopUp> : null
      }
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Channel Efficacy ROC */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid main-content-w">
            <div className="row">
              <div className="col-xl-8">
                <div className="card card-min-height align-items-sm-center">
                  <div className="row w-100">
                    <div className="col-sm-3 channel-card">
                      <h3 className="data-spends my-2" style={{ marginLeft: '20px' }}>Channels</h3>
                      <div className="d-flex flex-column channel-list ">
                        {ChannelAll.map((item) => {
                          return (
                            <Link
                              key={item.key}
                              className={
                                SelectedChannel === item.key
                                  ? "channels-link active"
                                  : "channels-link"
                              }
                              onClick={() => {
                                // setROCData({});
                                setSelectedChannel(item.key)
                              }}
                              to=""
                              role="tab"
                            >
                              {item.key}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-sm-9 brand-card ef-drivers">
                      {JSON.stringify(ROCData) !== '{}' ? (<>
                        <div className="row align-items-center justify-content-sm-between pb-24">
                          <div className="col-sm-11 d-flex align-items-center justify-content-sm-end">
                            {
                              ChartData.length > 0 ?
                                // <ul className="p-0 m-0">
                                //   {
                                ChartData.map((item, index) => {
                                  return (
                                    // <li key={index} className="cm-filter-option-li cursor-pointer" onClick={() => setSelectedChartData(item)}>
                                    //   <span className={SelectedChartData === item ? "btn cm-drag-btn p-1 mx-2 active" : "btn cm-drag-btn p-1 mx-2"}>
                                    //     <p className="m-0 text-center px-1" style={{ width: '150px' }}>{item.tab_name}</p>
                                    //   </span>
                                    // </li>
                                    <p key={index} className={SelectedChartData === item ? "m-0 px-2 mx-2 cursor-pointer text-white lt-st" : "m-0 px-2 mx-2 cursor-pointer text-lable text-white"} onClick={() => setSelectedChartData(item)}>{item.tab_name}</p>
                                  )
                                })
                                //   }
                                // </ul> 
                                : null
                            }
                          </div>
                          <div className="col-sm-1 d-flex align-items-center justify-content-sm-end">
                            <img src={Download} alt="" style={{ float: "right", cursor: 'pointer' }} title="Download" onClick={() => { DownloadData() }} />
                          </div>
                        </div>
                        {
                          ROCData.series.length > 0 ? <LineChart
                            ChartTitle={ROCData.title}
                            series={ROCData.series}
                            xunits={ROCData.xunits}
                            plotBands={ROCData.plotBands}
                            labels={ROCData.labels}
                            yunits={ROCData.yunits}
                            SelectedChannel={SelectedChannel}
                          /> : null
                        }

                      </>) : null}
                    </div>
                  </div>
                </div>
              </div>

              <SummaryAdstock Summary={Summary} AdstockData={AdstockData} setShowPopup={setShowPopup} />

              {/* <div className="col-xl-3">
                <div className="card card-min-height">
                  <div className="card-body brand-card">
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">Summary</h3>
                      <div className="spends-list my-2">
                        {Summary
                          ? Summary.map((item, index) => {
                            return (
                              <p key={index} className="cer-summary-p">
                                {item}
                              </p>
                            );
                          })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>

            <div className="row">
              <div className="col-sm-12 data-l-note" dangerouslySetInnerHTML={{ __html: Notes }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
