import React, { useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsSunburst from "highcharts/modules/sunburst";
HighchartsSunburst(Highcharts);

const KPISunburst = (props) => {
  const refChart = useRef()

  let RootNade = ''
  let options = {
    chart: {
      backgroundColor: "#1c2427",
      height: props.height,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    series: [
      {
        type: "sunburst",
        data: props.KpiSunburstData,
        allowDrillToNode: true,
        cursor: "pointer",
        point: {
          events: {
            click: function (e) {
              // var series = refChart.current.chart.series[0]
              // series.onClickDrillToNode({ point: e.point });
              var series = refChart.current.chart.series[0]
              if (RootNade === e.point.id) {
                RootNade = e.point.parent
                series.setRootNode(e.point.node.parentNode.id, true, { trigger: 'click' })
              } else {
                RootNade = e.point.id
                series.setRootNode(e.point.id, true, { trigger: 'click' })
              }
            }
          }
        },
        dataLabels: {
          format: '{point.name} ({point.value})',
          borderWidth: 0,
          filter: { property: "innerArcLength", operator: ">", value: 16 },
          style: { textShadow: false, textOutline: null, color: "contrast" },
        },
        levels: [
          {
            level: 1,
            levelIsConstant: false,
            dataLabels: {
              filter: { property: "outerArcLength", operator: ">", value: 64 },
            },
            colorByPoint: true,
          },
          { level: 2, colorVariation: { key: "brightness", to: -0.3 } },
          { level: 3, colorVariation: { key: "brightness", to: -0.3 } },
        ],
        // showInLegend:true,
      },
    ],
    tooltip: {
      headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;">',
      pointFormat: '{point.name}:{point.value:.1f}',
      footerFormat: "</div>",
      shared: true,
      useHTML: true,
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      borderRadius: 8
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          enabled: true,
          symbol: "download",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427"
          },
          titleKey: ""
        }
      }
    }
  };

  return (
    <HighchartsReact ref={refChart} highcharts={Highcharts} options={options} />
  );
};

export default KPISunburst;
