import React from "react";
import Legends from "./Legends";

const ItemWise = (props) => {
  return (
    <>
      <div className="row my-1 flex-nowrap" style={{ paddingRight: "40px" }}>
        <div className="custom-col-sm-1">
          <p className="m-0 vertical-text"><i className="ri-arrow-up-line my-2"></i> {props.Ytitle}</p>
        </div>
        <div className="col-sm-12 px-0">
          <div className="gr-table-x-title-div ">
            <p className="m-0 gr-table-x-title">{props.Xtitle} <i className="ri-arrow-right-line mx-2"></i></p>
          </div>
          <div className="table-responsive gains-risk-table" style={{ height: "470px", overflowY: "scroll" }}>
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th className="caption"></th>
                  {props.IndChannels.map((item) => {
                    return <th key={item}>{item}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {props.DirectChannels.map((item) => {
                  return (
                    <tr key={item.ChannelName}>
                      <td className="channel-name">{item.ChannelName}</td>
                      {item.ChannelData.map((data, index) => {
                        return (
                          <td
                            key={index}
                            style={{ color: data.Color }}
                          >
                            {data.Value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row my-3 d-flex justify-content-sm-end">
        <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
          {props.Legends.map((item, index) => {
            return <Legends key={index} item={item} />;
          })}
        </div>
      </div>
    </>
  );
};

export default ItemWise;
