import React from 'react'

const FooterOptimise = (props) => {
    return (
        <tfoot>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th colSpan="2">
                    {JSON.stringify(props.NewFixedData) === JSON.stringify(props.NewData) ? (
                        <button className="btn edit-spends-btn-info" type="button" style={{ margin: 0 }}>
                            Re-Optimise
                        </button>
                    ) : (
                        <button
                            onClick={() => props.Optimise()}
                            className="btn channel-allowcation-btn-info"
                            type="button"
                            style={{ padding: '5px 10px' }}
                        >
                            Re-Optimise
                        </button>
                    )}
                    {/* <button className="btn edit-spends-btn-info" type="button">Re-Optimise</button> */}
                    {/* <button className="btn edit-spends-btn-info f-right" type="button">Save Results</button> */}
                    <button
                        className="btn channel-allowcation-btn-info f-right"
                        type="button"
                        onClick={() => props.setSaveResult(true)}
                        style={{ padding: '5px 10px' }}
                    >
                        Save Result
                    </button>
                </th>
            </tr>
        </tfoot>
    )
}

export default FooterOptimise