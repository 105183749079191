import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// https://medium.com/geekculture/creating-multi-select-dropdown-with-checkbox-in-react-792ff2464ef3
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
	return (
		<div className="custom-select-option" style={{ padding: 0 }}>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

const MeasurementSummary = (props) => {
	const [FilterType, setFilterType] = useState(true)
	const [SearchFilter, setSearchFilter] = useState('')

	return (
		<div className={props.ZoomIn? "col-sm-12 col-md-12 col-lg-12 col-xl-2" : "col-sm-12 col-md-12 col-lg-12 col-xl-4"}>
			<div className="card custom-summary">
				<div className="card-body height-656 p-0">
					<div className="card-3">
						<div className="custom-header mb-1">
							<span className="header-title cursor-pointer" onClick={() => setFilterType(!FilterType)}>
								<h3 className="data-spends my-2 px-2">Analytics Filter</h3>
								{
									FilterType ? <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i> &nbsp;</h3> : <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i> &nbsp;</h3>
								}
							</span>
						</div>
						<div className={!FilterType ? "custom-footer active mb-1" : "custom-footer mb-1"}>
							<span className="footer-title cursor-pointer" onClick={() => setFilterType(!FilterType)}
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<h3 className="data-spends my-2 px-2">Summary</h3>
								{
									!FilterType ? <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i>&nbsp;</h3> : <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;</h3>
								}
							</span>
						</div>

						<div className="custom-body" style={{ overflowX: 'hidden', height: '100%',  overflowY: 'auto' }}>

							<DragDropContext onDragEnd={props.onDragEnd}>
								<div className="row">
									<div className="col-sm-12 p-0" style={{ overflow: 'auto', height: 'auto' }}>
										{
											FilterType ? <>
												<div className="custom-body" style={{ overflowX: 'hidden', height: '100%',  overflowY: 'auto' }}>
													<div className="row">
														<Droppable droppableId={'active'}>
															{(provided) => (
																<div className={props.ZoomIn? "col-sm-12 cm-drop border-right-2x":"col-sm-6 cm-drop border-right-2x" } {...provided.droppableProps} ref={provided.innerRef}>
																	<span className="my-0 cm-search">
																		<h3 className="data-spends m-0 text-left">Filters</h3>
																	</span>

																	<div className="card m-0">
																		{
																			props.ActiveFilters.map((item, index) => {
																				return (
																					<Draggable key={item.name} draggableId={item.name} index={index}>
																						{(provided) => (
																							<span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="btn my-2 cm-drop-btn">
																								<>
																									<div className="drop-header">
																										<p className="m-0 text-left">{item.name}</p>
																										<p className="m-0"><i className="fa fa-bars" aria-hidden="true"></i></p>
																									</div>
																									<ReactSelect
																										options={item.filters}
																										isMulti
																										closeMenuOnSelect={false}
																										hideSelectedOptions={false}
																										components={{
																											Option
																										}}
																										onChange={(selected) => {
																											if (selected !== null && selected.length > 0) {
																												if (selected[selected.length - 1].value === 'All') {
																													return props.onChangeFilter(selected[selected.length - 1], index)
																												} else {
																													for (var i = 0; i < selected.length; i++) {
																														if (selected[i].value === 'All') {
																															selected.splice(i, 1)
																														}
																													}
																												}
																											}
																											return props.onChangeFilter(selected, index)
																										}}
																										allowSelectAll={true}
																										value={item.Selected ? item.Selected : null}
																										isClearable={false}
																									/>
																								</>
																							</span>
																						)}
																					</Draggable>
																				)
																			})
																		}
																		{provided.placeholder}
																	</div>
																</div>
															)}
														</Droppable>
														{!props.ZoomIn ?
														<Droppable droppableId={'inactive'}>
															{(provided) => (
																<div className= {props.ZoomIn ? null : "col-sm-6 cm-drag"} {...provided.droppableProps} ref={provided.innerRef}>
																	<span className="my-0 cm-search">
																		<input className="form-control prefix-txt" type="text" placeholder="Search" value={SearchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
																		<span className="prefixsearch"><i className="fa fa-search" aria-hidden="true"></i></span>
																	</span>

																	<div className="card m-0">
																		{
																			props.AllFilters.map((item, index) => {
																				const dragClass = item.name.toLowerCase().includes(SearchFilter.toLowerCase()) ? "btn my-2 cm-drag-btn" : "btn my-2 cm-drag-btn hide-ele"
																				return (
																					<Draggable key={item.name} draggableId={item.name} index={index}>
																						{(provided) => (
																							<span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={dragClass}>
																								<p className="m-0 text-left">{item.name}</p>
																								<p className="m-0"><i className="fa fa-bars" aria-hidden="true"></i></p>
																							</span>
																						)}
																					</Draggable>
																				)
																			})
																		}
																		{provided.placeholder}
																	</div>
																</div>
															)}
														</Droppable> : null}
													</div>
												</div>
											</> : <ol>
												{props.Summary.map((item) => {
													return (
														<li key={item} className="spends-list-li">
															<p>{item}</p>
														</li>
													);
												})}
											</ol>
										}
									</div>
								</div>
							</DragDropContext>


						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default MeasurementSummary