import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setToken } from "../redux/actions/TokenAction";


const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    localStorage.clear();
    dispatch(setToken(""));
    navigate("/login");
  })
  return (
  <div></div>
  );
};

export default Logout;
