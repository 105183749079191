import React, { useState, useEffect, useRef } from 'react'
import ColumnChart from '../../Charts/ColumnChart'
import LineChart from '../../Charts/LineChart'
import StackChart from '../../Charts/StackChart'
import WaterfallChart from '../../Charts/WaterfallChart'
import Legends from "./Legends";
import circle from '../../images/c.png'
import tringle from '../../images/t.png'
// import tringleCircle from '../../images/t-c.png'

const Growth = (props) => {
    const refGraphDiv = useRef(null)
    const [ChartData, setChartData] = useState({})
    const [OptChart, setOptChart] = useState(props.OptChartdict.length > 0 ? 0 : '')
    const [LineFilter, setLineFilter] = useState('')
console.warn(JSON.stringify(ChartData))

    useEffect(() => {
        if (OptChart !== '') {
            setChartData(props.OptChartdict[OptChart])

            if (props.OptChartdict[OptChart] && props.OptChartdict[OptChart].type === "line") {
                setLineFilter(0)
            }
        }
    }, [OptChart, props.OptChartdict])

    const [ChartHeight, setChartHeight] = useState('')
    const [ChartWidth, setChartWidth] = useState('')
    useEffect(() => {
        if (refGraphDiv.current !== null) {
            setChartHeight(refGraphDiv.current.clientHeight)
            setChartWidth(refGraphDiv.current.clientWidth)
        }
    }, [])

    return (
        <>
            <div className="row">
                {
                    props.OptChartdict.length > 0 ?
                        <>
                            <div className="col-sm-11 my-1 pb-2 d-flex justify-content-center">
                                <select className="form-select" style={{ border: '1px solid #7D9CA6', borderRadius: '5px', fontSize: '16px', width: '200px' }} value={OptChart} onChange={(e) => setOptChart(e.target.value)}>
                                    {
                                        props.OptChartdict.map((item, index) => {
                                            return <option key={index} value={index}>{item.name}</option>
                                        })
                                    }
                                </select>

                                {
                                    (JSON.stringify(ChartData) !== '{}' && ChartData.type === 'line') ?
                                        <select className="form-select" style={{ border: '1px solid #7D9CA6', borderRadius: '5px', fontSize: '16px', width: '150px', marginLeft: '5px' }} value={LineFilter} onChange={(e) => setLineFilter(e.target.value)}>
                                            {
                                                ChartData.data.map((item, index) => {
                                                    return <option key={index} value={index}>{item.filter}</option>
                                                })
                                            }
                                        </select> : null
                                }
                            </div>
                            <div className="col-sm-1 my-1 pb-2 d-flex justify-content-center">
                                <span className="cursor-pointer text-white" title="Full Screen" onClick={() => props.setChartDataMain(ChartData, LineFilter)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi-fullscreen" viewBox="0 0 16 16">
                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                                    </svg>
                                </span>
                            </div>
                        </>
                        : null
                }
            </div>
            <div className="row">
                {/* <div className="col-sm-12" style={{ height: '46vh', overflow: 'auto', padding: '14% 0%' }}> */}
                <div className="col-sm-12">
                    <div className="custom-chart-height" ref={refGraphDiv} style={ChartData.type === 'column' ? { height: ChartHeight - 25 } : {}}>
                        {
                            (JSON.stringify(ChartData) !== '{}') ?
                                <>
                                    {ChartData.type === 'column' && JSON.stringify(ChartData.data) !== '{}' ? <ColumnChart data={ChartData.data} ChartHeight={ChartHeight} ChartWidth={ChartWidth} /> : null}
                                    {ChartData.type === 'line' && JSON.stringify(ChartData.data) !== '{}' ? <LineChart data={ChartData.data[LineFilter]} ChartHeight={ChartHeight} ChartWidth={ChartWidth} /> : null}
                                    {ChartData.type === 'stack' && JSON.stringify(ChartData.data) !== '{}' ? <StackChart data={ChartData.data} ChartHeight={ChartHeight} ChartWidth={ChartWidth} /> : null}
                                    {ChartData.type === 'waterfall' && JSON.stringify(ChartData.data) !== '{}' ? <WaterfallChart data={ChartData.data} ChartHeight={ChartHeight} ChartWidth={ChartWidth} /> : null}
                                </>
                                : null
                        }
                    </div>
                    {ChartData.type === 'column' && JSON.stringify(ChartData.data) !== '{}' ?
                        <div style={{
                            marginTop: '15px',
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {
                                ChartData.data.chartdata.label.map((item, index) => {
                                    return <Legends key={index} Name={item} Color={ChartData.data.chartdata.color[index]} />
                                })
                            }
                        </div>
                        : null}
                    {ChartData.type === 'line' && JSON.stringify(ChartData.data) !== '{}' ? <>
                        <div className="row ch-movement-legend" style={{ marginTop: "-15px" }}>
                            <div className="col-sm-12">
                                <ul className="m-0 p-0 text-center">
                                    <li className="px-2">
                                        <div className="d-flex align-items: center">
                                            <img src={tringle} alt="" style={{ width: "20px" }} />
                                            <p className="m-0 legend-title">New Spends</p>
                                        </div>
                                    </li>
                                    <li className="px-2">
                                        <div className="d-flex">
                                            <img src={circle} alt="" style={{ width: "20px" }} />
                                            <p className="m-0 legend-title">Old Spends</p>
                                        </div>
                                    </li>
                                    {/* <li className="px-2">
                                        <div className="d-flex">
                                            <img src={tringleCircle} alt="" style={{ width: "20px" }} />
                                            <p className="m-0 legend-title">New &amp; Old Spends</p>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </> : null}
                </div>
            </div>
        </>
    )
}

export default Growth