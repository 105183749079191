import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoMark from "../images/skewb-logomark.png";
import Rightbar from "./Rightbar";

const Topbar = (props) => {
  // console.log(props.Brands)
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [Count, setCount] = useState(0);
  const[BrandName, setBrandName] = useState()
  const[KPIName, setKPIName] = useState()

  useEffect(() => {
    const handle = setInterval(() => {
      setCount(Count + 1);
    }, 1000);

    return () => {
      clearInterval(handle);
    };
  }, [Count]);

  useEffect(()=>{
    const Analyse = JSON.parse(localStorage.getItem("Analyse"));

    setBrandName((location.pathname === '/home' || location.pathname === '/') ? '' : Analyse ? Analyse.BrandName : '')
    setKPIName((location.pathname !== '/home') ? Analyse ? Analyse.KPIName : '' : '')
    
  }, [Count])

  return (
    <div>
      <header id="page-topbar">
        <div className="navbar-header max-w-100 p-0 justify-content-sm-evenly">
          <div className="row w-100">
            <div className="col-sm-1">
              <div className="d-flex justify-content-center">
                <div className="navbar-brand-box">
                  <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={LogoMark} alt="logo-sm" height="32" />
                    </span>
                    <span className="logo-lg">
                      <img src={LogoMark} alt="logo-dark" height="32" />
                    </span>
                  </Link>
                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={LogoMark} alt="logo-sm-light" height="32" />
                    </span>
                    <span className="logo-lg">
                      <img
                        src={LogoMark}
                        alt="logo-light"
                        height="32"
                      />
                    </span>
                  </Link>
                </div>
                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-24 d-lg-none header-item"
                  data-bs-toggle="collapse"
                  data-bs-target="#topnav-menu-content"
                >
                  <i className="ri-menu-2-line align-middle"></i>
                </button>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="d-flex">
                <div className="d-none d-lg-block">
                  <div className=" d-none d-lg-inline-block ms-1">
                    {BrandName ? (
                      <Link
                        className="btn header-item noti-icon waves-effect px-0 d-flex align-items-center"
                        to="/home"
                      >
                        <i className="ri-arrow-left-s-line me-2"></i>
                        <p className="m-0 d-flex align-items-center h-breadcrumb-item">
                          Portfolio Landscape
                        </p>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 d-flex align-items-center justify-content-center   ">
              <div>
                <div className="d-inline-block">
                  <h2 className="heading-menu-title">{BrandName ? BrandName + ' - ' + KPIName : 'PORTFOLIO LANDSCAPE'}</h2>
                </div>
              </div>
            </div>
            <div className="offset-sm-1 col-sm-2">
              <div className="d-flex justify-content-center">
                <div className="dropdown d-inline-block">
                  <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                    onClick={() => setShow(!show)}
                  >
                    {/* <i className="ri-function-fill"></i> */}
                    <img src="assets/images/Vector.png" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {show ? <Rightbar show={show} setShow={setShow} /> : null}
    </div>
  );
};

export default Topbar;
