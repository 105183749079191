import React, { useEffect, useState, useRef } from "react";
import MeasurementSummary from "./MeasurementSummary";
import { toast } from "react-toastify";
import StackChart from "./StackChart";

const Measurement = (props) => {
  // console.log("ZoomIn", props.ZoomIn)
  const refGraphDiv = useRef(null);
  // const [ZoomIn, setZoomIn] = useState(false);

  const [ChartHeight, setChartHeight] = useState("");
  useEffect(() => {
    // setFlag(true);
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, []);

  return (
    <div className="container-fluid main-content-w">
      <div className="row">
        <div
          className={
            props.ZoomIn ? "col-xl-10  cm-chart" : "col-xl-8  cm-chart"
          }
        >
          <div className="card card-min-height">
            <div className="card-body brand-card">
              <div className="row align-items-center justify-content-sm-between pb-2 ef-drivers">
                <div className="col-sm-12">
                  <p className="card-title-lable">
                    EFFECTIVENESS MEASUREMENT OVER TIME
                    <i
                      style={{
                        float: "right",
                        color: "#ffffff",
                        cursor: "pointer",
                      }}
                      className={
                        !props.ZoomIn
                          ? "fa fa-window-maximize"
                          : "fa fa-window-minimize"
                      }
                      aria-hidden="true"
                      onClick={() => {
                        props.HandleZoom()
                        // props.setZoomIn(!props.ZoomIn);
                      }}
                      title={props.ZoomIn ? "Minimize" : "Maximize"}
                    ></i>
                  </p>
                </div>
              </div>
              <div className="row align-items-center ce-filter">
                <div className="col-sm-12 col-md-12 col-lg-2 d-flex justify-content-center my-3">
                  <select
                    className="form-select w-100"
                    value={props.timeframe}
                    onChange={props.onChangeHandler}
                  >
                    {props.TimeFramesAll.map((item) => {
                      return (
                        <option key={item.key} value={item.key}>
                          {item.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-10 d-flex align-items-center justify-content-center my-3">
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <h3 className="m-0">
                        <label className="login-lable brand-menu-label m-0">
                          For time period:
                        </label>
                      </h3>
                    </div>
                    <div className="col-sm-3">
                      <select
                        className="form-select"
                        value={props.DefaultStartDate}
                        onChange={(e) => {
                          props.setDefaultStartDate(e.target.value);
                          if (
                            props.ToDatesAll.indexOf(props.DefaultEndDate) <
                            props.FromDatesAll.indexOf(e.target.value)
                          ) {
                            props.setDefaultEndDate(
                              props.ToDatesAll[
                                props.FromDatesAll.indexOf(e.target.value)
                              ]
                            );
                          }
                        }}
                      >
                        {props.FromDatesAll.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-1">
                      <h3 className="m-0">
                        <label className="login-lable brand-menu-label m-0">
                          To
                        </label>
                      </h3>
                    </div>
                    <div className="col-sm-3">
                      <select
                        className="form-select"
                        value={props.DefaultEndDate}
                        onChange={(e) => {
                          if (
                            props.FromDatesAll.indexOf(props.DefaultStartDate) >
                            props.ToDatesAll.indexOf(e.target.value)
                          ) {
                            // setTimePeriodError('Please enter valid time span')
                            toast.error("Please enter valid time period");
                          } else {
                            // setTimePeriodError('')
                            props.setDefaultEndDate(e.target.value);
                          }
                        }}
                      >
                        {props.ToDatesAll.map((item, index) => {
                          if (
                            props.FromDatesAll.indexOf(
                              props.DefaultStartDate
                            ) <= index
                          ) {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <button
                        className="btn brand-btn-info"
                        type="button"
                        onClick={() => {
                          props.setFetchCall(true);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center justify-content-center my-3">
                                    <button
                                        className="btn brand-btn-info"
                                        type="button"
                                        onClick={() => {
                                            props.ExpandAll()
                                        }}>
                                        Expand All
                                    </button>
                                </div> */}
              </div>
              <div className="row my-5">
                <div className="col-sm-12">
                  <div className="row align-items-center sorting-btn-ui">
                    <div className="col-sm-3">
                      <ul>
                        <li onClick={props.DrillUpAll} title="Drill Up">
                          <i className="fa fa-arrow-up" aria-hidden="true"></i>
                        </li>
                        <li onClick={props.DrillDownAll} title="Drill Down">
                          <i
                            className="fa fa-arrow-down"
                            aria-hidden="true"
                          ></i>
                        </li>
                        <li onClick={props.DrillAll} title="Expand All">
                          <i className="fa fa-sitemap" aria-hidden="true"></i>
                        </li>
                        <li onClick={props.ResetAll} title="Reset">
                          <i className="fa" aria-hidden="true">
                            &#8635;
                          </i>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-9 custom-brdcrum">
                      {props.BreadCrumbs.join(" / ")}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="h-400" ref={refGraphDiv}>
                    {props.categories.length > 0 && props.series.length > 0 ? (
                      <StackChart
                        categories={props.categories}
                        series={props.series}
                        xtitle={props.xtitle}
                        ytitle={props.ytitle}
                        charttitle={props.charttitle}
                        DrillDown={props.DrillDown}
                        DrillUp={props.DrillUp}
                        height={ChartHeight}
                        ZoomIn={props.ZoomIn}
                        NotAllow={props.NotAllow}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MeasurementSummary
          onDragEnd={props.onDragEnd}
          Summary={props.Summary}
          AllFilters={props.AllFilters}
          ActiveFilters={props.ActiveFilters}
          onChangeFilter={props.onChangeFilter}
          ZoomIn={props.ZoomIn}
        />
      </div>
      <div className="row">
        <div
          className="col-sm-12 data-l-note"
          dangerouslySetInnerHTML={{ __html: props.Notes }}
        ></div>
      </div>
    </div>
  );
};

export default Measurement;
