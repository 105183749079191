import React from 'react'

const Summary = (props) => {
    return (
        <div className="row">
            {
                
                props.OptSummarydict.map((item, index) => {
                    return (
                        <div key={index} className="col-sm-6">
                            <div className="card text-center summary-box" style={{ height: '145px' }}>
                                <div className={item.highlight === 1 ? "card-body card-gradient px-1 active" :  "card-body card-gradient px-1"} >
                                    <div className="summary-box-content" style={{ height: '110px' }}>
                                        <h5 className="py-1 m-0" dangerouslySetInnerHTML={{ __html: item.header }}></h5>
                                        <p className="py-1 m-0 summary-p">{item.value}</p>
                                        <p className="p-2 m-0 summary-footnote">{item.footnote}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Summary