import React from 'react'

const PopUp = (props) => {
    return (
        <div className="chart-overlay">
            <div className="chart-popup">
                <h5>{props.SelectedLabel}</h5>
                {props.children}
                <button className="close-popup" onClick={() => props.setShowPopup(false)}>&times;</button>
                {/* <div className="row">
                    <div className="col-sm-12"> */}

                {/* </div>
                </div> */}
            </div>
        </div>
    )
}

export default PopUp