import React, { useState } from 'react'
import LineChart from "./LineChart";

const SummaryAdstock = (props) => {
    const [FilterType, setFilterType] = useState(true)

    return (
        <div className="col-xs-12 col-md-12 col-lg-12 col-xl-4">
            <div className="card custom-summary">
                <div className="card-body height-656 p-0">
                    <div className="card-3">
                        <div className="custom-header mb-1">
                            <span className="header-title cursor-pointer" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => setFilterType(!FilterType)}>
                                <h3 className="data-spends my-2 px-2">Summary</h3>
                                {
                                    FilterType || JSON.stringify(props.AdstockData) === '{}' ? <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i>&nbsp;</h3> : <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;</h3>
                                }
                            </span>
                        </div>
                        {
                            JSON.stringify(props.AdstockData) !== '{}' ?
                                <div className={!FilterType ? "custom-footer active mb-1" : "custom-footer mb-1"}>
                                    <span className="footer-title cursor-pointer" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => setFilterType(!FilterType)}>
                                        <h3 className="data-spends my-2 px-2">Adstock</h3>
                                        {
                                            !FilterType ? <h3 className="m-0"><i className="fa fa-minus" aria-hidden="true"></i>&nbsp;</h3> : <h3 className="m-0"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;</h3>
                                        }
                                    </span>
                                </div> : null
                        }

                        {
                            FilterType || JSON.stringify(props.AdstockData) === '{}' ? <div className="custom-body px-3 py-1">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="p-0" style={{ height: "450px" }}>
                                            <ul>
                                                {
                                                    props.Summary.map((item, index) => {
                                                        return (
                                                            <li key={index} className="spends-list-li">{item}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> : <div className="custom-body px-3 py-1">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-sm-12 my-1 pb-2 d-flex justify-content-end">
                                                <span className="cursor-pointer text-white" title="Full Screen" onClick={() => props.setShowPopup(true)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi-fullscreen" viewBox="0 0 16 16">
                                                        <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="p-0">
                                            {JSON.stringify(props.AdstockData) !== '{}' ? (
                                                <LineChart
                                                    ChartTitle={props.AdstockData.data.title}
                                                    series={props.AdstockData.data.series}
                                                    xunits={props.AdstockData.data.xunits}
                                                    plotBands={props.AdstockData.data.plotBands}
                                                    labels={props.AdstockData.data.labels}
                                                    yunits={props.AdstockData.data.yunits}
                                                    SelectedChannel={''}
                                                    height={'h-450'}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SummaryAdstock