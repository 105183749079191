import React from 'react'
import MixChart from './MixChart'

const TrendChart = (props) => {
    return (
        <div className="row my-3">
            <div className="col-sm-12">
                <p className="card-title-lable">&nbsp;</p>
                <div className="my-3" style={{ height: '400px' }}>
                    <MixChart ChartData={props.TrendChartdata} />
                </div>
            </div>
        </div>
    )
}

export default TrendChart