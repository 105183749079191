import React, { useEffect, useState, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import SummaryAnalytics from "./SummaryAnalytics";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MixChart from "./MixChart";

const Main = () => {
    const location = useLocation();
    const refGraphDiv = useRef(null);

    const Analyse = JSON.parse(localStorage.getItem("Analyse"));
    const [loader, setLoader] = useState(false);
    const [Notes, setNotes] = useState('')
    const [TimeFramesAll, setTimeFramesAll] = useState([]);
    const [timeframe, setTimeframe] = useState("");
    const [TableData, setTableData] = useState([]);
    const [SelectedTableData, setSelectedTableData] = useState('');
    const [SelectedTable, setSelectedTable] = useState({});
    const [Summary, setSummary] = useState([]);
    const [ChartData, setChartData] = useState({});

    useEffect(() => {
        setLoader(true);
        axios.post("get_timeframes/", 
        { 
            page: "validations",  
            brand: Analyse.Brand,
            kpi: Analyse.KPI 
        }).then((response) => {
            if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
            } else {
                setTimeFramesAll(response.data.data);
                setTimeframe(response.data.data[0].key);
                if('notes' in response.data){
                    setNotes(response.data.notes)
                }
                setLoader(false);
            }
        }).catch((data) => {
            setLoader(false);
        });
    }, [Analyse.Brand, Analyse.KPI ]);

    useEffect(() => {
        if (timeframe !== '') {
            setLoader(true);
            axios
                .post("get_validations/", {
                    brand: Analyse.Brand,
                    kpi: Analyse.KPI,
                    timeframe: timeframe
                })
                .then((response) => {
                    if (response.data.error === 1) {
                        toast.error(response.data.erroMsg);
                        setLoader(false);
                    } else {
                        setTableData(response.data.data.tabledata)
                        setSelectedTableData(response.data.data.tabledata[0].name)
                        setSummary(response.data.data.summary)
                        setChartData(response.data.data.chartdata)
                        if('notes' in response.data){
                            setNotes(response.data.notes)
                        }
                        setLoader(false);
                    }
                }).catch((data) => {
                    setLoader(false);
                });
        }
    }, [Analyse.Brand, Analyse.KPI, timeframe]);

    useEffect(() => {
        if (SelectedTableData !== '') {
            for (var i = 0; i < TableData.length; i++) {
                if (SelectedTableData === TableData[i].name) {
                    setSelectedTable(TableData[i])
                    break
                }
            }
        }
    }, [SelectedTableData, TableData]);

    const [ChartHeight, setChartHeight] = useState('')
    useEffect(() => {
        if (refGraphDiv.current !== null) {
            setChartHeight(refGraphDiv.current.clientHeight)
        }
    }, [])

    return (
        <>
            {loader ? <Loader /> : null}

            <ToastContainer theme="colored" />
            <Topbar />
            <Menubar />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid main-content-w">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                                    <h1 className="mb-sm-0 theme-color main-title">
                                        {
                                            (Analyse && Analyse.Menubar) ?
                                                Analyse.Menubar.map((item) => {
                                                    return ('/' + item.key === location.pathname) ? item.value : null
                                                })
                                                : null
                                        }
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid main-content-w">
                        <div className="row">
                            <div className="col-xl-8 cm-chart">
                                <div className="card card-min-height">
                                    <div className="card-body brand-card">
                                        <div className="row align-items-center ce-filter">
                                            <div className="col-sm-12 col-md-12 col-lg-2 d-flex justify-content-center my-1">
                                                <select className="form-select w-100" value={timeframe} onChange={(e) => setTimeframe(e.target.value)}>
                                                    {
                                                        TimeFramesAll.map((item) => {
                                                            return <option key={item.key} value={item.key}>{item.value}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row align-items-center justify-content-sm-between pb-3 ef-drivers">
                                            <div className="col-sm-12">
                                                <p className="card-title-lable">&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="row my-3">
                                            <div className="col-sm-12">
                                                <div className="my-1 h-500" ref={refGraphDiv}>
                                                    {
                                                        JSON.stringify(ChartData) !== '{}' ? <MixChart ChartData={ChartData} height={ChartHeight} /> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <SummaryAnalytics Summary={Summary} TableData={TableData} setSelectedTableData={setSelectedTableData} SelectedTableData={SelectedTableData} SelectedTable={SelectedTable} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 data-l-note" dangerouslySetInnerHTML={{ __html: Notes }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main