import React, { useEffect, useState, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import LTSTROI from "./LTSTROI";
import ComparativeROI from "./ComparativeROI";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from '../../images/download-icon.png'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
  const location = useLocation();
  const refGraphDiv = useRef(null);

  const [loader, setLoader] = useState(false);
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [TimeFrame, setTimeFrame] = useState("");
  const [Tabs, setTabs] = useState([]);
  const [ActiveTab, setActiveTab] = useState("");
  // const [ActiveTab, setActiveTab] = useState("LTSTROI");
  const [ActiveTimeFrame, setActiveTimeFrame] = useState("");
  const [Categories, setCategories] = useState([]);
  const [Series, setSeries] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [Years, setYears] = useState([]);
  const [ChannelDataAll, setChannelDataAll] = useState([]);
  const [Legends, setLegends] = useState([]);

  const [Notes, setNotes] = useState('');
  const [ChartTitle, setChartTitle] = useState('');
  const [Xtitle, setxTitle] = useState('');
  const [Ytitle, setyTitle] = useState('');

  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [FetchCall, setFetchCall] = useState(false);

  const[CategoryFlag, setCategoryFlag] = useState(false)



  useEffect(() => {
    axios
      .post("get_roi_tabs/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTabs(response.data.data.tabs)
          if (response.data.data.tabs.length > 0) {
            setActiveTab(response.data.data.tabs[0].tab)
          }
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (ActiveTab) {
      setLoader(true);
      axios
        .post("get_timeframes/", {
          page: ActiveTab + "_roi",
          brand: Analyse.Brand,
          kpi: Analyse.KPI
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setTimeFramesAll(response.data.data);
            setTimeFrame(response.data.data[0].key);
            setActiveTimeFrame(ActiveTab)
            setCategoryFlag(true)
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab,Analyse.Brand, Analyse.KPI]);
  useEffect(() => {
    if(CategoryFlag && TimeFrame){
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: TimeFrame,
          page: "roi",
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            }else{
              setChannelCategory('');
            }
            setCategoryFlag(false)
            setFetchCall(true);
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  } , [ActiveTab, Analyse.Brand, Analyse.KPI, TimeFrame])

  useEffect(() => {
    if (FetchCall && TimeFrame && ActiveTimeFrame === ActiveTab) {
      setLoader(true);
      axios
        .post("get_gain_risk_roi/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          tab: ActiveTab,
          timeframe: TimeFrame,
          category:ChannelCategory
        })
        .then((response) => {
          if (response.data.error !== 1) {
            if (ActiveTab === "comp") {
              setYears(response.data.data.ChannelYears);
              setChannelDataAll(response.data.data.ChannelData);
              setLegends(response.data.data.Legends)
            } else {
              setCategories(response.data.data.categories);
              setSeries(response.data.data.series);
            }
            setSummary(response.data.data.summary);
            setNotes(response.data.notes)
            setChartTitle(response.data.data.charttitle)
            setxTitle(response.data.data.xtitle)
            setyTitle(response.data.data.ytitle)
          } else {
            setYears([]);
            setChannelDataAll([]);
            setLegends([])
            setCategories([]);
            setSeries([]);
            setSummary([]);
            setNotes('')
            setChartTitle('')
            setxTitle('')
            setyTitle('')
            toast.error(response.data.erroMsg);
          }
          setFetchCall(false)
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI, TimeFrame, ActiveTimeFrame, ChannelCategory, FetchCall]);

  const DownloadDataltstroi = () => {
    setLoader(true);
    axiosDownload
      .post("ltstroi/", {
        data: {
          categories: Categories,
          series: Series,
          summary: Summary,
          Legends: Legends
        }
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const DownloadDatacomproi = () => {
    setLoader(true);
    axiosDownload
      .post("comproi/", {
        data: {
          ChannelYears: Years,
          ChannelData: ChannelDataAll,
          summary: Summary,
          Legends: Legends
        }
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const [ChartHeight, setChartHeight] = useState('')
  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight)
    }
  }, [Series])

  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Gains and Risk */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid main-content-w"
            style={{ paddingTop: "10px " }}
          >
            <div className="row">
              <div className="col-xl-9">
                <div className="card card-min-height">
                  <div className="card-body brand-card ef-drivers">
                    <div className="row align-items-center justify-content-sm-between">
                      {/* <div className="col-sm-1"></div> */}
                      <div className="col-sm-2">
                        <select
                          className="form-select"
                          onChange={(e) =>
                            { 
                              setTimeFrame(e.target.value);
                               setChannelCategory("") 
                               setCategoryFlag(true)
                            }
                          }
                          value={TimeFrame}
                        >
                          {TimeFramesAll.map((item) => {
                            return (
                              <option key={item.key} value={item.key}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                        {AllChannelCategory.length !== 0 ? (
                        <div className="col-sm-2">
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                            }}
                            value={ChannelCategory}
                          >
                            {AllChannelCategory.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}
                      <div className="col-sm-4">
                        <p className="card-title-lable" style={{ paddingLeft:(ActiveTab === "lt_st") ? "134px" :"0px"}}>
                          {ChartTitle}
                        </p>
                      </div>
                      <div className="col-sm-3 d-flex align-items-center justify-content-sm-end">
                        {
                          Tabs.map((item) => {
                            return (
                              <p
                                key={item.tab}
                                style={{ cursor: "pointer" }}
                                className={
                                  ActiveTab === item.tab
                                    ? "m-0 px-2 mx-2 lt-st"
                                    : "m-0 px-2 mx-2 text-lable"
                                }
                                onClick={() => setActiveTab(item.tab)}
                              >
                                {item.tab_name}
                              </p>
                            )
                          })
                        }
                        {/* <p
                          style={{ cursor: "pointer" }}
                          className={
                            ActiveTab === "LTSTROI"
                              ? "m-0 px-2 mx-2 lt-st"
                              : "m-0 px-2 mx-2"
                          }
                          onClick={() => setActiveTab("LTSTROI")}
                        >
                          LT ST ROI
                        </p>
                        <p
                          style={{ cursor: "pointer" }}
                          className={
                            ActiveTab === "ComparativeROI"
                              ? "m-0 px-2 mx-2 lt-st"
                              : "m-0 px-2 mx-2"
                          }
                          onClick={() => setActiveTab("ComparativeROI")}
                        >
                          Comparative ROI
                        </p> */}
                        {ActiveTab === "lt_st" ? (
                          <img src={Download} alt="" style={{ float: "right", cursor: 'pointer' }} title="Download" onClick={() => DownloadDataltstroi()} />
                        ) : null}

                        {ActiveTab === "comp" ? (
                          <img src={Download} alt="" style={{ float: "right", cursor: 'pointer' }} title="Download" onClick={() => DownloadDatacomproi()} />
                        ) : null}

                        {/* <i
                          className="fas fa-download px-2 mx-2"
                          style={{
                            float: "right",
                            fontSize: "25px",
                            color: "#fff ",
                          }}
                        ></i> */}
                      </div>
                    </div>

                    {ActiveTab === "lt_st" && Categories.length !==0 ? (
                      <div className="h-500 mt-3" ref={refGraphDiv}>
                        <LTSTROI Xtitle={Xtitle} Ytitle={Ytitle} Categories={Categories} Series={Series} height={ChartHeight} />
                      </div>
                    ) :ActiveTab === "lt_st" ?  <div className="mt-2"><h6 style={{ textAlign:"center" }}>No Data Found</h6></div> : null}

                    {ActiveTab === "comp" && ChannelDataAll.length !== 0 ? (
                      <ComparativeROI
                        Xtitle={Xtitle}
                        Ytitle={Ytitle}
                        ChannelYears={Years}
                        ChannelData={ChannelDataAll}
                        Legends={Legends}
                      />
                    ) : ActiveTab === "comp" ? <div className="mt-2"><h6 style={{ textAlign:"center" }}>No Data Found</h6></div> : null}
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card card-min-height">
                  <div className="card-body brand-card">
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">Summary</h3>
                      <div className="spends-list my-2">
                        <ol>
                          {Summary.map((item, index) => {
                            return (
                              <li key={item + index} className="spends-list-li">
                                {item}
                              </li>
                            );
                          })}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 data-l-note" dangerouslySetInnerHTML={{ __html: Notes }}>
                {/* {Notes} */}
                {/* <p className="m-0">Note:</p>
                <p className="m-0">FY 21: Oct'2020-Sep'2021</p>
                <p className="m-0">Covid-2020: Apr'2020-Jul'2020</p>
                <p className="m-0">Post Covid-2020: Aug'2020 to Dec'2020</p>
                <p className="m-0">2021*: Data is available till September</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
