import React, { useState, useEffect, useRef } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import Sunburst from "./Sunburst";
import MediaSunburst from "./MediaSunburst";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from '../../images/download-icon.png'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TrendChart from "./TrendChart";

const Main = () => {
  const location = useLocation();
  const refSpends = useRef(null);
  const refSales = useRef(null);
  const [SpendsHeight, setSpendsHeight] = useState('');
  const [SalesHeight, setSalesHeight] = useState('');

  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [EdaTabs, setEdaTabs] = useState([]);
  const [SunburstData, setSunburstData] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [MediaSunburstData, setMediaSunburstData] = useState([]);
  const [DecimalPoint, setDecimalPoint] = useState("");
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [FromDatesAll, setFromDatesAll] = useState([]);
  const [ToDatesAll, setToDatesAll] = useState([]);
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");
  const [sb1_name, setSb1_name] = useState("");
  const [sb2_name, setSb2_name] = useState("");
  const [sb1_tag, setSb1_tag] = useState("");
  const [sb2_tag, setSb2_tag] = useState("");
  const [Legends, setLegends] = useState([]);
  const [Notes, setNotes] = useState('');
  const [FetchCall, setFetchCall] = useState(false);
  const [FetchTrendsFilters, setFetchTrendsFilters] = useState(false);

  const [SelectionAll, setSelectionAll] = useState([]);
  const [Selection1, setSelection1] = useState("");
  const [Selection2, setSelection2] = useState("");
  const [TrendChartdata, setTrendChartdata] = useState({});

  const [SelectedChart, setSelectedChart] = useState();
  // const [TimePeriodError, setTimePeriodError] = useState('');
  const [KpiPerc, setKpiPerc] = useState("");
  const [MediaPerc, setMediaPerc] = useState("");


  useEffect(() => {
    if (refSpends.current !== null) {
      setSpendsHeight(refSpends.current.clientHeight)
    }
    if (refSales.current !== null) {
      setSalesHeight(refSales.current.clientHeight)
    }
  }, [MediaSunburstData, SunburstData]);

  const [Test, setTest] = useState(false);
  useEffect(() => {
    if (Test) {
      setTest(false)
    }
  }, [Test]);

  useEffect(() => {
    if (EdaTabs.length === 0) {
      setLoader(true);
      axios.post("get_eda_tabs/", { brand: Analyse.Brand, kpi: Analyse.KPI }).then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setEdaTabs(response.data.data.tabs)
          setSelectedChart(response.data.data.tabs[0].tab_short)
          setLoader(false);
        }
      }).catch((data) => {
        setLoader(false);
      });
    }
  }, [Analyse.Brand, Analyse.KPI, EdaTabs]);

  useEffect(() => {
    setLoader(true);

    let page = ""
    if (SelectedChart === "overview") {
      page = "eda"
    }
    if (SelectedChart === "trends") {
      page = "trends"
    }
    if (page !== "") {
      axios.post("get_timeframes/", { page: page,  brand: Analyse.Brand, kpi: Analyse.KPI }).then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          setFromDatesAll(response.data.data[0].fromdates);
          setToDatesAll(response.data.data[0].todates);
          setDefaultStartDate(response.data.data[0].default_startdate);
          setDefaultEndDate(response.data.data[0].default_enddate);
          setLoader(false);
          setFetchCall(true)
          setFetchTrendsFilters(true)
        }
      }).catch((data) => {
        setLoader(false);
      });
    }
  }, [SelectedChart, Analyse.Brand,  Analyse.KPI]);

  useEffect(() => {
    if (FetchTrendsFilters && timeframe !== '' && DefaultStartDate !== '' && DefaultEndDate !== '' && SelectedChart === "trends") {
      if (FromDatesAll.indexOf(DefaultStartDate) > ToDatesAll.indexOf(DefaultEndDate)) {
        toast.error('Please enter valid time period');
      } else {
        axios.post("get_trends_filters/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          starttime: DefaultStartDate,
          endtime: DefaultEndDate,
        }).then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setSelectionAll(response.data.data)
            setSelection1(response.data.data[0].value)
            setFetchTrendsFilters(false)
            setLoader(false);
          }
        }).catch((data) => {
          setLoader(false);
        });
      }
    }
  }, [Analyse, timeframe, DefaultStartDate, DefaultEndDate, FromDatesAll, ToDatesAll, SelectedChart, FetchTrendsFilters])

  useEffect(() => {
    if (FetchCall && SelectedChart === "overview") {
      if (timeframe !== '' && DefaultStartDate !== '' && DefaultEndDate !== '') {
        // if (FromDatesAll.indexOf(DefaultStartDate) > ToDatesAll.indexOf(DefaultEndDate)) {
        //   toast.error('Please enter valid time period');
        //   setFetchCall(false)
        // } else {
          setLoader(true);
          setSunburstData([]);
          setMediaSunburstData([]);
          axios
            .post("get_sunburst/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              starttime: DefaultStartDate,
              endtime: DefaultEndDate,
            })
            .then((response) => {
              if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                setSunburstData(response.data.chart.kpi);
                setMediaSunburstData(response.data.chart.media);
                setDecimalPoint(response.data.chart.decimal);
                setMediaPerc(response.data.chart.mediaper);
                setKpiPerc(response.data.chart.kpiper);
                setTest(true)
                setSummary(response.data.chart.summary);
                setLegends(response.data.chart.Legends)
                setSb1_name(response.data.chart.sb1_name);
                setSb2_name(response.data.chart.sb2_name);
                setSb1_tag(response.data.chart.sb1_tag);
                setSb2_tag(response.data.chart.sb2_tag);
                setNotes(response.data.notes)
                setLoader(false);
              }
              setFetchCall(false)
            }).catch((data) => {
              setLoader(false);
            });
        // }
      }
    }
  }, [Analyse.Brand, Analyse.KPI, DefaultStartDate, DefaultEndDate, timeframe, FetchCall, FromDatesAll, ToDatesAll, SelectedChart])

  useEffect(() => {
    if (FetchCall && SelectedChart === "trends") {
      if (timeframe !== '' && DefaultStartDate !== '' && DefaultEndDate !== '' && Selection1 !== '') {
        // if (FromDatesAll.indexOf(DefaultStartDate) > ToDatesAll.indexOf(DefaultEndDate)) {
        //   toast.error('Please enter valid time period');
        //   setFetchCall(false)
        // } else {
          setLoader(true);
          setSunburstData([]);
          setMediaSunburstData([]);
          axios
            .post("get_trends/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              starttime: DefaultStartDate,
              endtime: DefaultEndDate,
              selection: [Selection1, Selection2]
            })
            .then((response) => {
              if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
                setLoader(false);
              } else {
                // console.log(response.data)
                // setTest(true)
                setTrendChartdata(response.data.data.chartdata)
                setSummary(response.data.data.summary);
                setNotes(response.data.notes)
                setLoader(false);
              }
              setFetchCall(false)
            }).catch((data) => {
              setLoader(false);
            });
        // }
      }
    }
  }, [Analyse.Brand, Analyse.KPI, DefaultStartDate, DefaultEndDate, timeframe, FetchCall, FromDatesAll, ToDatesAll, Selection1, Selection2, SelectedChart])

  useEffect(() => {
    setFetchCall(true)
  }, [Analyse.Brand])

  const onChangeHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAll.length; i++) {
      if (TimeFramesAll[i].key === value) {
        setFromDatesAll(TimeFramesAll[i].fromdates);
        setToDatesAll(TimeFramesAll[i].todates);
      }
    }
    setTimeframe(value);
  };

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("eda/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        starttime: DefaultStartDate,
        endtime: DefaultEndDate,
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  return (
    <>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Data Landscape */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid main-content-w">
            <div className="row">
              <div className="col-xl-9">
                {/* <TestSun /> */}
                <div className="card card-min-height">
                  <div className="card-body brand-card">

                    <div className="row align-items-center ce-filter">
                      <div className="col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center my-2">
                        <select
                          className="form-select w-100"
                          value={timeframe}
                          onChange={onChangeHandler}
                        >
                          {TimeFramesAll.map((item) => {
                            return (
                              <option key={item.key} value={item.value}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-sm-12 col-md-10 col-lg-6 d-flex align-items-center justify-content-center my-2">
                        <div className="row align-items-center">
                          <div className="col-sm-3">
                            <h3 className="m-0"><label
                              className="login-lable brand-menu-label m-0">For time period
                              :</label>
                            </h3>
                          </div>
                          <div className="col-sm-3">
                            <select
                              className="form-select"
                              value={DefaultStartDate}
                              onChange={(e) => {
                                setDefaultStartDate(e.target.value)
                                if (ToDatesAll.indexOf(DefaultEndDate) < FromDatesAll.indexOf(e.target.value)) {
                                  setDefaultEndDate(ToDatesAll[FromDatesAll.indexOf(e.target.value)])
                                }
                              }}
                            >
                              {FromDatesAll.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-sm-1">
                            <h3 className="m-0"><label
                              className="login-lable brand-menu-label m-0">To</label></h3>
                          </div>
                          <div className="col-sm-3">
                            <select
                              className="form-select"
                              value={DefaultEndDate}
                              onChange={(e) => { setDefaultEndDate(e.target.value) }}
                            >
                              {ToDatesAll.map((item, index) => {
                                if (FromDatesAll.indexOf(DefaultStartDate) <= index) {
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  )
                                } else {
                                  return null
                                }
                              })}
                            </select>
                          </div>
                          <div className="col-sm-2">
                            <button
                              className="btn brand-btn-info"
                              type="button"
                              onClick={() => {
                                setFetchCall(true)
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-sm-end my-2">
                        {
                          EdaTabs.map((item, index) => {
                            return (
                              <p key={item.tab_short} className={SelectedChart === item.tab_short ? "m-0 px-2 mx-2 cursor-pointer text-white lt-st" : "m-0 px-2 mx-2 cursor-pointer text-lable text-white"} onClick={() => setSelectedChart(item.tab_short)}>{item.tab_name}</p>
                            )
                          })
                        }
                        <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', display: 'none' }} title="Download" onClick={() => DownloadData()} />
                      </div>
                      {/* <div className="col-sm-12 col-md-6 col-lg-1 d-flex justify-content-center my-2">
                        <img src={Download} alt="" style={{ float: "right", cursor: 'pointer', display: 'none' }} title="Download" onClick={() => DownloadData()} />
                      </div> */}
                    </div>
                    {
                      SelectedChart === 'trends' ?
                        <>
                          <div className="row justify-content-center ce-filter">
                            <div className="col-sm-4 d-flex align-items-center my-3">
                              <p className="m-0 px-3 text-white text-nowrap">Selection 1:</p>
                              <select className="form-select w-100" value={Selection1} onChange={(e) => {
                                setSelection1(e.target.value)
                                setFetchCall(true)
                              }}>
                                {
                                  SelectionAll.map((item) => {
                                    return Selection2 === item.value ? null : <option key={item.value} value={item.value}>{item.label}</option>
                                  })
                                }
                              </select>
                            </div>
                            <div className="col-sm-4 d-flex align-items-center my-3">
                              <p className="m-0 px-3 text-white text-nowrap">Selection 2:</p>
                              <select className="form-select w-100" value={Selection2} onChange={(e) => {
                                setSelection2(e.target.value)
                                setFetchCall(true)
                              }}>
                                <option value={""}></option>
                                {
                                  SelectionAll.map((item) => {
                                    return Selection1 === item.value ? null : <option key={item.value} value={item.value}>{item.label}</option>
                                  })
                                }
                              </select>
                            </div>
                          </div>
                          {
                            JSON.stringify(TrendChartdata) !== '{}' ? <TrendChart TrendChartdata={TrendChartdata} /> : null
                          }
                        </> : null
                    }
                    {
                      SelectedChart === 'overview' ?
                        <>
                          <div className="row align-items-center"
                            style={{ paddingTop: "1.5rem" }}
                          >
                            <div className="col-sm-6">
                              <div className="d-flex align-items-center">
                                <h3 className="data-spends my-2">{sb1_name}</h3>
                                <p className="data-spends-values px-3 my-2 text-white">
                                  [{sb1_tag}]
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <div className="d-flex align-items-center">
                                <h3 className="data-spends my-2">{sb2_name}</h3>
                                <p className="data-spends-values px-3 my-2 text-white">
                                  [{sb2_tag}]
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center h-500">
                            <div className="col-sm-6">
                              <div style={{ height: '100%' }} ref={refSpends}>
                                {
                                  MediaSunburstData.length > 0 ? <MediaSunburst MediaSunburstData={MediaSunburstData} name={'Spends'} height={SpendsHeight} DecimalPoint={DecimalPoint} MediaPerc={MediaPerc} /> : null
                                }
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div style={{ height: '100%' }} ref={refSales}>
                                {
                                  SunburstData.length > 0 ? <Sunburst SunburstData={SunburstData} name={'Sales'} height={SalesHeight} KpiPerc={KpiPerc}/> : null
                                }
                              </div>
                            </div>
                            <div className="col-sm-12 mt-3">
                              <div className="row justify-content-center">
                                {
                                  Legends.map((item, index) => {
                                    return <div key={index} className="col-md-3 col-lg-2 text-center text-white mb-3">
                                      <span style={{ height: '16px', width: '16px', display: 'inline-block', marginRight: '5px', backgroundColor: item.Color }}></span>
                                      {item.Name}
                                    </div>
                                  })
                                }
                              </div>
                            </div>

                          </div>
                        </> : null
                    }
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="card card-min-height">
                  <div className="card-body brand-card">
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">Summary</h3>
                      <div className="spends-list">
                        <ol>
                          {Summary.map((item) => {
                            return (
                              <li key={item} className="spends-list-li">
                                <p>{item}</p>
                              </li>
                            );
                          })}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 data-l-note" dangerouslySetInnerHTML={{ __html: Notes }}>
                {/* {Notes} */}
                {/* <p className="m-0">Note:</p>
                <p className="m-0">FY 21: Oct'2020-Sep'2021</p>
                <p className="m-0">Covid-2020: Apr'2020-Jul'2020</p>
                <p className="m-0">Post Covid-2020: Aug'2020 to Dec'2020</p>
                <p className="m-0">2021*: Data is available till September</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
