import React, { useEffect, useState } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import ItemWise from "./ItemWise";
import AggregateTotalView from "./AggregateTotalView";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from "../../images/download-icon.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [Tabs, setTabs] = useState([]);
  const [ActiveTab, setActiveTab] = useState("");
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [DirectChannels, setDirectChannels] = useState([]);
  const [IndChannels, setIndChannels] = useState([]);
  const [Legends, setLegends] = useState([]);
  const [Summary, setSummary] = useState([]);

  const [Notes, setNotes] = useState("");
  const [ChartTitle, setChartTitle] = useState("");
  const [Xtitle, setxTitle] = useState("");
  const [Ytitle, setyTitle] = useState("");

  const [AllChannelCategory, setAllChannelCategory] = useState([]);
  const [ChannelCategory, setChannelCategory] = useState("");
  const [FetchCall, setFetchCall] = useState(false);


  useEffect(() => {
    axios
      .post("get_synergy_tabs/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTabs(response.data.data.tabs);
          if (response.data.data.tabs.length > 0) {
            setActiveTab(response.data.data.tabs[0].tab);
          }
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    axios
      .post("get_timeframes/", {
        // page: ActiveTab !== "AggregateTotalView" ? "synergy_item" : "synergy_aggr",
        page: "synergy_item",
        brand: Analyse.Brand,
        kpi: Analyse.KPI
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
        }
      });
  }, [Analyse.Brand, Analyse.KPI]);


  useEffect(() => {
    if (timeframe) {
      axios
        .post("get_category/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          page: "synergy",
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
            setLoader(false);
          } else {
            setAllChannelCategory(response.data.data);
            if (response.data.data.length > 0) {
              setChannelCategory(response.data.data[0].value);
            } else {
              setChannelCategory('');
            }
            setFetchCall(true)
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [ActiveTab, Analyse.Brand, Analyse.KPI, timeframe])

  useEffect(() => {
    setLoader(true);
    if (timeframe && ActiveTab && FetchCall) {
      axios
        .post("get_synergy_effects/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          // tab: ActiveTab !== "AggregateTotalView" ? "item" : "aggr",
          tab: ActiveTab,
          timeframe: timeframe,
          category: ChannelCategory,
        })
        .then((response) => {
          setLoader(false);
          if (response.data.error !== 1) {
            setDirectChannels(response.data.data.DirectChannels);
            setIndChannels(response.data.data.IndChannels);
            setLegends(response.data.data.Legends);
          } else {
            setDirectChannels([]);
            setIndChannels([]);
            setLegends([]);
            toast.error(response.data.erroMsg);
          }

          setSummary(response.data.data.summary);
          setNotes(response.data.notes);
          setChartTitle(response.data.data.charttitle);
          setxTitle(response.data.data.xtitle);
          setyTitle(response.data.data.ytitle);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [Analyse.Brand, Analyse.KPI, ActiveTab, timeframe, ChannelCategory, FetchCall]);

  const DownloadData = () => {
    setLoader(true);
    axiosDownload
      .post("synergy/", {
        data: {
          IndChannels: IndChannels,
          DirectChannels: DirectChannels,
          summary: Summary,
          Legends: Legends,
        },
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Synergy Effects */}
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                        return "/" + item.key === location.pathname
                          ? item.value
                          : null;
                      })
                      : null}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid main-content-w"
            style={{ paddingTop: "10px" }}
          >
            <div className="row">
              <div className="col-xl-9">
                <div className="card card-min-height">
                  <div className="card-body brand-card ef-drivers pb-1">
                    <div className="row align-items-center justify-content-sm-between pb-3">
                      <div className="col-sm-2">
                        <select
                          className="form-select"
                          value={timeframe}
                          onChange={(e) => setTimeframe(e.target.value)}
                        >
                          {TimeFramesAll.map((item) => {
                            return (
                              <option key={item.key} value={item.key}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {AllChannelCategory.length !== 0 ? (
                        <div className="col-sm-2">
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setChannelCategory(e.target.value);
                            }}
                            value={ChannelCategory}
                          >
                            {AllChannelCategory.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}

                      <div className="col-sm-5">
                        <p className="card-title-lable" style={{
                          paddingLeft: "95px"
                        }}>{ChartTitle}</p>
                      </div>

                      <div className="col-sm-3 d-flex align-items-center justify-content-sm-end">
                        {Tabs.map((item) => {
                          return (
                            <p
                              key={item.tab}
                              style={{ cursor: "pointer" }}
                              className={
                                ActiveTab === item.tab
                                  ? "m-0 px-2 mx-2 lt-st"
                                  : "m-0 px-2 mx-2 text-lable"
                              }
                              onClick={() => setActiveTab(item.tab)}
                            >
                              {item.tab_name}
                            </p>
                          );
                        })}

                        <img
                          src={Download}
                          alt=""
                          style={{ float: "right", cursor: "pointer" }}
                          title="Download"
                          onClick={() => DownloadData()}
                        />
                        {/* <p
                          style={{ cursor: "pointer" }}
                          className={
                            ActiveTab === "AggregateTotalView"
                              ? "m-0 px-2 mx-2 lt-st"
                              : "m-0 px-2 mx-2 text-lable"
                          }
                          onClick={() => setActiveTab("AggregateTotalView")}
                        >
                          Aggregate Total View
                        </p>
                        <p
                          style={{ cursor: "pointer" }}
                          className={
                            ActiveTab === "ItemWise"
                              ? "m-0 px-2 mx-2 lt-st"
                              : "m-0 px-2 mx-2 text-lable"
                          }
                          onClick={() => setActiveTab("ItemWise")}
                        >
                          Item Wise View
                        </p> */}
                        {/* <i
                          className="fas fa-download px-2 mx-2"
                          style={{
                            float: "right",
                            fontSize: "25px",
                            color: " #fff",
                          }}
                        ></i> */}
                      </div>
                    </div>

                    {ActiveTab === "item" && DirectChannels.length > 0 ? (
                      <ItemWise
                        DirectChannels={DirectChannels}
                        IndChannels={IndChannels}
                        Legends={Legends}
                        Xtitle={Xtitle}
                        Ytitle={Ytitle}
                      />
                    ) : (
                      ""
                    )}
                    {ActiveTab === "aggr" && DirectChannels.length > 0 ? (
                      <AggregateTotalView
                        DirectChannels={DirectChannels}
                        IndChannels={IndChannels}
                        Legends={Legends}
                        Xtitle={Xtitle}
                        Ytitle={Ytitle}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="card card-min-height">
                  <div className="card-body brand-card">
                    <div className="row align-items-center justify-content-sm-between">
                      <h3 className="data-spends my-2">Summary</h3>
                      <div className="spends-list my-2">
                        <ol>
                          {Summary
                            ? Summary.map((item, index) => {
                              return (
                                <li key={index} className="spends-list-li">
                                  {item}
                                </li>
                              );
                            })
                            : ""}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-sm-12 data-l-note"
                dangerouslySetInnerHTML={{ __html: Notes }}
              >
                {/* {Notes} */}
                {/* <p className="m-0">Note:</p>
                <p className="m-0">FY 21: Oct'2020-Sep'2021</p>
                <p className="m-0">Covid-2020: Apr'2020-Jul'2020</p>
                <p className="m-0">Post Covid-2020: Aug'2020 to Dec'2020</p>
                <p className="m-0">2021*: Data is available till September</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
