import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
import HighchartsGroupedCategories from "highcharts-grouped-categories";
HighchartsGroupedCategories(Highcharts);
CustomEvents(Highcharts);

const StackChart = (props) => {
  const refChart = useRef(null);
  useEffect(() => {
    const chart = refChart.current.chart;
    if (chart) chart.reflow(false);
  }, [props.ZoomIn]);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      backgroundColor: "#1c2427",
      showAxes: true,
      height: props.height,
    },
    title: {
      text: props.charttitle,
      style: {
        color: "#D6FF41",
        fontSize: "14px",
      },
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: props.categories,
      crosshair: false,
      //width: '100%',
      title: {
        text: props.xtitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        useHTML: true,
        formatter: function () {
          let fulltext = "";
          if (typeof this.value === "string") {
            const SeriesList = []
            function chnnellist(list) {
              if (list.name.split(",")[0] !== '') {
                SeriesList.push(list.name.split(",")[0])
              }
              if ("parent" in list && list.parent !== undefined) {
                chnnellist(list.parent);
              }
            }
            chnnellist(this.axis.categories[this.pos]);

            const val = this.value.split(",");
            let title = SeriesList.reverse().join("-")
            title = title.substring(0, title.indexOf(val[0]) + val[0].length);

            let short = "";
            if (val[1]) {
              if (val[1] === "-1") {
                short = val[0];
              } else if (val[1] === "0") {
                short = val[0] !== "" && val[0].length > 3 ? val[0].slice(0, 3) + ".." : val[0];
              } else {
                short = val[0] !== "" && val[0].length > 2 ? val[0].slice(0, 2) + ".." : val[0];
              }
            } else {
              short = val[0];
            }
            return (
              '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' + title + ">" + short + "</div>"
            );
          } else {
            return (
              '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' + fulltext + ">" + this.value + "</div>"
            );
          }
        },
        rotation: 0,
        style: {
          color: "#FFFFFF",
        },
        events: {
          click: function () {
            props.DrillUp(this);
          },
        },
      },
    },
    yAxis: {
      min: 0,
      gridLineColor: "#7d9ca6",
      gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.ytitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        style: {
          color: "#FFFFFF",
        },
      },
    },
    tooltip: {
      // headerFormat:
      //   '<div style="padding:0;background-color:#ffffff;color:#000000;"><table>',
      // pointFormat: '<td style="padding:0;"><b>{point.y:.1f}</b></td></tr>',
      // footerFormat: "</table></div>",
      formatter: function () {
        const SeriesList = []
        function chnnellist(list) {
          if (list.name.split(",")[0] !== '') {
            SeriesList.push(list.name.split(",")[0])
          }
          if ("parent" in list && list.parent !== undefined) {
            chnnellist(list.parent);
          }
        }
        chnnellist(this.x);

        let label = SeriesList.reverse().join("-")

        return label + " " + this.y.toFixed(2) + "%";
      },
      shared: true,
      useHTML: false,
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
    },
    plotOptions: {
      series: {
        // cursor: 'pointer',
        point: {
          events: {
            click: function () {
              const SeriesList = []
              function chnnellist(list) {
                if (list.name.split(",")[0] !== '') {
                  SeriesList.push(list.name.split(",")[0])
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-")

              if (!(props.NotAllow.includes(label))) {
                props.DrillDown(this);
              }
            },
            mouseOver: function () {
              const SeriesList = []
              function chnnellist(list) {
                if (list.name.split(",")[0] !== '') {
                  SeriesList.push(list.name.split(",")[0])
                }
                if ("parent" in list && list.parent !== undefined) {
                  chnnellist(list.parent);
                }
              }
              chnnellist(this.category);

              let label = SeriesList.reverse().join("-")

              window.document.getElementsByTagName('body')[0].style.cursor = "pointer"
              if ((props.NotAllow.includes(label))) {
                window.document.getElementsByTagName('body')[0].style.cursor = "not-allowed"
              }
            },
            mouseOut: function () {
              window.document.getElementsByTagName('body')[0].style.cursor = "default"
            }
          },
        },
      },
      column: {
        stacking: "normal",
        // stacking: "",
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><table>',
          pointFormat: '<td style="padding:0;"><b>{point.y:.2f}%</b></td></tr>',
          footerFormat: "</table></div>",
          enabled: true,
          verticalAlign: "top",
          color: "#ffffff",
          align: "center",
          x: 0,
          y: -20,
        },
        color: "#d6ff41",
        // events: {
        //   click: (e) => {
        //     console.log(e)
        //   }
        // }
      },
    },
    legend: {
      enabled: false,
      itemHoverStyle: {
        color: "#ffffff",
      },
      itemStyle: {
        color: "#ffffff",
      },
      symbolRadius: 0,
    },
    series: props.series,
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          enabled: false,
          className: "highcharts-contextbutton",
          symbol: "menu",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
          menuItems: ["viewFullscreen"],
        },
      },
    },
  };

  return (
    <HighchartsReact ref={refChart} highcharts={Highcharts} options={options} />
  );
};

export default StackChart;
