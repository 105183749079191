import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import HC_more from "highcharts/highcharts-more";
import CustomEvents from "highcharts-custom-events";

// import HighchartsGroupedCategories from "highcharts-grouped-categories";
// HighchartsGroupedCategories(Highcharts);
CustomEvents(Highcharts);
HC_more(Highcharts);

const Waterfall = (props) => {
  const [Cursor, setCursor] = useState('pointer')
  const [Cat, setCat] = useState(props.Categories)
  const [Height, setHeight] = useState('')
  const [Width, setWidth] = useState('')

  window.addEventListener("load", (event) => {
    setHeight(window.innerHeight)
    setWidth(window.innerWidth)
  });

  window.addEventListener('resize', function (event) {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function () {
      if (Height !== window.innerHeight || Width !== window.innerWidth) {
        setCat([])
        setTimeout(() => {
          setHeight(window.innerHeight)
          setWidth(window.innerWidth)
          setCat(props.Categories)
        }, 500);
      }
    }, 250);
  });

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "waterfall",
      backgroundColor: "#1c2427",
    },
    title: {
      text: "",
      style: {
        color: "#D6FF41",
        fontSize: "14px",
      },
    },
    xAxis: {
      categories: Cat,
      title: {
        text: props.xtitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        useHTML: true,
        formatter: function () {
          // setTest(!Test)
          let fulltext = "";
          if (typeof this.value === "string") {
            const SeriesList = [];
            function chnnellist(list) {
              if (list.name.split(",")[0] !== "") {
                SeriesList.push(list.name.split(",")[0]);
              }
              if ("parent" in list && list.parent !== undefined) {
                chnnellist(list.parent);
              }
            }
            chnnellist(this.axis.categories[this.pos]);

            const val = this.value.split(",");
            let title = SeriesList.reverse().join("-");
            title = title.substring(0, title.indexOf(val[0]) + val[0].length);

            let short = "";
            if (val[1]) {
              if (val[1] === "-1") {
                short = val[0];
              } else if (val[1] === "0") {
                short =
                  val[0] !== "" && val[0].length > 3
                    ? val[0].slice(0, 3) + ".."
                    : val[0];
              } else {
                short =
                  val[0] !== "" && val[0].length > 2
                    ? val[0].slice(0, 2) + ".."
                    : val[0];
              }
            } else {
              short = val[0];
            }
            return (
              '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
              title +
              ">" +
              short +
              "</div>"
            );
          } else {
            return (
              '<div class="cursor-pointer" style="background-color:#1C2427;z-index:99999" title=' +
              fulltext +
              ">" +
              this.value +
              "</div>"
            );
          }
        },
        rotation: 0,
        style: {
          color: "#FFFFFF",
        },
        events: {
          click: function () {
            props.DrillUp(this);
          },
        },
      },
    },
    yAxis: {
      min: props.WaterfallData.length > 0 ? Math.floor(props.WaterfallData[0].y) - 15 : 0,
      gridLineColor: "#7d9ca6",
      gridLineDashStyle: "ShortDash",
      visible: true,
      lineWidth: 1,
      title: {
        text: props.ytitle,
        style: {
          color: "#D6FF41",
        },
      },
      labels: {
        formatter: function () {
          return this.value + "%";
        },
        style: {
          color: "#FFFFFF",
        },
      },
    },
    tooltip: {
      formatter: function () {
        var label = this.point.id
        function removeDash(label) {
          label = label.slice(0, -1);
          return label[label.length - 1] === "-" ? removeDash(label) : label;
        };

        if (label[label.length - 1] === "-") {
          label = removeDash(label);
        }

        return label + " " + this.y.toFixed(2) + "%";
      },
      distance: 25,
      shared: false,
      useHTML: false,
      borderColor: "#ffffff",
      backgroundColor: "#ffffff",
      borderRadius: 8,
      hideDelay: 0
    },
    plotOptions: {
      series: {
        stickyTracking: true,
        cursor: Cursor,
        point: {
          events: {
            click: function () {
              props.DrillDown(this);
            },
            mouseOver: function (e) {
              var label = e.target.id

              function removeDash(label) {
                label = label.slice(0, -1);
                return label[label.length - 1] === "-" ? removeDash(label) : label;
              };

              if (label[label.length - 1] === "-") {
                label = removeDash(label);
              }

              // window.document.getElementsByTagName('body')[0].style.cursor = "pointer"
              if ((props.NotAllow.includes(label))) {
                // window.document.getElementsByTagName('body')[0].style.cursor = "not-allowed"
                setCursor('not-allowed')
              } else {
                setCursor('pointer')
              }

              props.dataplotRollOverHandler(label);
            },
            mouseOut: function () {
              // window.document.getElementsByTagName('body')[0].style.cursor = "default"
            }
          },
        },
      },
      waterfall: {
        animation: false,
        dataLabels: {
          headerFormat: '<div style="padding:0;background-color:#ffffff;color:#000000;"><table>',
          pointFormat: '<td style="padding:0;"><b>{point.y:.2f}%</b></td></tr>',
          footerFormat: "</table></div>",
          enabled: true,
          verticalAlign: "top",
          color: "#ffffff",
          align: "center",
          x: 0,
          y: -20,
        },
      },
    },
    legend: {
      enabled: false,
      itemHoverStyle: {
        color: "#ffffff",
      },
      itemStyle: {
        color: "#ffffff",
      },
      symbolRadius: 0,
    },
    series: [{ data: props.WaterfallData }],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          enabled: false,
          className: "highcharts-contextbutton",
          symbol: "menu",
          symbolStroke: "#ffffff",
          theme: {
            fill: "#1c2427",
          },
          titleKey: "",
          menuItems: ["viewFullscreen"],
        },
      },
    },
  };
  return (
    Cat.length > 0 ? <HighchartsReact highcharts={Highcharts} options={options} /> : null
  );
};

export default Waterfall;
