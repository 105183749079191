import React from "react";
// import PreviousOptimiseData from "./PreviousOptimiseData";
// import NewOptimiseData from "./NewOptimiseData";
// import EditNewOptimiseData from "./EditNewOptimiseData";
// import ChannelAllocation from "./ChannelAllocation";
// import GrowthDistributionChart from "./GrowthDistributionChart";
// import SpendsContributionChart from "./SpendsContributionChart";
// import Header from "./Header";
// import Footer from "./Footer";
// import axios from "../../axios"
import { useLocation } from "react-router-dom";

// import Download from '../../images/download-icon.png'
import TableData from "./TableData";
import Allocation from "./Allocation";
import SummaryGrowth from "./SummaryGrowth";

const SkewbSimulator = (props) => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Skewb Simulator */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid main-content-w simulator"
            // style="padding-top: 25px; max-width: 99% !important;"
            // style={{ maxWidth: "99%", padding: "25px 25px 0px 25px" }}
          >
            <div className="row">
              <TableData
                YearHandler={props.YearHandler}
                PreviousData={props.PreviousData}
                SelectedYear={props.SelectedYear}
                NewData={props.NewData}
                NewFixedData={props.NewFixedData}
                AllowEdit={props.AllowEdit}
                setAllowEdit={props.setAllowEdit}

                Summary={props.Summary}

                EditNewSpends={props.EditNewSpends}
                EditNewSpendsOnBlur={props.EditNewSpendsOnBlur}
                ResetSpends={props.ResetSpends}

                setSaveResult={props.setSaveResult}
                Optimise={props.Optimise}

                ChannelExpand={props.ChannelExpand}
                ChannelExpand1={props.ChannelExpand1}
                handleShrinkChannel={props.handleShrinkChannel}
                handleExpandChannel={props.handleExpandChannel}
                handleShrinkChannel1={props.handleShrinkChannel1}
                handleExpandChannel1={props.handleExpandChannel1}
                Levels={props.Levels}
                AddLevel={props.AddLevel}
                RemoveLevel={props.RemoveLevel}
              />

              <Allocation
                AllowEdit={props.AllowEdit}
                SuggestedAllocation={props.SuggestedAllocation}
                EditedAllocation={props.EditedAllocation}
              />

              <SummaryGrowth
                AllowEdit={props.AllowEdit}
                OptSummarydict={props.OptSummarydict}
                OptChartdict={props.OptChartdict}
                setChartDataMain={props.setChartDataMain}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SkewbSimulator;
