const response = {
  data: {
    categories: [
      "2022-01-02",
      "2022-01-09",
      "2022-01-16",
      "2022-02-20",
      "2022-02-27",
      "2022-03-06",
      "2022-03-13",
      "2022-03-20",
      "2022-03-27",
      "2022-04-03",
      "2022-04-10",
      "2022-04-17",
      "2022-04-24",
      "2022-05-01",
      "2022-05-08",
      "2022-05-15",
      "2022-05-22",
      "2022-05-29",
      "2022-06-05",
      "2022-06-12",
      "2022-06-19",
      "2022-06-26",
      "2022-07-03",
      "2022-07-10",
      "2022-07-17",
      "2022-07-24",
      "2022-07-31",
      "2022-08-07",
      "2022-08-14",
      "2022-08-21",
      "2022-08-28",
      "2022-09-04",
      "2022-09-11",
      "2022-09-18",
      "2022-09-25",
      "2022-10-02",
      "2022-10-09",
    ],
    colors: [
      "#CAA4BF",
      "#E07261",
      "#7893C1",
      "#55AFAE",
      "#1296CE",
      "#7866AF",
      "#8EC3D7",
      "#EB9F8A",
      "#9DB1D2",
      "#2E6D71",
      "#B45A5A",
      "#9cdbdb",
      "#579fad",
      "#de92c5",
      "#0fafba",
      "#b3e0e3",
      "#e3b3e1",
      "#af4aba",
      "#e6d8e8",
      "#71b9e3",
      "#BAC25E",
      "#77B06B",
    ],
    series: [
      {
        name: "FB-FB-Feed-Desktop",
        data: [
          0.0, 0.013, 0.012, 0.0, 0.034, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.606,
          0.401, 0.341, 0.899, 0.989, 0.37, 0.0, 0.0, 0.0, 0.0, 0.0, 0.264,
          0.528, 0.223, 3.897, 6.414, 6.468, 6.467, 5.82, 10.555, 3.728, 6.454,
          7.081, 6.4, 4.502, 0.0,
        ],
        color: "#CAA4BF",
      },
      {
        name: "FB-FB-Feed-Mobile",
        data: [
          0.51, 2.105, 3.152, 0.0, 0.757, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.532,
          1.535, 1.627, 3.722, 4.19, 1.425, 0.0, 0.0, 0.001, 0.0, 4.683, 10.315,
          2.461, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.662, 0.0, 0.399, 0.0, 0.0, 0.0,
          0.0,
        ],
        color: "#E07261",
      },
      {
        name: "FB-FB-Others-Desktop",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.071,
          0.078, 0.079, 0.088, 0.131, 0.179, 1.05, 1.467, 0.662, 0.0, 0.0,
        ],
        color: "#7893C1",
      },
      {
        name: "FB-FB-Others-Mobile",
        data: [
          0.0, 0.0, 0.0, 0.0, 1.065, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.064, 0.031,
          0.019, 0.03, 0.03, 0.027, 0.0, 0.0, 0.0, 0.0, 0.003, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#55AFAE",
      },
      {
        name: "FB-FB-Stories-Mobile",
        data: [
          0.0, 0.003, 0.004, 0.0, 0.023, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#1296CE",
      },
      {
        name: "FB-FB-Video-Desktop",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#7866AF",
      },
      {
        name: "FB-FB-Video-Mobile",
        data: [
          0.108, 0.543, 0.861, 0.0, 1.134, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.163,
          0.092, 0.071, 0.165, 0.163, 0.077, 0.0, 0.0, 0.0, 0.0, 0.248, 0.398,
          0.059, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0,
        ],
        color: "#8EC3D7",
      },
      {
        name: "FB-FBBM_Othr-Others-Desktop",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#EB9F8A",
      },
      {
        name: "FB-FBBM_Othr-Others-Mobile",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#9DB1D2",
      },
      {
        name: "FB-FBBM_Othr-Video-Mobile",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#2E6D71",
      },
      {
        name: "FB-IG-Feed-Mobile",
        data: [
          0.302, 0.285, 0.103, 0.0, 0.322, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.663,
          1.393, 1.517, 3.452, 3.647, 1.421, 0.0, 0.0, 0.0, 0.0, 3.466, 2.793,
          2.51, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.586, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0,
        ],
        color: "#B45A5A",
      },
      {
        name: "FB-IG-Others-Mobile",
        data: [
          0.0, 0.474, 0.394, 0.0, 0.32, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.09,
          0.057, 0.039, 0.037, 0.037, 0.023, 0.0, 0.0, 0.0, 0.0, 0.023, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#9cdbdb",
      },
      {
        name: "FB-IG-Stories-Mobile",
        data: [
          0.13, 0.041, 0.027, 0.0, 0.323, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#579fad",
      },
      {
        name: "FB-Unknown-Others-Others",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#de92c5",
      },
      {
        name: "GADS-OthrPLT-Desktop",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.159,
          0.74, 1.837, 2.289, 1.438, 1.361, 1.097, 1.287, 0.149, 0.201, 0.162,
          4.187, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0,
        ],
        color: "#0fafba",
      },
      {
        name: "GADS-OthrPLT-Mobile",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 2.384,
          2.24, 0.818, 1.294, 0.257, 0.239, 0.238, 0.201, 0.038, 2.667, 1.417,
          3.171, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0,
        ],
        color: "#b3e0e3",
      },
      {
        name: "GADS-OthrPLT-OthrDev",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.119,
          0.68, 1.613, 1.82, 0.736, 0.843, 0.986, 0.929, 0.355, 0.182, 0.054,
          0.198, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0,
        ],
        color: "#e3b3e1",
      },
      {
        name: "GADS-Search-Desktop",
        data: [
          0.0, 0.0, 0.0, 3.513, 2.463, 1.453, 5.985, 1.671, 18.356, 26.133,
          19.547, 24.924, 21.638, 22.634, 14.273, 19.71, 14.242, 17.595, 15.384,
          18.57, 20.328, 17.909, 25.524, 13.412, 11.125, 7.983, 8.414, 23.322,
          19.356, 15.686, 18.487, 15.962, 15.837, 15.744, 19.735, 19.259,
          14.818,
        ],
        color: "#af4aba",
      },
      {
        name: "GADS-Search-Mobile",
        data: [
          0.0, 0.0, 0.0, 1.505, 2.406, 2.288, 1.893, 0.606, 1.336, 2.647, 1.053,
          1.934, 1.706, 1.55, 0.885, 0.951, 0.0, 0.0, 0.0, 0.0, 0.0, 2.224,
          1.689, 0.0, 0.0, 0.0, 1.257, 0.0, 0.0, 0.0, 1.817, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0,
        ],
        color: "#e6d8e8",
      },
      {
        name: "GADS-Search-OthrDev",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.221, 0.0, 0.0, 0.0, 0.203,
          0.0, 0.0, 0.0, 0.124, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#71b9e3",
      },
      {
        name: "MS",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.001, 7.608, 0.0, 0.0, 1.421,
          3.493, 7.833, 0.076, 12.973, 5.852, 12.194, 0.0, 13.749, 3.912, 5.129,
        ],
        color: "#BAC25E",
      },
      {
        name: "Twitter",
        data: [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 3.557, 0.0, 0.0, 0.933,
          0.076, 0.065, 2.282, 4.877, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ],
        color: "#77B06B",
      },
    ],
    Legends: [
      {
        Name: "FB-FB-Feed-Desktop",
        Color: "#CAA4BF",
      },
      {
        Name: "FB-FB-Feed-Mobile",
        Color: "#E07261",
      },
      {
        Name: "FB-FB-Others-Desktop",
        Color: "#7893C1",
      },
      {
        Name: "FB-FB-Others-Mobile",
        Color: "#55AFAE",
      },
      {
        Name: "FB-FB-Stories-Mobile",
        Color: "#1296CE",
      },
      {
        Name: "FB-FB-Video-Desktop",
        Color: "#7866AF",
      },
      {
        Name: "FB-FB-Video-Mobile",
        Color: "#8EC3D7",
      },
      {
        Name: "FB-FBBM_Othr-Others-Desktop",
        Color: "#EB9F8A",
      },
      {
        Name: "FB-FBBM_Othr-Others-Mobile",
        Color: "#9DB1D2",
      },
      {
        Name: "FB-FBBM_Othr-Video-Mobile",
        Color: "#2E6D71",
      },
      {
        Name: "FB-IG-Feed-Mobile",
        Color: "#B45A5A",
      },
      {
        Name: "FB-IG-Others-Mobile",
        Color: "#9cdbdb",
      },
      {
        Name: "FB-IG-Stories-Mobile",
        Color: "#579fad",
      },
      {
        Name: "FB-Unknown-Others-Others",
        Color: "#de92c5",
      },
      {
        Name: "GADS-OthrPLT-Desktop",
        Color: "#0fafba",
      },
      {
        Name: "GADS-OthrPLT-Mobile",
        Color: "#b3e0e3",
      },
      {
        Name: "GADS-OthrPLT-OthrDev",
        Color: "#e3b3e1",
      },
      {
        Name: "GADS-Search-Desktop",
        Color: "#af4aba",
      },
      {
        Name: "GADS-Search-Mobile",
        Color: "#e6d8e8",
      },
      {
        Name: "GADS-Search-OthrDev",
        Color: "#71b9e3",
      },
      {
        Name: "MS",
        Color: "#BAC25E",
      },
      {
        Name: "Twitter",
        Color: "#77B06B",
      },
    ],
    ytitle: "%Contribution",
    xtitle: "Time Period",
    charttitle: "Contribution Distribution",
    summary: [
      "Stable Spends are observed from April'22",
      "Avg Weekly Media Contribution (Jan'22-Sep'22): ~ 22.5%",
      "Last 6 Months Mar'22-Aug'22 Avg Media Contribution: ~29.6%",
      "Avg Media Contribution from Jul'22-Sep'22: ~30.2%",
    ],
  },
  erroMsg: "",
  error: 0,
  notes: "",
};

export default response;
